import styled from 'styled-components';
import { Link } from 'react-router-dom';

// export const Wrapper = styled.div`
//   width: 100vw;
//   height: 100vh;
//   background-color: #541516;
//   background-color: white;
//   background-size: cover;
//   background-position: center;
//   overflow: hidden;
//   padding: 38px 70px;
//   @media only screen and (max-width:768px){
//     overflow: auto;
//   }
// `;

export const Wrapper = styled.div`
  @media only screen and (min-width: 768px) {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }
`;

export const Main = styled.main`
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  padding-top: 120px;

  @media only screen and (min-width: 768px) {
    padding: 0 20px 20px 40px;
  }
`;


export const Title = styled.div`
  display:flex;
  justify-content: space-between;
`

export const Logo = styled.h1`
  background-image:url("/images/logo.svg");
  background-repeat: no-repeat;
  width: 208px;
  height: 90px;
  background-size: 100% 100%;
  font-size: 0;
  text-indent: -9999;
  overflow: hidden;
  margin-bottom: 38px;
  @media only screen and (max-width:415px){
    width: 104px;
    height: 45px;
    margin-bottom: 15px;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  font-family: "AppleGothic";
  font-size: 17px;
  font-weight: 700;
  color: #000000;
  display: inline-block;
  padding: 0 8px;
  @media only screen and (max-width:768px){
    font-size: 14px;
    font-weight:600;
  }
  @media only screen and (max-width:375px){
    font-size: 10px;
    font-weight: 600;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items:center;
 
`
