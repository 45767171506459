import styled from "styled-components";

export const AddAndDelete = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 65px; */
`;

export const ProductLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid gray;
`;

export const AddNewTagArea = styled.div`
  display: flex;
  align-items: "center";
`;

export const TagsArea = styled.div`
  display: flex;
  margin: 10px;
  width: 300px;
  flex-wrap: wrap;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 25px;
`;

export const AddProductLine = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
  border-top: 1px solid gray;
`;
