import styled from 'styled-components';

export const Header = styled.header`
  padding: 15px 0;
  border-bottom: 1px #979797 solid;
  margin-bottom: 40px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 50px;
    padding: 25px 0 20px;
  }
`;

export const Breadcrumbs = styled.div`
  height: 30px;

  @media only screen and (min-width: 768px) {
    height: 52px;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h2`
  font-family: "AppleGothic";
  font-size: 24px;
  line-height: 36px;
  font-weight: 300;
  margin: 0;

  @media only screen and (min-width: 768px) {
    font-size: 30px;
    line-height: 45px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const UploadButton = styled.button`
  height: 40px;
  padding: 12px 20px 9px;
  background-color: #980000;
  border: 0;
  color: #ffffff;
  border-radius: 31px;
  font-family: "AppleGothic";
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
`;

export const CaptionButton = styled.button`
  height: 40px;
  padding: 12px 20px 9px;
  background-color: #fffff;
  border:1px solid #980000;
  margin-left:10px;
  color: #980000;
  border-radius: 31px;
  font-family: "AppleGothic";
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
`;

export const ConfigButton = styled.button`
  width: 40px;
  height: 40px;
  border: 0;
  padding: 9px;
  margin-left: 8px;
  background-color: #980000;
  border-radius: 50%;
  line-height: 1;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    margin-left: 15px;
  }

  svg {
    width: 23px;
    height: 23px;
    fill: #ffffff;
  }
`;

export const BetaMessage = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: .25rem;
  margin-top: 15px;
`;

export const WelcomeMessage = styled.div`
  color: rgb(0, 29, 79);
  background-color: rgb(129, 193, 201);
  font-weight: bold; 
  font-size: 1.5rem; 
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: .25rem;
  margin-top: 15px;
`
