import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Title = styled.div`
  display:flex;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 48px;
  font-family: "AppleGothic";
  color: rgb(172, 0, 1);
  font-weight: 750;
`

export const Logo = styled.a`
  background-image: url("/images/logo.svg");
  background-repeat: no-repeat;
  width: 208px;
  height: 90px;
  background-size: 100% 100%;
  font-size: 0;
  text-indent: -9999;
  overflow: hidden;
  margin-bottom: 38px;
  @media only screen and (max-width:415px){
    width: 104px;
    height: 45px;
    margin-bottom: 15px;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  font-family: "AppleGothic";
  font-size: 17px;
  font-weight: 700;
  color: #000000;
  display: inline-block;
  padding: 2px 8px;
  border-bottom: 2px solid rgba(0,0,0,0);
  transition: 0.3s ease;

  &:hover {
    opacity: 0.7;
    border-bottom: 2px solid #980000;
  }

  @media only screen and (max-width:768px){
    font-size: 14px;
    font-weight:600;
  }
  @media only screen and (max-width:375px){
    font-size: 10px;
    font-weight: 600;
  }
`;
