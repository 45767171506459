import React from 'react'
import { Title, Logo, Navigation, List, NavLink } from './styles'

const NavBar = () => {
  return (
    <Title className='title'>
      <Logo href="/login">AIM-HI - Artificial Intelligence Marketing</Logo>
      <Navigation>
        <List>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/contact">Contact</NavLink>
          <NavLink to="/privacy">Privacy</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/news">News</NavLink>
          <NavLink to="/conditions">Terms and Conditions</NavLink>
        </List>
      </Navigation>
    </Title>
  )
}

export default NavBar
