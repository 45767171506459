import React from "react";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";
import axios from "axios";
import UserContext from "../../config/UserContext";
import LinkedIn from "../LinkedIn";
import { SelectPageDialog, InstagramPopDialog } from "../Dialogs";

import {
  hasProvider,
  linkProvider,
  getFbPages,
  facebookPublishMedia,
  getInstagramPages,
  createInstagramMediaContainer,
  instagramPublishMedia,
  getPagesInstagramBusinessAccount,
} from "../../utils/user";

import { Wrapper, Label, List, Icon } from "./styles";
// import firebase from 'firebase';

// console.log('call anywhere', process.env.REACT_APP_NODE_API)

const Share = ({ post }) => {
  const { user, firebase, setLoading } = React.useContext(UserContext);
  const [fbPages, setFbPages] = React.useState([]);
  const [openAi, setOpenAi] = React.useState([]);
  const [instagramPages, setInstagramPages] = React.useState([]);
  const [displayFbPagesDialog, setDisplayFbPagesDialog] = React.useState(false); // What is this variable?
  const [displayInstagramPagesDialog, setDisplayInstagramPagesDialog] =
    React.useState(false); //Instagram
  const [displayInstagramPopDialog, setDisplayInstagramPopDialog] =
    React.useState(false);
  const db = firebase.firestore();
  const [fbPostIdList, setFbPostIdList] = React.useState(
    post.fbPageIds ? post.fbPageIds : []
  );

  const [count, setCount] = React.useState(1);

  const [imageUrl, setImageUrl] = React.useState(post.data.image);
  const [postCaption, setPostCaption] = React.useState(post.data.body);
  const [isSharingPost, setIsSharingPost] = React.useState(false);
  const [facebookUserAccessToken, setFacebookUserAccessToken] =
    React.useState("");
  const fbConfigId = process.env.REACT_APP_FB_CONFIG_ID;

  const getFbAccessToken = async () => {

    //for fb login for business
    return new Promise((resolve, reject) => {
      window.FB.getLoginStatus(async (response) => {
        if (response.status === "connected") {
          resolve(response.authResponse.accessToken);
        } else {
          window.FB.login(
            function(response) {
              if (response.status === "connected") {
                resolve(response.authResponse.accessToken);
              }else{
                reject('Error in User FB authentication');
              }
            },
            {
              config_id: fbConfigId // configuration ID goes here
            }
          );
        }
      });
    });

    
    //below code is for fb login
    
    // const provider = new firebase.auth.FacebookAuthProvider();
    // // edit FB scope
    // provider.addScope("pages_manage_posts");
    // provider.addScope("pages_read_engagement");
    // provider.addScope("pages_show_list");
    // // ********************added****************
    // provider.addScope("instagram_basic");
    // provider.addScope("instagram_content_publish");

    // return new Promise((resolve, reject) => {
    //   // eslint-disable-next-line
    //   FB.getLoginStatus(async (response) => {
    //     if (response.status === "connected") {
    //       resolve(response.authResponse.accessToken);
    //     } else {
    //       try {
    //         if (!hasProvider(user, "facebook.com")) {
    //           const accessToken = await linkProvider(provider);
    //           resolve(accessToken);
    //         } else {
    //           const result = await firebase.auth().signInWithPopup(provider);
    //           resolve(result.credential.accessToken);
    //         }
    //       } catch (err) {
    //         return reject(err);
    //       }
    //     }
    //   });
    // });
  };

  const shareOnFacebook = async () => {
    try {
      setLoading(true);
      const accessToken = await getFbAccessToken();
      const pages = await getFbPages(accessToken);
      console.debug(">>>>>pages>>>>>>", pages);
      setFbPages(pages);
      setLoading(false);
      setDisplayFbPagesDialog(true);
    } catch (err) {
      console.error("shareOnFacebook", err);
      setLoading(false);
    }
  };

  const publishOnFacebook = async (pageIds) => {
    setDisplayFbPagesDialog(false);
    console.debug("pageIDs>>>>>", pageIds);
    if (pageIds && pageIds.length > 0) {
      try {
        setLoading(true);
        const pages = fbPages.filter((page) => pageIds.includes(page.id));
        console.debug("****selectedPageObjs>>>>>", pages);
        const pageIDs = await Promise.all(
          pages.map((page) => facebookPublishMedia(page, post))
        );
        console.debug("postIDs>>>>>", pageIDs);
        pageIDs.forEach((postId) => {
          window.open(`https://www.facebook.com/${postId}`);
        });
        const newFbPostIdList = fbPostIdList.concat(pageIDs);
        setFbPostIdList(newFbPostIdList);

        // post.fbPageIds = newFbPostIdList;
        db.collection("posts")
          .doc(post.id)
          .update({ fbPageIds: newFbPostIdList });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err.message);
      }
    } else {
      // no page created or selected is handled in SelectPage.js
      // click cancel btn at FB page selection w/t choose any options
      setLoading(false);
    }
  };

  // **********************************Instagram*********************
  /* ****************/ /////////////////////////////////////////////////// */

  const getInstagramAccountId = (facebookPageId, facebookAccessToken) => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        facebookPageId,
        "get",
        {
          fields: "instagram_business_account",
          access_token: facebookAccessToken,
        },
        (response) => {
          if (response.error) {
            reject(response.error);
          } else {
            resolve(response.instagram_business_account.id);
          }
        }
      );
    });
  };

  // async function createMediaObjectContainer(instagramAccountId) {
  //   try {
  //     let results = await window.FB.api(`${instagramAccountId}/media`, "POST", {
  //       access_token: facebookUserAccessToken,
  //       image_url: imageUrl,
  //       caption: postCaption,
  //     });
  //     console.log("results in container: ", results, typeof results);
  //     return results;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const createMediaObjectContainer = (
    instagramAccountId,
    facebookUserAccessToken
  ) => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        `${instagramAccountId}/media`,
        "POST",
        {
          access_token: facebookUserAccessToken,
          image_url: imageUrl,
          caption: postCaption,
        },
        (response) => {
          if (response.error) {
            reject(response.error);
          } else {
            resolve(response.id);
          }
        }
      );
    });
  };

  const publishMediaObjectContainer = (
    instagramAccountId,
    mediaObjectContainerId,
    fbaccessToken
  ) => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        `${instagramAccountId}/media_publish`,
        "POST",
        {
          access_token: fbaccessToken, //facebookUserAccessToken
          creation_id: mediaObjectContainerId,
        },
        (response) => {
          if (response.error) {
            reject(response.error);
          } else {
            resolve(response.id);
          }
        }
      );
    });
  };

  // Debugging showed setFacebookUserAccessToken(accessToken) is not working for some reason.
  // To fix this, I just manually passed in the accessToken to publishMediaObjectContainer &&
  // getInstagramAccountId calls in this function:
  const shareInstagramPost = async () => {
    setIsSharingPost(true);
    const accessToken = await getFbAccessToken();
    // if (accessToken) {
    //   setFacebookUserAccessToken(String(accessToken));
    // }

    const fbPageID = await getFbPages(accessToken);

    const instagramAccountId = await getInstagramAccountId(
      fbPageID[0].id,
      accessToken
    );

    const mediaObjectContainerId = await createMediaObjectContainer(
      instagramAccountId,
      accessToken
    );
    const results = await publishMediaObjectContainer(
      instagramAccountId,
      mediaObjectContainerId,
      accessToken
    );

    setIsSharingPost(false);
    setDisplayInstagramPopDialog(true);
  };

  /* /////////////////////////////////////////////////////////////////// */

  //   const shareOnInstagram = async () => {
  //     try {
  //       setLoading(true);
  //       const accessToken = await getFbAccessToken();
  //       const pages = await getInstagramPages(accessToken);
  //       setInstagramPages(pages);
  //       setLoading(false);
  //       setDisplayInstagramPagesDialog(true);
  //     } catch (err) {
  //       console.error('shareOnInstagram', err);
  //       setLoading(false);
  //     }
  //   };

  //   const publishOnInstagram = async pageId => {
  //     setDisplayInstagramPagesDialog(false);
  //     console.debug('pageId>>>>>', pageId);
  //     const page = instagramPages.find(page => pageId === pageId);
  //     if (pageId && pageId.length > 0) {
  //     try {
  //       setLoading(true);
  //       const containerId = await createInstagramMediaContainer(page, post);
  //       const data = await instagramPublishMedia(page, containerId);
  //       console.log(data)
  //       // TODO: when this works, open instagram publication
  //     } catch(err) {
  //       setLoading(false);
  //       alert(err.message)
  //       console.error(err);
  //     }
  //   }
  // }

  // **********************************Instagram*********************
  const shareOnTwitter = async () => {
    try {
      setLoading(true);
      if (hasProvider(user, "twitter.com")) {
        //unlink twitter provider
        await firebase.auth().currentUser.unlink("twitter.com");
        const provider = new firebase.auth.TwitterAuthProvider();
        await linkProvider(provider);
      } else {
        const provider = new firebase.auth.TwitterAuthProvider();
        await linkProvider(provider);
      }
      const tweet = await axios.post(
        `${process.env.REACT_APP_NODE_API}/publishOnTwitter`,
        {
          postId: post.id,
        }
      );
      window.open(
        `https://twitter.com/${tweet.data.user.id_str}/status/${tweet.data.id_str}`
      );
      setLoading(false);
    } catch (err) {
      console.error("shareOnTwitter", err);
      setLoading(false);
    }
  };

  const shareOnLinkedIn = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_API}/publishOnLinkedIn`,
        {
          code: data.code,
          postId: post.id,
        }
      );
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  const openInstagram = async (data) => {
    setDisplayInstagramPagesDialog(false);
    window.open("http://instagram.com");
  };

  //========================================================================================================

  //==================
  // CHECK USER AGENT
  //==================

  const findDevice = () => {
    let device = "desktop";
    let mobileDevices = ["iPhone", "Android", "iPad", "Tablet"];
    const { userAgent } = navigator;
    mobileDevices.forEach((mobile) => {
      if (userAgent.includes(mobile)) {
        device = "mobile";
      }
    });
    return device;
  };

  const download = (url) => {
    var storage = firebase.storage();
    var httpsReference = storage.refFromURL(url);
    // console.log('httpsReference',httpsReference)
    var storageRef = storage.ref();

    storageRef
      .child(httpsReference.fullPath)
      .getDownloadURL()
      .then(function (newurl) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function (e) {
          if (this.status === 200) {
            var blob = new Blob([this.response], { type: "image" });
            let a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = "image.jpeg";
            a.click();
            window.URL.revokeObjectURL(url);
          } else {
            console.log("XMLHttpRequest onload image error");
          }
        };
        xhr.open("GET", newurl);
        xhr.send();
      })
      .catch(function (error) {
        console.log("download error", error);
      });
  };

  /** why does this function need to be separate? It's only used in one place. */
  const copyCaption = (body, tags) => {
    const caption =
      body +
      " " +
      tags
        .map((tag) => {
          return tag === "AimHiGlobal" ? "@AimHiGlobal" : `#${tag}`;
        })
        .join(" ");

    const textarea = document.createElement(`textarea`);
    document.body.appendChild(textarea);
    textarea.value = caption;
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  const openIG = () => {
    window.open("https://www.instagram.com/");
  };

  /* ********************************************************************************* */
  const shareOnInstagram2 = async (pages) => {
    try {
      setLoading(true);
      const facebookPages = await getInstagramPages();
      const instagramAccountId = await getInstagramPages(facebookPages[0].id);
      // const mediaObjectContainerId = await createMediaObjectContainer(
      //   instagramAccountId
      // );
      // await firebase.auth().currentUser.Promise;
      // const pages = await getInstagramPages(accessToken);
      console.debug(">>>>>pages>>>>>>", pages);
      setInstagramPages(pages);
      setLoading(false);
      setDisplayInstagramPagesDialog(true);
    } catch (err) {
      console.error("shareOnInstagram", err);
      setLoading(false);
    }

    const instagramPublishMedia = async (pageIds) => {
      setDisplayInstagramPagesDialog(false);
      console.debug("pageIDs>>>>>", pageIds);
      if (pageIds && pageIds.length > 0) {
        try {
          setLoading(true);
          const pages = instagramPages.filter((page) =>
            pageIds.includes(page.id)
          );
          console.debug("****selectedPageObjs>>>>>", pages);
          const pageIDs = await Promise.all(
            pages.map((page) => createInstagramMediaContainer(page, post))
          );
          console.debug("postIDs>>>>>", pageIDs);
          pageIDs.forEach((postId) => {
            window.open(`https://www.instagram.com/${postId}`);
          });
          const newdisplayInstagramPopDialog =
            displayInstagramPopDialog.concat(pageIDs);
          setDisplayInstagramPopDialog(newdisplayInstagramPopDialog);
          console.log("I can't make it");
          // post.fbPageIds = newFbPostIdList;
          db.collection("posts")
            .doc(post.id)
            .update({ instagramPages: pageIds.newdisplayInstagramPopDialogt });
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.error(err.message);
        }
      } else {
        // no page created or selected is handled in SelectPage.js
        // click cancel btn at FB page selection w/t choose any options
        setLoading(false);
      }
      setDisplayInstagramPopDialog(true);
    };

    // const device = findDevice()

    // if(device === 'desktop'){
    //   setDisplayInstagramPopDialog(true);
    // } else {
    // TODO: wait for image download completed then open tab

    // download(post.data.image);
    // copyCaption(post.data.body, post.data.tags)
    // window.setTimeout(openIG, 3000, null, null);

    // eslint-disable-next-line no-extend-native

    // console.log('function prototype', Function)
    // setTimeout(openIG, 4000);
    // setTimeout( window.open('https://www.instagram.com/').bind(this), 4000);
    // if(count<=60){
    //   setInterval(() => {
    //     setCount(count => count + 1);
    //   }, 500);
    // }

    // start timer after click
    // if timer ended
  };
  /* ****************************************************************************************************** */
  //========================================================================================================

  return (
    <Wrapper>
      <Label>Share on:</Label>
      <List>
        <li>
          <Icon
            title="Instagram"
            onClick={(e) => {
              shareInstagramPost(e.target.Promise);
            }}
          >
            <ReactSVG src="/images/social/instagram.svg" />
          </Icon>
        </li>
        <li>
          <Icon title="Facebook" onClick={shareOnFacebook}>
            <ReactSVG src="/images/social/facebook.svg" />
          </Icon>
        </li>
        <li>
          <Icon title="Twitter" onClick={shareOnTwitter}>
            <ReactSVG src="/images/social/twitter.svg" />
          </Icon>
        </li>
        <li>
          <LinkedIn
            onSuccess={(data) => shareOnLinkedIn(data)}
            onFailure={console.error}
          >
            {(handleClick) => (
              <Icon title="LinkedIn" onClick={handleClick}>
                <ReactSVG src="/images/social/linkedin.svg" />
              </Icon>
            )}
          </LinkedIn>
        </li>
        {/* <li>{count}</li> */}
      </List>
      <SelectPageDialog
        isOpen={displayFbPagesDialog}
        title="Select page"
        description={
          <p>
            Select the page from the list that you want to publish this post:
          </p>
        }
        options={fbPages}
        onClose={publishOnFacebook}
      />

      {/* <SelectPageDialog
        isOpen={displayInstagramPagesDialog}
        title="Notice"
        description={<p>Your instagram post has been published!</p>}
        options={instagramPages}
        onClose={() => setDisplayInstagramPagesDialog(false)}
      /> */}

      <InstagramPopDialog
        isOpen={displayInstagramPopDialog}
        title="Notice"
        description={<p>Your instagram post has been published!</p>}
        onClose={() => setDisplayInstagramPopDialog(false)}
      />
    </Wrapper>
  );
};

Share.propTypes = {
  post: PropTypes.object.isRequired,
};

export default Share;
