import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Wrapper, Button, Description, Dropdown } from "./styles";

const SelectFbPage = ({ isOpen, title, description, options, onClose }) => {
  const [selectedOption, setSelectedOption] = React.useState({});

  // React.useEffect(() => {
  //   setSelectedOption(options.length ? options[0].id : null);
  // }, [options]);

  const handleCheck = (ifchecked, selectedID) => {
    if (ifchecked) {
      selectedOption[selectedID] = true;
    } else {
      delete selectedOption[selectedID];
    }
    setSelectedOption(selectedOption);
  };
  const publish = () => {
    setSelectedOption({});
    // console.log("in publish function publishing", Object.keys(selectedOption));
    onClose(Object.keys(selectedOption));
  };

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <Wrapper>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Description>{description}</Description>
          <Dropdown>
            {/* <select
              disabled={!options.length}
              onChange={e => setSelectedOption(e.target.value)}
              defaultValue={selectedOption}
            >
              {!options.length && (
                <option>No pages available</option>
              )}
              { options.map(option => (
                <option key={option.id} value={option.id}>{option.name}</option>
              ))}
            </select> */}

            {!options.length && <div>No pages available</div>}
            {options.map((option) => (
              <div>
                <input
                  type="checkbox"
                  value={option.id}
                  onClick={(e) => handleCheck(e.target.checked, e.target.value)}
                />
                <label>{option.name}</label>
              </div>
            ))}
          </Dropdown>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            type="button"
            onClick={() => publish()}
            disabled={!selectedOption}
          >
            Publish
          </Button>
        </DialogActions>
      </Wrapper>
    </Dialog>
  );
};

SelectFbPage.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.object,
  options: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

SelectFbPage.defaultProps = {
  isOpen: false,
  title: null,
  description: null,
};

export default SelectFbPage;
