import React from 'react';
import ReactSVG from 'react-svg';
import AutosizeInput from 'react-input-autosize';
import UserContext from '../../../config/UserContext'
import { addFavoriteTag } from '../../../utils/posts';

import {
  Tag,
  AddTag,
  AddTagInput
} from './styles';

const clickOutsideFavTag = (ref,handler) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        const handleClickOutside = e => {
        if(ref.current && !ref.current.contains(e.target)){
            handler()
        } else {
            return;
        }
        }
        document.addEventListener('mousedown',handleClickOutside);
        return()=>{
        document.removeEventListener('mousedown',handleClickOutside);
        }
    }, [handler, ref]);
}

const AddFavoriteTagForm = ({ tags }) => {
    const { user } = React.useContext(UserContext);
    const inputEl = React.useRef(null);
    const [tag, setTag] = React.useState('');
    const [isOpen, setOpen] = React.useState(false);
    const favTagRef = React.useRef(null);

    const handleSubmit = async e => {
        e.preventDefault();
        if (tag) {
            setOpen(false);
            setTag('');
            await addFavoriteTag(user.uid, tags,tag);
        } 
    };

    const handleClick = async e => {
        e.preventDefault();
        if (!isOpen) {
            setOpen(true);
            inputEl.current.focus();
        } else {
            if(tag){
            setOpen(false);
            setTag('');
            await addFavoriteTag(user.uid,tags,tag)
            } else {
            setOpen(false);
            }
        }
    };

    const saveFavTagHanlder = async () => {
        setTag('');
        if(tag){
            await addFavoriteTag(user.uid,tags,tag)
        }
    }
  
    clickOutsideFavTag(favTagRef, ()=>saveFavTagHanlder())

    return (
    <Tag addtag ref={favTagRef}>
        <AddTagInput isOpen={isOpen}>
        <form onSubmit={handleSubmit}>
            #
            <AutosizeInput
            ref={inputEl}
            name="form-field-name"
            value={tag}
            onChange={ event => setTag(event.target.value.replace(/[^a-z0-9]/gi,'')) }
            />
        </form>
        </AddTagInput>
        <AddTag onClick={handleClick}>
        <ReactSVG src="/images/icons/add.svg" />
        </AddTag>
    </Tag>
    );
};

export default AddFavoriteTagForm;
