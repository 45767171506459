import firebase from 'firebase';
// import * as admin from 'firebase-admin';


export const hasProvider = (user, providerId) => {
  return !!user.providerData.find(provider => provider.providerId === providerId);
};

export const userExists = async (db, uid) => {
  const snapshot = await db.collection('users').doc(uid).get();

  return snapshot.exists;
};


export const userLastPay = async (db,uid) => {
  const userRef = db.collection('users').doc(uid);
  const userInfo = await userRef.get();
  return userInfo.data()
}

const saveTwitterToken = async (uid, accessToken, secret) => {
  const db = firebase.firestore();

  try {
    const payload = {
      twitterAccessToken: accessToken,
      twitterSecret: secret,
    };

    if (await userExists(db, uid)) {
      return db.collection('users').doc(uid).update(payload);
    }

    return db.collection('users').doc(uid).set(payload);
  } catch (error) {
    console.error(error);
  }
};

export const linkProvider = async (provider) => {
  let curProvider;
  if(provider.providerId === 'facebook.com'){
    curProvider = new firebase.auth.FacebookAuthProvider()
  } else if(provider.providerId === 'twitter.com'){
    curProvider = provider
  }
  const result = await firebase.auth().currentUser.linkWithPopup(curProvider);
  const { providerId, accessToken, secret } = result.credential;
  const { uid } = result.user;
  if (provider.providerId === 'twitter.com') {
    await saveTwitterToken(uid, accessToken, secret);
  }

  return accessToken;
};

export const linkedInAuthorizationUrl = () => {
  let url = 'https://www.linkedin.com/oauth/v2/authorization';

  url += '?response_type=code';
  url += `&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}`;
  url += `&redirect_uri=${process.env.REACT_APP_LINKEDIN_REDIRECT_URI}`;
  url += `&state=${process.env.REACT_APP_LINKEDIN_STATE}`;
  url += '&scope=r_liteprofile%20r_emailaddress%20w_member_social';

  return url;
};

export const getFbPages = accessToken => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    FB.api('me/accounts', 'get', {
      access_token: accessToken
    }, (res) => {
      if (res.error) {
        reject(res.error);
      } else {
        resolve(res.data);
      }
    });
  });
};

export const getInstagramPages = async accessToken => {
  const getData = async () => {
    const pages = await getFbPages(accessToken);
    // console.log('checkhere????',pages)
    return await Promise.all(pages.map(async page => {
      const account = await getPagesInstagramBusinessAccount(accessToken, page.id);
      return {...page, instagram_business_account: account}
    }))
  };

  const instagramPages = await getData();
  return instagramPages.filter(page => page.instagram_business_account);
};

const getPagesInstagramBusinessAccount = (accessToken, pageId) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    FB.api(pageId, 'get', {
      access_token: accessToken,
      fields: 'instagram_business_account'
    }, (res) => {
      if(res.error) {
        reject(res.error);
      } else {
        resolve(res.instagram_business_account);
      }
    })
  });
}

export const facebookPublishMedia = (page, post) => {
  return new Promise((resolve, reject) => {
    const { body, tags, image } = post.data;
    const caption = body
    + " "
    + tags.map(tag => {
        return tag === "AimHiGlobal" ? "@AimHiGlobal" : `#${tag}`;
    }).join(' ');

    // eslint-disable-next-line
      FB.api(`${page.id}/photos`, 'post', {
        access_token: page.access_token,
        url: image,
        caption
      }, res => {
        if (res.error) {
          reject(res.error);
        } else {
          resolve(res.post_id);
        }
      });
  });
};

export const createInstagramMediaContainer = (page, post) => {
  return new Promise((resolve, reject) => {
    const { body, image } = post.data;
    // eslint-disable-next-line
    FB.api(`${page.instagram_business_account.id}/media`, 'post', {
      access_token: page.accessToken,
      image_url: image,
      caption: body
    }, res => {
      if(res.error) {
        reject(res.error);
      } else {
        resolve(res);
      }
    });
  });
}

export const instagramPublishMedia = (page, containerId) => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    FB.api(`${page.instagram_business_account.id}/media_publish`, 'post', {
      access_token: page.accessToken,
      creation_id: containerId
    }, res => {
      if(res.error) {
        reject(res.error);
      } else {
        resolve(res);
      }
    });
  });
}

export const findBrowser = () => {
  let sBrowser = navigator.userAgent;
  let sUsrAg = navigator.userAgent;

  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = "Samsung Internet";
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft Internet Explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "Microsoft Edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome or Chromium";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown";
  }

  var osSys = "Not known"; 
  if (navigator.appVersion.indexOf("Win") != -1) osSys =  
    "Windows OS"; 
  if (navigator.appVersion.indexOf("Mac") != -1) osSys =  
    "MacOS"; 
  if (navigator.appVersion.indexOf("X11") != -1) osSys =  
    "UNIX OS"; 
  if (navigator.appVersion.indexOf("Linux") != -1) osSys =  
    "Linux OS"; 

  // console.log("Name: ", Name); 
  // console.log("Browser: ", sBrowser);
  // console.log("NavID: ", window.navigator.userAgent);
  
  return sBrowser; 

}

export const findDevice = () => {
  let device = 'desktop';
  let mobileDevices = ['iPhone','Android','iPad','Tablet']
  const {userAgent} = navigator;
  mobileDevices.forEach((mobile)=>{
    if(userAgent.includes(mobile)){
      device = 'mobile'
    }
  })
  return device; 
}


