import React from 'react';
import NavBar from '../../components/NavBar';
import { Wrapper, Logo, Title, Navigation, NavLink, List, Body, Subject,Footer} from './styles';

const PrivacyPolicy = () => (
  <Wrapper>
    <NavBar />
    <Body>
      <Subject >Privacy Policy</Subject>
         <ul>
           <li>AIM-HI is a productivity app that will help small businesses with their content marketing.</li>
           <li>All the pictures on the Aim-Hi website will be uploaded by our customer and therefore is the responsibility of the client. i.e. copyright images, etc.</li>
           <li>Aim-Hi uses machine learning solely to improve efficiency of our customers.</li>
           <li>Aim-Hi is an automated platform, any information which is reported will be analysed and removed if it does not fit legal requirements of US Law.</li>
           <li>Any information we collect is solely for posting content on the social media page.</li>
           <li>Any user/client or entity which signs up for this service allows access to their social media accounts, all posts will be only published if previously approved by the user.</li>
           <li>We retain information and track it as necessary.</li>
           <li>All information will be kept confidential.</li>
           <li>Any user/client may delete their account information data, or have the option to delete social media posts before
           posting from our platform.</li>
           <li>All activities are conducted for marketing purposes, any illegal activities will be reported to the authorities.</li>
           <li>If you are under the age of 13, please do not submit any Personal Information through our Service. If you are the age of 18, please get the approval of a parent or consenting adult who is responsible for you.</li>
           <li>We will not provide your information to a third party.</li>
           <li>If any of these policies change, we will let customers know prior to changing the policy.</li>
           <li>Cookies may be collected to store information from our clients.</li>
           <li>Clients will have the right to edit information on their profiles.</li>
           <li>We may need to store information to help us access your accounts on third party SaaS application for example: Facebook, LinkedIn, Twitter etc. We will store your app credentials in an encrypted form.</li>
         </ul>
    </Body>
    <Footer>
        Copyright &copy; Aim-Hi 2020
    </Footer>
  </Wrapper>
)

export default PrivacyPolicy;
