import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import firebase from 'firebase';
import AWS from 'aws-sdk';
import CircularProgress from '@material-ui/core/CircularProgress';

import UserContext from './config/UserContext';
import GlobalStyle from './config/styles/GlobalStyle';
import theme from './config/styles/theme';
import firebaseConfig from './config/firebase';

import PrivacyPolicy from './pages/PrivacyPolicy';
import About from './pages/About';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Posts from './pages/Posts';
import Checkout from './pages/Checkout/';
import Statistics from './components/Statistics/Statistics';
import Settings from './pages/Settings/';
import News from './pages/News/'
import LinkedInPopUp from './components/LinkedIn/popup';
import Conditions from './pages/Conditions';
// import ParamInput from './components/StatsDataCharts/ParamInput';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

import { sortByDate } from './utils/posts';

require('dotenv').config();


firebase.initializeApp(firebaseConfig);

AWS.config.update({
  region: 'us-east-2',
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
  }),
});

AWS.region = 'us-east-2';

const Loading = styled.div`
  position: absolute;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(255,255,255, 0.7);

  .MuiCircularProgress-svg {
    color: #980000;
  }
`;

function App() {
  const [firstTime, setFirstTime] = useState(false);
  const [user, setUser] = useState(false);
  const [posts, setPosts] = useState([]);
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [addAimHiTag, setAddAimHiTag] = useState('reinitialized');
  const [aiTurnOff, setAiTurnOff] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const db = firebase.firestore();

  /** This listener always uses stale state values from initialization. Thus variables such as profile may not be accessed.*/
  const addDbListener = () => {
    db.collection('users')
      .doc(user.uid)
      .onSnapshot(
        querySnapshot => {
          const data = querySnapshot.data();
          // user.uid is already initialized 
          if (data) {
            setAddAimHiTag(data.aimHiTag === undefined ? true : data.aimHiTag);
            setAiTurnOff(data.aiTurnOff === undefined ? false : data.aiTurnOff);
            setProfile(data);
            setFirstTime(!data.name || !data.category);
          }
          // user.uid is NOT initialized 
          else {
            setAddAimHiTag(true);
            setFirstTime(true);
          }
        },
        error => {
          console.error(error);
        }
      );

    /* Query database for posts, then add to an array of posts which is passed down through context. */
    db.collection('posts')
      .where('uid', '==', user.uid)
      .onSnapshot(
        querySnapshot => {
          const postList = [];
          querySnapshot.forEach(doc => {
            const post = doc.data();
            if (post.image.length && post.body.length) {
              postList.push({
                id: doc.id,
                data: post
              });
            };
          });
          setPosts(postList.sort(sortByDate));
        },
        error => {
          console.error(error);
        }
      );
  };


  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      userData => {
        setUser(userData);
        setLoading(false);
        setLoadingUser(false);
        console.debug('user', userData);
      }
    );

    return () => {
      unregisterAuthObserver();
    }
  }, []);


  React.useEffect(() => {
    if (user) {
      addDbListener();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, firstTime]);

  // window.fbAsyncInit = function () {
  //   window.FB.init({
  //     appId: '467338260658658',
  //     cookie: true,                // Enable cookies to allow the server to access the session.
  //     xfbml: true,                // Parse social plugins on this webpage.
  //     version: 'v9.0'                // Use this Graph API version for this call. version 7.0 is latest , or 8.0 
  //   });
  // };

  // (function (d, s, id) {
  //   var js, fjs = d.getElementsByTagName(s)[0];
  //   if (d.getElementById(id)) { return; }
  //   js = d.createElement(s); js.id = id;
  //   js.src = "//connect.facebook.net/en_US/sdk.js";
  //   fjs.parentNode.insertBefore(js, fjs);
  // }(document, 'script', 'facebook-jssdk'));


  /** handles a click on the AimHiTagOption component, in the settings page */
  // These settings could possibly be stored in a "settings" collection under each user instead
  const handleAimHiTagClick = (event) => {
    db.collection('users').doc(user.uid).set({
      aimHiTag: event.target.checked
    }, { merge: true }).catch(err => console.error(err));

    setAddAimHiTag(event.target.checked);
  }

  /** handles a click on the AiTurnOffOption component, in the settings page */
  const handleAiTurnOffClick = (event) => {
    db.collection('users').doc(user.uid).set({
      aiTurnOff: event.target.checked
    }, { merge: true }).catch(err => console.error(err));

    setAiTurnOff(event.target.checked);
  }

  // app returns loading window until the facebook sdk is finished loading
  // return (
  // return window.FB === undefined ? (<div>  </div>) : (
    return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <UserContext.Provider value={{ firebase, user, firstTime, profile, posts, setLoading }}>
          {loading && <Loading><CircularProgress size={60} /></Loading>}
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/news" component={News} />
            <Route exact path="/linkedin/" component={LinkedInPopUp} />
            <Route exact path="/conditions" component={Conditions} />
            {/* <Route exact path='/chartmockup' component={ParamInput} /> */}

            <ProtectedRoute exact path='/checkout' loadingUser={loadingUser} user={user} onFailureRedirectToPath="/login">
              <Checkout />
            </ProtectedRoute >

            <ProtectedRoute exact path='/statistics' loadingUser={loadingUser} user={user} onFailureRedirectToPath="/login">
              <Statistics />
            </ProtectedRoute >

            <ProtectedRoute exact path='/settings' loadingUser={loadingUser} user={user} onFailureRedirectToPath="/login">
              <Settings
                handleAimHiTagClick={handleAimHiTagClick}
                handleAiTurnOffClick={handleAiTurnOffClick}
                addAimHiTag={addAimHiTag}
                aiTurnOff={aiTurnOff}
              />
            </ProtectedRoute>

            <ProtectedRoute exact path='/posts' loadingUser={loadingUser} user={user} onFailureRedirectToPath="/login">
              <Posts
                addAimHiTag={addAimHiTag}
                aiTurnOff={aiTurnOff}
                handleAiTurnOffClick={handleAiTurnOffClick}
              />
            </ProtectedRoute>

            {/* Unknown paths redirect to home, but consider redirecting to 404 page */}
            <ProtectedRoute path='/' loadingUser={loadingUser} user={user} onFailureRedirectToPath="/login">
              <Posts
                addAimHiTag={addAimHiTag}
                aiTurnOff={aiTurnOff}
                handleAiTurnOffClick={handleAiTurnOffClick}
              />
            </ProtectedRoute>

          </Switch>
        </UserContext.Provider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
