import React from 'react';
import UserContext from '../../config/UserContext';
import firebase from 'firebase';
import {linkProvider} from '../user'; 

//===============================================
//------------- General UTILS




//===============================================
//------------- FB UTILS
// 1402600550103108
export const fbInitializer= () => {
    window.fbAsyncInit = function() {
        window.FB.init({
        appId      : '467338260658658',
        cookie     : true,                // Enable cookies to allow the server to access the session.
        xfbml      : true,                // Parse social plugins on this webpage.
        version    : '9.0'                // Use this Graph API version for this call. version 7.0 is latest , or 8.0 
        });
    };  
    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    window.FB.login(function(response) {
        if (response.authResponse) {
        console.log('Welcome!  Fetching your information.... ');
        window.FB.api('/me', function(response) {
        console.log('FaceBook: Good to see you, ' + response.name + '.');
        });
        } else {
        console.log('User cancelled login or did not fully authorize.');
        }
    });
    window.fbAsyncInit = function() {
        window.FB.Event.subscribe('auth.statusChange', function(response) {
            console.log( "FB.Event.subscribe auth.statusChange" );
            console.log( response );
        });
    }
};

export const hasProvider = (user, providerId) => {
    return !!user.providerData.find(provider => provider.providerId === providerId);
};
  
export const userExists = async (db, uid) => {
    const snapshot = await db.collection('users').doc(uid).get();
    return snapshot.exists;
};


export const getFbPages = accessToken => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        'me/accounts', 
        'get', 
        { access_token: accessToken, summary: true }, 
        (res) => {
          if (res.error) {
            reject(res.error);
          } else {
            console.log("getFbPages RESULT: ", res.data); 
            resolve(res.data);
          }
        }
      );
    });
  };

export const getFbFeed = (indexPages) => {
    return new Promise( (resolve, reject) => {
      window.FB.api(
        "/"+indexPages+"/feed", 
        // { access_token: accessToken, summary: true },
        function (res) {
          if (res && !res.error) {
            resolve(res); 
            // console.log("FEED FB USER PAGES: ", indexPages, res); 
          }
          else {
            reject(res); 
            console.log("FEED ERROR: ", indexPages, res.error); 
          }
        } 
    )}
  )};


  export const getPageAccessToken = (pageIndex, token) => {
    return new Promise( (resolve, reject) => {
      window.FB.api('/me/accounts','get', 
      {access_token : token},
        (res) => {
          if(!res || res.error) {
            console.error("Page Token ERROR: ", res.error);
            reject(res); 
          }
          else {
            // console.log('pageAccessToken:', pageIndex, res.data[pageIndex].access_token);
            resolve(res.data[pageIndex].access_token); 
          }
        });
    });
  };

  export const getFbLikes = async (postIndex, since, until, token) => {
    return new Promise ( (resolve, reject) => {
      console.log("token", token)
      // feedIndex = {object-id}
      window.FB.api(
        "/" + postIndex + "/reactions"
        + "?",
        'get',
        // {summary: true, since: since, until: until, access_token: token, appsecret_proof: "see dev console"},
        {summary: true, since: since, until: until, access_token: token},
        function (res) {
          if (res && !res.error) {
            // console.log("inside getFbLikes - total_count: ", res);
            resolve(res); 
          }
          else {
            console.error("likes error: ", res.error); 
            reject(res); 
          }
        }
      )
    })
  }





