import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import UserContext from '../../config/UserContext';
const nodemailer = require('nodemailer');
require('dotenv').config();

/** This dialog is used by the Checkout component, appears after payment */
export default function PaymentInfo({ order, isOpen, onClose }) {
  const { user } = React.useContext(UserContext);
  const basicTierPrice = "199.00";
  const orderMade = order.orderId ? true : false;
  const orderTimeObject = orderMade ? order.orderTime : null;
  const orderTime = orderTimeObject ? (orderTimeObject.getMonth() + 1) + "/" + orderTimeObject.getDate() + "/" + orderTimeObject.getFullYear() : null;
  const subId = orderMade ? order.subscriptionId : null;


  /** Emails a user after signup. This code was originally in cloud functions. As written, it works,
   * except the aim hi email and password will be visible in the website code if they are in the .env file.
   * To remedy this, the firebase "Blaze" plan must be upgraded to, in order to store environment variables
   * in cloud functions. Alternatively, perhaps the password could be stored in the firestore database - though
   * the security of this needs to be checked. */
  const sendEmailMessage = () => {
    console.log(orderMade, orderTime, orderTimeObject, subId)
    console.log(order)
    const userEmail = order.email

    console.debug('email', process.env.REACT_APP_AIMHIEMAIL, 'pass', process.env.REACT_APP_AIMHIGMAILPASSWORD)

    const transport = nodemailer.createTransport({
      service: 'gmail',
      auth: {
        user: `${process.env.AIMHIGMAIL}`,
        pass: `${process.env.AIMHIGMAILPASSWORD}`
      }
    });
    // const { orderTime, orderId, userEmail, userName } = currentOrder;

    const mailOptions = {
      from: `${process.env.AIMHIGMAIL}`,
      to: `${userEmail}`,
      subject: `Welcome to Aim-Hi`,
      text: `Dear userName at company,
        Thank you for singing up for Aim-Hi’s highly intelligent social media marketing platform on ${orderTime}.
        We are excited to have you as a customer!
        If you have any questions, please contact us on aim.hi.global@gmail.com
        Or call us at +14086008836
        Best regards,
        Aimee
        Aim-Hi`
    };

    return transport.sendMail(mailOptions, function (err, info) {
      if (err) {
        console.error(err)
      }
      console.info(info)
    });
  };


  const handleClose = () => {
    onClose()
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <img id="basicPlan" src="/images/payment/confirmation.png" alt="" style={{ width: "80%" }} />
          <div>
            <div>Basic Subscription ${basicTierPrice}/month</div>
            <div>Payment Date {orderTime}</div>
            <div>Confirmation number {subId}</div>
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={sendEmailMessage} color="primary">
            Email
          </Button> */}
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PaymentInfo.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

PaymentInfo.defaultProps = {
  isOpen: false,
  onClose: () => { }
};
