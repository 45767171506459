import React, { useRef } from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
// import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDropzone } from "react-dropzone";
import AiTurnOffOption from "../../pages/Settings/AiTurnOffOption";
import UserContext from "../../config/UserContext";
import { uploadToStorage, callRekognition, savePost, UploadBase64ToStorage } from "../../utils/posts";
import { generatePostBody } from "../../utils/generatePostBody";

import {
  Wrapper,
  Button,
  AddFile,
  AddFileIcon,
  AddFileMain,
  AddFileSecondary,
  ThumbsContainer,
  Thumb,
  ThumbInner,
  ProductLineSection,
  CenteredSection,
  LeftDiv,
  RightDiv,
  DialogActionWrapper,
  DialogContent
} from "./styles";
import CreateImage from "./CreateImage";
import CaptionFile from './CaptionGenerate';

/** This file, the modal for Generating Ad in single dialog.
 * It is then used in the PageHeader component.  */
const GenerateAd = ({ isOpen, onClose, aiTurnOff, handleAiTurnOffClick }) => {
  const { user, profile, firebase } = React.useContext(UserContext);
  const [productLines, setProductLines] = React.useState(null);
  const [selectedProductLine, setSelectedProductLine] = React.useState(null);
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isImageStep, setIsImageStep] = React.useState(true);
  const [isCaptionStep, setIsCaptionStep] = React.useState(false);
  const [postDetails, setPostDetails] = React.useState({});

  const db = firebase.firestore();

  React.useEffect(() => {
    // Reformat product lines into an array so it can be mapped
    if (profile.productLines)
      setProductLines(Object.entries(profile.productLines));
  }, [profile]);

  const onDrop = React.useCallback((acceptedFiles) => {
    // show preview in upload box after image selection
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const handleImageUpload = () => {
    setLoading(true);
    const storage = firebase.storage();
    const reader = new FileReader();
    console.debug(
      "upload image >>>> able to get image file:",
      files,
      "reader:",
      reader
    );
    reader.onload = async () => {
      setLoading(true);
      const binaryStr = reader.result;
      try {
        const data = await callRekognition(binaryStr);
        console.debug(
          "upload image >>>> able to get response data from AI rekognition:",
          data
        );

        let favTags = profile.tags || [];

        // Add product line tags
        let productLine = null;
        if (selectedProductLine) {
          favTags = [...favTags, ...selectedProductLine[1]];
          productLine = selectedProductLine[0];
        }

        const tags = favTags.concat(
          data.Labels.map((label) => label.Name.replace(/[^a-z0-9]/gi, ""))
        );
        console.debug("upload image >>>> able to get all tags:", tags);

        let busContactInfo = [];

        if (profile.businessInfo && profile.businessInfo.web !== "") {
          busContactInfo.push(profile.businessInfo.web);
        }
        if (profile.businessInfo && profile.businessInfo.busPhone !== "") {
          busContactInfo.push(profile.businessInfo.busPhone);
        }
        if (profile.businessInfo && profile.businessInfo.busEmail !== "") {
          busContactInfo.push(profile.businessInfo.busEmail);
        }

        console.debug(
          "upload image >>>> able to get business info",
          busContactInfo
        );
        const snapshot = await uploadToStorage(user, files[0]);
        const url = await storage
          .ref()
          .child(snapshot.ref.location.path)
          .getDownloadURL();

        setPostDetails({
          ...postDetails,
          image: url,
          labels: data.Labels,
          tags,
          productLine: productLine
        });

        setIsImageStep(false);
        setIsCaptionStep(true);
        setLoading(false);
      } catch (err) {
        console.error("handleImageUpload error", err, err.stack);
        setLoading(false);
      }


    }
    reader.readAsArrayBuffer(files[0]);
    setSelectedProductLine(null);
  }

  const handleImageCreate = async (base64) => {
    setLoading(true);
    const storage = firebase.storage();
    setLoading(true);
    const binaryStr = await fetch(base64).then(b => b.arrayBuffer());
    try {
      const data = await callRekognition(binaryStr);
      console.debug(
        "upload image >>>> able to get response data from AI rekognition:",
        data
      );

      let favTags = profile.tags || [];

      // Add product line tags
      let productLine = null;
      if (selectedProductLine) {
        favTags = [...favTags, ...selectedProductLine[1]];
        productLine = selectedProductLine[0];
      }

      const tags = favTags.concat(
        data.Labels.map((label) => label.Name.replace(/[^a-z0-9]/gi, ""))
      );
      console.debug("upload image >>>> able to get all tags:", tags);

      let busContactInfo = [];

      if (profile.businessInfo && profile.businessInfo.web !== "") {
        busContactInfo.push(profile.businessInfo.web);
      }
      if (profile.businessInfo && profile.businessInfo.busPhone !== "") {
        busContactInfo.push(profile.businessInfo.busPhone);
      }
      if (profile.businessInfo && profile.businessInfo.busEmail !== "") {
        busContactInfo.push(profile.businessInfo.busEmail);
      }

      console.debug(
        "upload image >>>> able to get business info",
        busContactInfo
      );
      const snapshot = await UploadBase64ToStorage(user, base64);
      const url = await storage
        .ref()
        .child(snapshot.ref.location.path)
        .getDownloadURL();

      setPostDetails({
        ...postDetails,
        image: url,
        labels: data.Labels,
        tags,
        productLine: productLine
      });

      setIsImageStep(false);
      setIsCaptionStep(true);
      setLoading(false);
    } catch (err) {
      console.error("handleImageUpload error", err, err.stack);
      setLoading(false);
    }
    setSelectedProductLine(null);

  }

  const storePost = async (postDetails) => {
    await savePost(postDetails);
  }

  const handleCaptionCreate = (caption) => {

    let postRecycleTokens;
    if (profile.accessLevel <= 100) {
      postRecycleTokens = 1;
    }
    if (profile.accessLevel === 500) {
      postRecycleTokens = 3;
    }
    if (profile.accessLevel === 600) {
      postRecycleTokens = 4;
    }
    if (profile.accessLevel === 700) {
      postRecycleTokens = 5;
    }

    let originalPostDetails = {
      ...postDetails,
      body: caption,
      autoGenerated: true,
      postRecycleTokens: postRecycleTokens,
      uid: user.uid,
      date: Date.now()
    }
    storePost(originalPostDetails).then(() => {
      setFiles([]);
      setLoading(false);
      onClose();
    })
  }

  const handleProductLineSelection = (event) => {
    setSelectedProductLine(event.target.value);
  };

  const handleClose = () => {
    setIsCaptionStep(false);
    setIsImageStep(true);
    setFiles([]);
    onClose();
  };

  const thumbs = files.map((file) => (
    <Thumb key={file.name}>
      <ThumbInner>
        <img src={file.preview} alt="Preview" />
      </ThumbInner>
    </Thumb>
  ));

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth={true}>
      <Wrapper>
        <DialogTitle style={{ paddingBottom: 10 }}>Generate Ad</DialogTitle>
        {
          isImageStep && <DialogContent>
            <LeftDiv>
              <section>
                {!files.length && (
                  <AddFile {...getRootProps()}>
                    <input {...getInputProps()} />
                    <AddFileIcon>+</AddFileIcon>
                    <AddFileMain>
                      Drag files to upload or <span className="action">Browse</span>
                    </AddFileMain>
                    <AddFileSecondary>
                      Only JPEG and PNG files with max size of 5 MB
                    </AddFileSecondary>
                  </AddFile>
                )}
                {!!files.length && (
                  <AddFile>
                    <ThumbsContainer>{thumbs}</ThumbsContainer>
                  </AddFile>
                )}
                {/* Conditionally display product line options based on access level. */}
                {productLines && profile.accessLevel >= 500 && (
                  <ProductLineSection>
                    <FormControl>
                      <InputLabel id="productLine">Product Line</InputLabel>
                      <Select
                        labelId="productLine"
                        id="productLine"
                        style={{ minWidth: 120 }}
                        value={selectedProductLine}
                        onChange={handleProductLineSelection}
                      >
                        {productLines.map((element) => {
                          return (
                            <MenuItem key={element.indexOf()} value={element}>
                              {element[0]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </ProductLineSection>

                )}
                <CenteredSection>
                  <AiTurnOffOption
                    handleClick={handleAiTurnOffClick}
                    aiTurnOff={aiTurnOff}
                  />
                </CenteredSection>
                <DialogActionWrapper>
                  <DialogActions>
                    {!loading && (
                      <>
                        {!!files.length && (
                          <Button type="button" onClick={handleImageUpload}>
                            Upload
                          </Button>
                        )}
                      </>
                    )}

                  </DialogActions>
                </DialogActionWrapper>
              </section>
            </LeftDiv>
            <RightDiv>
              <CreateImage onCreate={handleImageCreate} />
            </RightDiv>
          </DialogContent>
        }
        {
          isCaptionStep && <CaptionFile onFinish={handleCaptionCreate} />
        }

        <DialogActionWrapper>
          <DialogActions>
            {loading && <CircularProgress size={20} />}
            {!loading && (
              <Button type="button" onClick={handleClose}>
                Cancel
              </Button>)}
          </DialogActions>
        </DialogActionWrapper>
      </Wrapper>
    </Dialog>
  );
};

GenerateAd.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

GenerateAd.defaultProps = {
  isOpen: false,
  onClose: () => { },
};

export default GenerateAd;
