import styled from 'styled-components';

export const Tags = styled.ul`
display: flex;
align-items: center;
flex-wrap: wrap;
list-style: none;
margin: 0 -3px;
padding: 0;
`;

export const Tag = styled.li`
display: flex;
align-items: center;
background-color: #f2cbcb;
margin: 3px;
padding: ${props => props.addtag ? "2px" : "2px 2px 2px 10px"};
border-radius: 15px;
font-size: 13px;
font-weight: bold;
`;

export const DeleteTag = styled.a`
width: 15px;
height: 15px;
margin-left: 8px;
cursor: pointer;

svg {
  width: 15px;
  height: 15px;
  fill: #980000;
}
`;

export const AddTag = styled.a`
width: 15px;
height: 15px;
cursor: pointer;

svg {
  width: 15px;
  height: 15px;
  fill: #980000;
}
`;

export const AddTagInput = styled.div`
max-width: ${props=>props.isOpen ? 'auto' : '0'};
overflow: hidden;
padding-left: ${props=>props.isOpen ? '8px' : '0'};
padding-right: ${props=>props.isOpen ? '8px' : '0'};
transition: max-width 0.5s ease;
height: 15px;

input {
  padding: 0;
  border: 0;
  font-size: 13px;
  font-weight: bold;
  background-color: #f9eaea;
  margin: 0 3px;
  padding: 0 2px;
  min-width: 60px;
}
`;
