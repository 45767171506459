import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Wrapper, Body, Main, PayPal, SelectedTier, Tiers, Tier, TierTitle, TierPrice, TierFeatures, TierFeature, PayButton, SoonButton, Flex, CheckoutCards } from './styles';//TODO Jess how do I use SelectedTier to highlight?
import UserContext from '../../config/UserContext';
import Sidebar from '../../components/Sidebar';
import PaymentInfoDialog from '../../components/Dialogs/PaymentInfo'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


const Checkout = () => {
  const { firebase, user, profile } = React.useContext(UserContext);
  const db = firebase.firestore();
  const [paid, setPaid] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [payOrder, setPayOrder] = React.useState({});
  const [coupon, setCoupon] = React.useState(null)
  const [codeValidation, setCodeValidation] = React.useState(null);
  const [isPaymentConfOpen, setIsPaymentConfOpen] = React.useState(false);
  const refContainer = React.useRef();
  const [readyToPayBasic, setReadyToPayBasic] = React.useState(false);
  const [readyToPayGold, setreadyToPayGold] = React.useState(false);
  const [readyToPaySilver, setreadyToPaySilver] = React.useState(false);
  const [subID, setSubID] = React.useState('');
  // const [readyToPay, setReadyToPay] = React.useState(false);

   //reset tiers to normal
   const resetTiers = () =>{
    document.getElementById("basicTier").style.boxShadow="4px 4px 2px rgba(136, 136, 136, 0.2),-4px 4px 2px rgba(136, 136, 136, 0.2)";
    document.getElementById("silverTier").style.boxShadow="4px 4px 2px rgba(136, 136, 136, 0.2),-4px 4px 2px rgba(136, 136, 136, 0.2)";
    document.getElementById("goldTier").style.boxShadow="4px 4px 2px rgba(136, 136, 136, 0.2),-4px 4px 2px rgba(136, 136, 136, 0.2)";
    setReadyToPayBasic(false);
    setreadyToPaySilver(false);
    setreadyToPayGold(false);
  }

  //basic button selected
  const basicButtonClick = () =>{
    resetTiers();
    setReadyToPayBasic(true);
    document.getElementById("basicTier").style.boxShadow="4px 4px 10px rgb(255 4 4 / 20%), -4px 4px 10px rgb(255 0 0 / 20%)";//add highlight around basic tier
  }

  //silver button selected
  const silverButtonClick = () =>{
    resetTiers();
    setreadyToPaySilver(true);
    document.getElementById("silverTier").style.boxShadow="4px 4px 10px rgb(255 4 4 / 20%), -4px 4px 10px rgb(255 0 0 / 20%)";//add highlight around silver tier
  }

    //gold button selected
  const goldButtonClick = () =>{
    resetTiers();
    setreadyToPayGold(true);
    document.getElementById("goldTier").style.boxShadow="4px 4px 10px rgb(255 4 4 / 20%), -4px 4px 10px rgb(255 0 0 / 20%)";//add highlight around gold tier
  }


  // 3 basic tier plans, 2  of them  need coupon code

  const couponCodes = JSON.parse(process.env.REACT_APP_COUPONS)

  const paypalBtn = (planID, btnID) => {
    if (user) {
      if (loading) {
        window.paypal
          .Buttons({
            style: {
              shape: 'pill',
              color: 'blue',
              layout: 'vertical',
              label: 'paypal',
            },
            createSubscription: function (data, actions) {
              return actions.subscription.create({
                'plan_id': planID
              });
            },
            onApprove: async (data, actions) => {

              /* sample return after successful subscription
              {
                billingToken: " XX-XXXXXXXXXXXXXXXXX"
                facilitatorAccessToken: "XXXX-XXXX-XX_XXXXXX-XX"
                orderID: "XXXXXXXXXXXXXXXXX"
                subscriptionID: "X-XXXXXXXXXXXX"
              }
              */
              const orderId = data.orderID;
              const subscriptionId = data.subscriptionID;
              console.debug('Paypal subscriptionID', subscriptionId)
              const orderTime = new Date();
              const order = {
                userId: user.uid,
                orderId: orderId,
                subscriptionId: subscriptionId,
                email: user.email,
                orderTime: orderTime,
                orderStatus: 'successful'
              }
              // add transaction info to 'payment' db
              db.collection('payment').doc(orderId).set(order)
                .then(function () {
                  console.log("Add payment record successfully!");
                })
                .catch(function (error) {
                  console.error("Error add payment record: ", error);
                });
              // update user's access level
              db.collection('users').doc(user.uid).update({
                lastPaymentTime: orderTime,
                subscriptionId: subscriptionId,
              })
                .then(function () {
                  console.log("Update user's access level successfully!");
                })
                .catch(function (error) {
                  console.error("ErrorUpdate user's access level: ", error);
                })
              setPaid(true);
              setPayOrder(order)

              //open payment popup
              setIsPaymentConfOpen(true)
            }
          })
          .render("#paypal-button-" + btnID.toString())

        setLoading(false)
      }
    }
  }

  const applyCoupon = (e) => {
    e.preventDefault();
    let appliedCoupon = refContainer.current.childNodes[0].firstChild.value.toUpperCase();
    if (couponCodes[appliedCoupon] !== undefined) {
      setCoupon(appliedCoupon)
      setCodeValidation(couponCodes[appliedCoupon].desc)
    } else {
      setCodeValidation('Coupon Invalid')
    }
  }

  useEffect(() => {
    /*
    {first month free: P-8DU575929M508844PL6I4PFA
    first three month free: P-13G82249FY513944BL6I4QVY
    no trial: P-7KH781789D314814DL6I4SEA
    } */

    //Basic Plan
    paypalBtn('P-1JC50726D9079922FMQVVLUA', "basic0")

    // old basic below
    // paypalBtn('P-7KH781789D314814DL6I4SEA', "basic0")

    // paypalBtn('P-4CR85961NE145730LMQU63HQGokd', "basic0")
    // Star Bronze
    paypalBtn('P-5A154493H20738831MK7EPYA', "basic1")
    paypalBtn('P-13G82249FY513944BL6I4QVY', "basic2")


    // Silver Tier
    paypalBtn('P-99X196876M9619216MQVVHVI', "silver0")

    // old silver below
    // paypalBtn('P-81G27494U94759914MK53UXI', "silver0")
    // Swanky Silver
    paypalBtn('P-0WG82506N0020742PMK7EQVY', "silver1")
    paypalBtn('P-13G82249FY513944BL6I4QVY', "silver2")


    // Gold
    paypalBtn('P-66B74053H4085564LMQVVCVA', "gold0")

    //old gold below
    // paypalBtn('P-1TU24480HB659704NMK53WKQ', "gold0")
    // DeAnza Gold
    paypalBtn('P-1U322685P2495804HMK7ERUY', "gold1")
    paypalBtn('P-13G82249FY513944BL6I4QVY', "gold2")

    setSubID(profile.subscriptionId || '')

  }, []);

  if (paid) {
    return <Redirect to="/" />;
  }

  /** Brings up the payment confirmation dialog. For dev test purposes only. */
  // const handleEmailTest = () => {
  //   const orderTime = new Date();
  //   const orderId = '1';
  //   const subscriptionId = 'a sub';
  //   const order = {
  //     userId: user.uid,
  //     orderId: orderId,
  //     subscriptionId: subscriptionId,
  //     email: user.email,
  //     orderTime: orderTime,
  //     orderStatus: 'successful'
  //   }
  //   setPayOrder(order)
  //   setIsPaymentConfOpen(true);
  // }



  return (
    <Wrapper>
      <Sidebar />
      <Main>
        <div style={{ color: "#9D0000", fontSize: "50px", fontWeight: "900", 'text-align': 'right'}}>Payment Options</div>
        <Body>
          <Tiers>
            {/* <div> */}
              <Tier id="basicTier">
                  <TierTitle>
                    Basic
                    <TierPrice>
                      $199 <div>month</div>
                    </TierPrice>
                  </TierTitle>
                  <TierFeatures>
                    <TierFeature>8 Image uploads with AIM-HI content creations</TierFeature>
                    <TierFeature>8 AIM-HI posts stored</TierFeature>
                    <TierFeature>3 content re-generations per post</TierFeature>
                    <TierFeature>Unlimited social media posting (FaceBook, LinkedIn, Twitter)</TierFeature>
                  </TierFeatures>
                {
                  (subID.length === 0 || (subID.length > 0 && subID.includes('cancel')))
                    ? <PayButton onClick={() => basicButtonClick()}> Basic </PayButton>
                    : <PayButton disabled> Subscribed </PayButton>
                }
              </Tier>
              {/* <PayPal style={readyToPayBasic ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                <div>
                  <form >
                    <TextField id="filled-basic" ref={refContainer} variant="outlined" size="small" placeholder="Enter Code" type="text"></TextField>
                    <Button variant="contained" onClick={(e) => applyCoupon(e)}>Apply Code</Button>
                  </form>

                  {codeValidation !== null && codeValidation === 'Coupon Invalid' &&
                    <div style={{ color: 'red', 'font-weight': 'bold' }}>{codeValidation}</div>
                  }
                  {codeValidation !== null && codeValidation !== 'Coupon Invalid' &&
                    <div>{codeValidation}</div>
                  }
                </div>
                <div >
                  <div style={coupon ? { display: 'none' } : { display: 'block' }} id="paypal-button-basic0" />
                  <div style={coupon === process.env.REACT_APP_COUPON25 ? { display: 'block' } : { display: 'none' }} id="paypal-button-basic1" />
                  <div style={coupon === process.env.REACT_APP_COUPONVIP ? { display: 'block' } : { display: 'none' }} id="paypal-button-basic2" />
                </div>
              </PayPal>
            </div> */}
            <Tier id="silverTier">
              <TierTitle>
                Silver
                <TierPrice>
                  $299 <div>month</div>
                </TierPrice>
              </TierTitle>
              <TierFeatures>
                <TierFeature>16 Image uploads with AIM-HI content creations</TierFeature>
                <TierFeature>16 AIM-HI posts stored</TierFeature>
                <TierFeature>4 content re-generations per post</TierFeature>
                <TierFeature>Unlimited social media posting (FaceBook, LinkedIn, Twitter)</TierFeature>
                <TierFeature>Rollover of excess image uploads and re-generations into next month</TierFeature>
                <TierFeature>Detailed Statistics with data visualization and marketing charts</TierFeature>
              </TierFeatures>
              {
                (subID.length === 0 || (subID.length > 0 && subID.includes('cancel')))
                  ? <PayButton onClick={() => silverButtonClick()}> Silver </PayButton>
                  : <PayButton disabled> Subscribed </PayButton>
              }
              {/* <SoonButton disabled> Coming Soon </SoonButton> */}
            </Tier>
            <Tier id="goldTier">
              <TierTitle>
                Gold
                <TierPrice>
                  $399 <div>month</div>
                </TierPrice>
              </TierTitle>
              <TierFeatures>
                <TierFeature>24 Image uploads with AIM-HI content creations</TierFeature>
                <TierFeature>24 AIM-HI posts stored</TierFeature>
                <TierFeature>5 content re-generations per post</TierFeature>
                <TierFeature>Unlimited social media posting (FaceBook, LinkedIn, Twitter)</TierFeature>
                <TierFeature>Rollover of excess image uploads and re-generations into next month</TierFeature>
                <TierFeature>Detailed Statistics with data visualization and marketing charts</TierFeature>
                <TierFeature>Supports GIFs and Video uploads</TierFeature>
              </TierFeatures>
              {
                (subID.length === 0 || (subID.length > 0 && subID.includes('cancel')))
                  ? <PayButton onClick={() => goldButtonClick()}> Gold </PayButton>
                  : <PayButton disabled> Subscribed </PayButton>
              }
              {/* <SoonButton disabled> Coming Soon </SoonButton> */}
            </Tier>
          </Tiers>
          <CheckoutCards>
            <PayPal style={readyToPayBasic ? { visibility: 'visible' } : { visibility: 'hidden' }}>
              <div>
                <form >
                  <TextField id="filled-basic" ref={refContainer} variant="outlined" size="small" placeholder="Enter Code" type="text"></TextField>
                  <Button variant="contained" onClick={(e) => applyCoupon(e)}>Apply Code</Button>
                </form>
                {codeValidation !== null && codeValidation === 'Coupon Invalid' &&
                  <div style={{ color: 'red', 'font-weight': 'bold' }}>{codeValidation}</div>
                }
                {codeValidation !== null && codeValidation !== 'Coupon Invalid' &&
                  <div>{codeValidation}</div>
                }
              </div>
              <div >
                <div style={coupon ? { display: 'none' } : { display: 'block' }} id="paypal-button-basic0" />
                <div style={coupon === process.env.REACT_APP_COUPON25 ? { display: 'block' } : { display: 'none' }} id="paypal-button-basic1" />
                <div style={coupon === process.env.REACT_APP_COUPONVIP ? { display: 'block' } : { display: 'none' }} id="paypal-button-basic2" />
              </div>
            </PayPal>
            <PayPal style={readyToPaySilver ? { visibility: 'visible' } : { visibility: 'hidden' }}>
              <div >
                <form >
                  <TextField id="filled-basic" ref={refContainer} variant="outlined" size="small" placeholder="Enter Code" type="text"></TextField>
                  <Button variant="contained" onClick={(e) => applyCoupon(e)}>Apply Code</Button>
                </form>
                {codeValidation !== null && codeValidation === 'Coupon Invalid' &&
                  <div style={{ color: 'red', 'font-weight': 'bold' }}>{codeValidation}</div>
                }
                {codeValidation !== null && codeValidation !== 'Coupon Invalid' &&
                  <div>{codeValidation}</div>
                }
              </div>
              <div >
                <div style={coupon ? { display: 'none' } : { display: 'block' }} id="paypal-button-silver0" />
                <div style={coupon === process.env.REACT_APP_COUPON25 ? { display: 'block' } : { display: 'none' }} id="paypal-button-silver1" />
                <div style={coupon === process.env.REACT_APP_COUPONVIP ? { display: 'block' } : { display: 'none' }} id="paypal-button-silver2" />
              </div>
            </PayPal>
            <PayPal style={readyToPayGold ? { visibility: 'visible' } : { visibility: 'hidden' }}>
              <div>
                <form >
                  <TextField id="filled-basic" ref={refContainer} variant="outlined" size="small" placeholder="Enter Code" type="text"></TextField>
                  <Button variant="contained" onClick={(e) => applyCoupon(e)}>Apply Code</Button>
                </form>

                {codeValidation !== null && codeValidation === 'Coupon Invalid' &&
                  <div style={{ color: 'red', 'font-weight': 'bold' }}>{codeValidation}</div>
                }
                {codeValidation !== null && codeValidation !== 'Coupon Invalid' &&
                  <div>{codeValidation}</div>
                }
              </div>
              <div>
                <div style={coupon ? { display: 'none' } : { display: 'block' }} id="paypal-button-gold0" />
                <div style={coupon === process.env.REACT_APP_COUPON25 ? { display: 'block' } : { display: 'none' }} id="paypal-button-gold1" />
                <div style={coupon === process.env.REACT_APP_COUPONVIP ? { display: 'block' } : { display: 'none' }} id="paypal-button-gold2" />
              </div>
            </PayPal>
          </CheckoutCards>
          {/* <button onClick={handleEmailTest}>test email</button> */}
          <PaymentInfoDialog order={payOrder} isOpen={isPaymentConfOpen} onClose={() => setIsPaymentConfOpen(false)} />
        </Body>
      </Main>
    </Wrapper>
  )
}

export default Checkout;
