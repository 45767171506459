import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import getImageFromText from "./services/CreateImageService";

import {
    Wrapper,
    Button,
    Thumb,
    ThumbInner,
    CenteredSection,
    FormInput
} from "./styles";
import { Create } from "@material-ui/icons";
import { TextField, CircularProgress } from "@material-ui/core";
import { FormArea } from "../../pages/Contact/styles";

const CreateImage = ({ isOpen, onClose, onCreate }) => {
    const [isTextEntered, setIsTextEntered] = React.useState(null);
    const [textPrompt, setTextPrompt] = React.useState('');
    const [imageSource, setImageSource] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);


    const handleCreate = async () => {
        setIsLoading(true);
        const currentImageSource = await getImageFromText(textPrompt);
        setImageSource(currentImageSource);
        setIsTextEntered(true);
        setIsLoading(false);
    };

    const handleChange = async () => {
        setIsLoading(true);
        const currentImageSource = await getImageFromText(textPrompt);
        setImageSource(currentImageSource);
        setIsLoading(false);
    }

    const handleContinue = () => {
        setImageSource(null)
        setIsTextEntered(null)
        onCreate(imageSource);
    }

    return (
        <Wrapper>

            <FormArea>
                <h2>Create Image using our AI</h2>
            </FormArea>
            <DialogContent>
                <FormInput>
                    <TextField
                        label="Describe Image (eg. bowl of soup)"
                        value={textPrompt}
                        onChange={(e) => setTextPrompt(e.target.value)}
                        fullWidth
                        required
                        variant="outlined"
                    />
                </FormInput>
                {isTextEntered && <img alt="Description" id="created-img" style={{ maxWidth: "100%", height: "auto" }} src={imageSource}></img>}
            </DialogContent>
            <CenteredSection>
                <DialogActions>
                    {
                        !isTextEntered &&
                        <Button
                            type="button"
                            onClick={handleCreate}
                            disabled={isLoading}
                        >
                            {isLoading ? <CircularProgress color="white" size={14} /> : "Create"}
                        </Button>
                    }
                    {
                        isTextEntered &&
                        <>
                            <Button
                                type="button"
                                onClick={handleChange}
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress color="white" size={24} /> : "Change"}
                            </Button>
                            <Button type="button" onClick={handleContinue}>
                                Continue
                            </Button>
                        </>
                    }
                </DialogActions>
            </CenteredSection>
        </Wrapper>
    );
};

export default CreateImage;
