import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from "@material-ui/icons/Add";
import UserContext from "../../../config/UserContext";
import {
  AddAndDelete,
  ProductLine,
  AddNewTagArea,
  TagsArea,
  Section,
  AddProductLine,
} from "./styles";
import ConfirmDelete from "../ConfirmDelete/ConfirmDelete";

export default function ManageProductLines(props) {
  const { user, profile, firebase } = useContext(UserContext);
  // const [productLines, setProductLines] = useState();
  const [productLines, setProductLines] = useState({});
  // const [tagErrorText, setTagErrorText] = useState("");
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [productLineToBeDeleted, setProductLineToBeDeleted] = useState();
  const [textFields, setTextFields] = useState({});
  const [newProductLine, setNewProductLine] = useState();

  const db = firebase.firestore();

  useEffect(() => {
    // Puts product lines into state so it can be manipulated
    if (profile.productLines) {
      let productLinesCopy = JSON.parse(JSON.stringify(profile.productLines));
      setProductLines(productLinesCopy);
    }
  }, [profile]);

  useEffect(() => {
    // Creates blank text fields for holding new hashtag input state
    if (productLines) {
      let textFields = {};
      Object.keys(productLines).forEach((line) => {
        textFields[line] = "";
      });
      setTextFields(textFields);
    }
  }, [productLines]);

  const onChangeHandler = (value, line) => {
    // Handles new hashtag input change
    let newTextFields = { ...textFields };
    newTextFields[line] = value;
    setTextFields(newTextFields);
  };

  const handleAddHashtag = (line) => {
    // If statement to avoid blank hashtags and repeats
    if (
      textFields[line] &&
      productLines[line].indexOf(textFields[line]) === -1
    ) {
      let mutatableProductLines = { ...productLines };
      mutatableProductLines[line].push(textFields[line]);
      setProductLines(mutatableProductLines);
    }
  };

  const handleDeleteHashtag = (line, tagText) => {
    let mutatableProductLines = { ...productLines };
    mutatableProductLines[line] = mutatableProductLines[line].filter(
      (tag) => tag !== tagText
    );
    setProductLines(mutatableProductLines);
  };

  const handleAddProductLine = () => {
    // If statement to avoid blank named product lines and repeats
    // does not catch different capitalizations
    if (newProductLine && productLines[newProductLine] === undefined) {
      let productLinesCopy = JSON.parse(JSON.stringify(productLines));
      productLinesCopy[newProductLine] = [];
      setProductLines(productLinesCopy);
      setNewProductLine("");
    }
  };

  const handleOpenDeleteDialog = (line) => {
    setProductLineToBeDeleted(line);
    setConfirmingDelete(true);
  };

  const handleCloseDeleteDialog = (bool) => {
    if (bool) {
      setConfirmingDelete(false);
      let newProductLines = { ...productLines };
      delete newProductLines[productLineToBeDeleted];
      setProductLines(newProductLines);
    } else {
      setConfirmingDelete(false);
    }
  };

  const handleSubmit = () => {
    props.close();
    var userRef = db.collection("users").doc(user.uid);
    var setWithMerge = userRef.update({
      productLines,
    });
  };

  const handleCancel = () => {
    props.close();
    // setTimeout for UX, users won't see the info change back before modal closes
    if (profile.productLines) {
      setTimeout(() => {
        let productLines = JSON.parse(JSON.stringify(profile.productLines));
        setProductLines(productLines);
        setNewProductLine(null);
      }, 100);
    }
  };

  return (
    <div>
      <ConfirmDelete
        open={confirmingDelete}
        close={handleCloseDeleteDialog}
        line={productLineToBeDeleted}
      />
      <Dialog open={props.open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Manage Product Lines</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Any new post can be assigned to a product line when it is created.
            Product lines allows you to create hashtags that are automatically
            added to any future posts in that product line.
          </DialogContentText>
          {productLines &&
            Object.entries(productLines)
              .sort()
              .map((line, index) => {
                return (
                  <ProductLine key={`a${index}`}>
                    <Section>
                      <b>
                        <p>{line[0]}</p>
                      </b>

                      <AddAndDelete>
                        {/* <IconButton aria-label="edit">
                          <EditIcon />
                        </IconButton> */}
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleOpenDeleteDialog(line[0])}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </AddAndDelete>
                    </Section>
                    <Section>
                      <TagsArea>
                        {line[1].map((element, index) => (
                          <Chip
                            key={index}
                            label={`#${element}`}
                            color="primary"
                            onDelete={() =>
                              handleDeleteHashtag(line[0], element)
                            }
                            style={{ marginRight: 5, marginBottom: 5 }}
                          />
                        ))}
                      </TagsArea>
                      <AddNewTagArea>
                        {/* Breaks without this conditional because text fields is set after product lines */}
                        {textFields && (
                          <TextField
                            value={textFields[line[0]]}
                            onChange={(e) =>
                              onChangeHandler(e.target.value, line[0])
                            }
                            margin="dense"
                            label="New Hashtag"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  #
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment postition="end">
                                  <IconButton
                                    onClick={() => handleAddHashtag(line[0])}
                                    size="small"
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}

                        {/* <Button size="small" onClick={() => console.log(tags)}>
                          Add
                        </Button> */}
                      </AddNewTagArea>
                    </Section>
                    {/* <p>{tagErrorText}</p> */}
                  </ProductLine>
                );
              })}
          <AddProductLine>
            <TextField
              value={newProductLine}
              onChange={(e) => setNewProductLine(e.target.value)}
              margin="dense"
              label="New Product Line"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment postition="end">
                    <IconButton onClick={handleAddProductLine} size="small">
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </AddProductLine>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
