import firebase from 'firebase';
import AWS from 'aws-sdk';
import axios from 'axios';
import { generatePostBody } from '../utils/generatePostBody';


export const uploadToStorage = (user, file) => {
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const ref = storageRef.child(`images/${user.uid}/${file.name}`);
  return ref.put(file);
};

export const UploadBase64ToStorage = (user, base64) =>{
  const storage = firebase.storage();
  const storageRef = storage.ref();
  let fileName = user.uid+''+Date.now();
  const ref = storageRef.child(`images/${user.uid}/${fileName}`);

  //link : https://firebase.google.com/docs/storage/web/upload-files
  return ref.putString(base64,'data_url');
}

export const callRekognition = imageData => {
  const rekognition = new AWS.Rekognition();
  const params = {
    Image: { Bytes: imageData }
  };

  return new Promise((resolve, reject) => {
    rekognition.detectLabels(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};




export const savePost = data => {
  const db = firebase.firestore();

  return new Promise((resolve, reject) => {
    db.collection('posts').add(data)
      .then((docRef) => {
        resolve(docRef.id);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updatePost = (id, body) => {
  const db = firebase.firestore();
  return db.collection('posts').doc(id).update({
    body
  });
};

export const deletePost = id => {
  const db = firebase.firestore();
  return db.collection('posts').doc(id).delete();
};
/* Removed, since cloud functions are no longer being used */
// export const generatePostBody = (labels, businessName, businessCategory) => {
//   return fetch('https://us-central1-aimhidev.cloudfunctions.net/generatePostBody', {
//     method: 'POST',
//     mode: 'cors',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       businessName,
//       businessCategory,
//       labels,
//     }),
//   });
// };

/* This function is also an unnecessary split - if used again, combine into a single function. */
// async function getPostBody(labels, businessName, businessCategory) {
//   // console.info('reko labels', labels);
//   // console.info('business data', businessName, businessCategory);
//   const postBody = await generatePostBody(labels, businessName, businessCategory);
//   const postBodyResult = await postBody.json();
//   // console.info('postBodyResult ----- ', postBodyResult);
//   return postBodyResult;
// }

/** Upon clicking the recycle icon, a new post body is randomly generated and the post is updated. 
 * This should be moved to the same file as generate post body? */
export const recyclePost = (post, businessName, businessCategory) => {
  // console.info('post info', post);
  let postBodyResult = generatePostBody(post.data.labels, businessName, businessCategory)
  // console.info('postBodyResult  1', postBodyResult)
  if (postBodyResult.success) {
    console.info('updating!');
    return updatePost(post.id, postBodyResult.text);
  } else {
    console.info('not updating :(');
    return false;
  }
};


export const addTag = (post, tag) => {
  const db = firebase.firestore();
  const tags = [...post.data.tags];
  tags.push(tag);

  return db.collection('posts').doc(post.id).update({
    tags
  });
};

export const addFavoriteTag = (userId, tags, tag) => {
  const db = firebase.firestore();
  tags.push(tag);

  return db.collection('users').doc(userId).update({
    tags: tags
  });
};

export const removeTag = (post, index) => {
  const db = firebase.firestore();
  const tags = [...post.data.tags];
  tags.splice(index, 1);

  return db.collection("posts").doc(post.id).update({
    tags
  });
};

export const removeFavoriteTag = (userId, tags, index) => {
  const db = firebase.firestore();
  // const newTags = [...tags];
  tags.splice(index, 1);

  return db.collection('users').doc(userId).update({
    tags
  });
};

/** This function is only used in AccountInfo.js. It crops an image.
 * @param { ? } image - ?
 * @param { ? } crop - ?
 * @param {string} fileName - name of the file */
export const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      blob.name = fileName;
      resolve(blob);
    }, 'image/jpeg', 1);
  });
}

/** This function is only used in App.js. */
export const sortByDate = (a, b) => {
  if (a.data.date > b.data.date) {
    return -1;
  }
  if (a.data.date < b.data.date) {
    return 1;
  }
  return 0;
}

/** Deprecated. Removed from cloud functions and put into PaymentInfo component */
// export const sendEmailMessage = (order) => {
//   /*
//     https://us-central1-aimhidev.cloudfunctions.net/emailMessage2 for using nodemailer on cloud function;
//   */

//   return fetch('https://us-central1-aimhidev.cloudfunctions.net/emailMessage2', {
//     method: 'POST',
//     mode: 'cors',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//       order
//     }),
//   })
//     .then(() => { })
//     .catch(err => console.error('send email err', err))
// };

// export const downloadImage = (url) => {
//   return fetch(url, {
//     origin: "*" ,
//     method: "GET",
//   })
//   .then(()=>{})
//   .catch(err=>console.error('send email err', err))
// };

/** As far as I can tell, this function is not used anywhere and is deprecated. */
export const downloadImage2 = (url, filename) => {
  console.log('url', url)
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  }).catch((err) => { console.log('download error', err) });
}

/** As far as I can tell, this function is not used anywhere and is deprecated. */
export function downloadImage(url, filename) {
  return fetch('https://us-central1-aimhidev.cloudfunctions.net/imageDownload', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/octet-stream',
      'Content-Disposition': 'attachment; filename="picture.png"'
    },
    body: JSON.stringify({
      url, filename
    }),
  })
    .then(() => { })
    .catch(err => console.error('send email err', err))

}