import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -10px;
  padding: 0;

  & > li {
    margin: 0 10px 20px;

    @media only screen and (min-width: 980px) {
      width: calc(50% - 20px);
    }

    @media only screen and (min-width: 1300px) {
      width: calc(33.33% - 20px);
    }
  }
`;
