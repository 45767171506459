import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDropzone } from "react-dropzone";

import UserContext from "../../config/UserContext";
import { uploadToStorage, callRekognition, savePost } from "../../utils/posts";
import { generatePostBody } from "../../utils/generatePostBody";
import { Wrapper, Button, Thumb, Description, ThumbInner } from "./styles";

const InstagramPop = ({ isOpen, onClose, title, description, options }) => {
  const { user, profile, firebase } = React.useContext(UserContext);
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState("");
  const db = firebase.firestore();

  const genericNoProductTemplate = "{company}. For moments, Money can buy!";

  React.useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      setName(profile.name || "");
    },
    [files]
  );

  const onDrop = React.useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  const handleUpload = () => {
    const storage = firebase.storage();
    const reader = new FileReader();

    reader.onload = async () => {
      setLoading(true);
      const binaryStr = reader.result;
      try {
        const data = await callRekognition(binaryStr);
        let favTags = profile.tags || [];
        const tags = favTags.concat(
          data.Labels.map((label) => label.Name.replace(/[^a-z0-9]/gi, ""))
        );
        let busContactInfo = [];

        if (profile.businessInfo && profile.businessInfo.web !== "") {
          busContactInfo.push(profile.businessInfo.web);
        }
        if (profile.businessInfo && profile.businessInfo.busPhone !== "") {
          busContactInfo.push(profile.businessInfo.busPhone);
        }
        if (profile.businessInfo && profile.businessInfo.busEmail !== "") {
          busContactInfo.push(profile.businessInfo.busEmail);
        }

        let other = profile.businessInfo ? profile.businessInfo.other : "";

        let busInfoHeader =
          busContactInfo.length === 0
            ? "\n"
            : `\nCheck out ${profile.name} at `;

        if (busContactInfo.length === 1) {
          busInfoHeader += busContactInfo[0] + ". ";
        } else {
          busContactInfo.forEach((info, i) => {
            if (i !== busContactInfo.length - 1) {
              busInfoHeader += info + ", ";
            } else {
              busInfoHeader += info + ". ";
            }
          });
        }

        if (other.length > 0) {
          busInfoHeader += "\n" + other + ". ";
        }

        // console.info('reko labels', data.Labels);
        // console.info('business data', businessName, businessCategory);

        const postBody = await generatePostBody(
          data.Labels,
          profile.name,
          profile.category
        );

        const snapshot = await uploadToStorage(user, files[0]);
        const url = await storage
          .ref()
          .child(snapshot.ref.location.path)
          .getDownloadURL();

        let postRecycleTokens;
        if (profile.accessLevel <= 100) {
          postRecycleTokens = 1;
        }
        if (profile.accessLevel === 500) {
          postRecycleTokens = 3;
        }
        if (profile.accessLevel === 600) {
          postRecycleTokens = 4;
        }
        if (profile.accessLevel === 700) {
          postRecycleTokens = 5;
        }

        await savePost({
          body: postBody.success
            ? postBody.text + busInfoHeader
            : genericNoProductTemplate.replace("{company}", profile.name) +
              busInfoHeader,
          autoGenerated: postBody.success,
          tags,
          image: url,
          uid: user.uid,
          date: Date.now(),
          labels: data.Labels,
          postRecycleTokens,
        });

        let decrementedimgUploadToken =
          profile.controlTokens.imgUploadToken - 1;

        profile.controlTokens.imgUploadToken = decrementedimgUploadToken;
        db.collection("users").doc(user.uid).update({
          controlTokens: profile.controlTokens,
        });

        setFiles([]);
        setLoading(false);
        onClose();
      } catch (err) {
        console.error("handleUpload error", err, err.stack);
        setLoading(false);
      }
    };
    reader.readAsArrayBuffer(files[0]);
  };

  // Function is still being worked on. Delete if still there in a week:
  const handleClose = () => {
    setFiles([]);
    onClose();
  };

  const thumbs = files.map((file) => (
    <Thumb key={file.name}>
      <ThumbInner>
        <img src={file.preview} alt="Preview" />
      </ThumbInner>
    </Thumb>
  ));

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <Wrapper>
        <DialogTitle>{title}</DialogTitle>
        {/* <DialogTitle>
          Instagram sharing is only available on mobile devices or tablets.
        </DialogTitle>
        <br></br>
        <DialogContent>
          Please log on www.aimhiglobal.com on your tablet or mobile device.
        </DialogContent>{" "}
        <DialogContent>
          <section>
            {!files.length && (
              <AddFile {...getRootProps()}>
                <input {...getInputProps()} />
                <AddFileIcon>+</AddFileIcon>
                <AddFileMain>
                  Drag files to upload or <span className="action">Browse</span>
                </AddFileMain>
                <AddFileSecondary>
                  Only JPEG and PNG files with max size of 10 MB
                </AddFileSecondary>
              </AddFile>
            )}
            {!!files.length && (
              <AddFile>
                <ThumbsContainer>{thumbs}</ThumbsContainer>
              </AddFile>
            )}
          </section>
        </DialogContent> */}
        <DialogContent>
          <Description>{description}</Description>
        </DialogContent>
        <DialogActions>
          {!loading && (
            <>
              <Button type="button" onClick={handleClose}>
                Cancel
              </Button>
              {/* {!!files.length && (
                <Button type="button" onClick={handleUpload}>
                  Upload
                </Button>
              )} */}
            </>
          )}
          {loading && <CircularProgress size={20} />}
        </DialogActions>
      </Wrapper>
    </Dialog>
  );
};

InstagramPop.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

InstagramPop.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

export default InstagramPop;
