import React from 'react';
import PropTypes from 'prop-types';

import { List } from './styles';
import Post from '../Post';

const PostList = ({ posts, businessName, businessCategory, addAimHiTag }) => (
  <section>
    <List>
      { posts.map(post => (
        <li key={post.id}>
          <Post post={post} businessName={businessName} businessCategory={businessCategory} addAimHiTag={addAimHiTag} />
        </li>
      ))}
    </List>
  </section>
);

PostList.propTypes = {
  posts: PropTypes.array.isRequired
};

export default PostList;
