import React, { useState, useContext, useEffect } from "react";
import firebase from "firebase";
import { Button, Tab, Tabs } from "@material-ui/core";
import PageHeader from "../../components/PageHeader";
import PostList from "../../components/PostList";
import UserContext from "../../config/UserContext";
import Sidebar from "../../components/Sidebar";
import { Wrapper, Main, ProductLines } from "./styles";
import { element } from "prop-types";
import ManageProductLines from "../../components/Dialogs/ManageProductLines/ManageProductLines";

const Posts = ({ addAimHiTag, aiTurnOff, handleAiTurnOffClick }) => {
  const { profile, posts } = useContext(UserContext);
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const [productLines, setProductLines] = useState();
  const [selectedProductLine, setSelectedProductLine] = useState();
  const [currentTab, setCurrentTab] = useState(0);
  const [manageingProductLines, setManageingProductLines] = useState(false);

  React.useEffect(() => {
    setDisplayedPosts([...posts]);
  }, [posts]);

  React.useEffect(() => {
    // Reformat product lines into an array so it can be mapped, and alphabetize
    if (profile.productLines) {
      let fetchedProductLines = Object.entries(profile.productLines);
      fetchedProductLines.sort();
      setProductLines(fetchedProductLines);
    }
  }, [profile]);

  React.useEffect(() => {
    // Filter posts based on product line whenever it changes
    if (currentTab === 0 && posts) {
      let filteredPosts = posts;
      setDisplayedPosts(filteredPosts);
    } else if (posts && selectedProductLine != null) {
      let filteredPosts = posts.filter(
        (post) => post.data.productLine === selectedProductLine[0]
      );
      setDisplayedPosts(filteredPosts);
    }
  }, [selectedProductLine, currentTab, posts]);

  const handleTabChange = (event, newValue) => {
    setSelectedProductLine(productLines[newValue - 1]);
    setCurrentTab(newValue);
  };

  return (
    <Wrapper>
      <Sidebar />
      <Main>
        <ManageProductLines
          open={manageingProductLines}
          close={() => setManageingProductLines(false)}
          productLines={productLines}
        />
        <PageHeader
          title="My Posts"
          onNewPostClick={() => {}}
          aiTurnOff={aiTurnOff}
          handleAiTurnOffClick={handleAiTurnOffClick}
        />
        {profile.accessLevel >= 500 && (
          <ProductLines>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              style={{ marginBottom: 10 }}
            >
              <Tab label="All Products" />
              {productLines &&
                productLines.map((element) => {
                  return <Tab label={element[0]} />;
                })}
            </Tabs>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={() => setManageingProductLines(true)}
            >
              Manage Product Lines
            </Button>
          </ProductLines>
        )}

        <PostList
          posts={displayedPosts}
          businessName={profile.name}
          businessCategory={profile.category}
          addAimHiTag={addAimHiTag}
          aiTurnOff={aiTurnOff}
        />
      </Main>
    </Wrapper>
  );
};

export default Posts;
