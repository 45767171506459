import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';
import UserContext from '../../config/UserContext';

export default function AimHiTagOption(props) {
  const { profile } = React.useContext(UserContext);

  // checkbox should be disabled for free accounts: user access level < 500
  return profile.accessLevel < 500 ? (
    <form >
      <FormControlLabel
        disabled
        control={
          <Checkbox
            checked={true}
            name="aimhitag"
          />
        }
        label="Add @AimHiGlobal. This option may not be disabled for free accounts."
      >
      </FormControlLabel>
    </form>
  ) : (
      <form >
        <FormControlLabel
          control={
            <Checkbox
              checked={props.addAimHiTag}
              name="aimhitag"
              onClick={(event) => props.handleClick(event)}
            />
          }
          label="Add @AimHiGlobal"
        >
        </FormControlLabel>
      </form>
    )
}