import React from 'react';
import ReactSVG from 'react-svg';

import UserContext from '../../config/UserContext';
import { AccountInfoDialog } from '../Dialogs';

// import { UploadFileDialog} from '../Dialogs';
import {
  Wrapper,
  Toggle,
  Header,
  Brand,
  BrandLogo,
  BrandInfo,
  HamburgerMenu,
  Navigation,
  List,
  Item,
  Signout
} from './styles';
// import UploadFile from '../Dialogs/UploadFile';

const Sidebar = () => {
  const { user, firstTime, profile, firebase } = React.useContext(UserContext);
  const [desktopMenuCollapsed, setDesktopMenuCollapsed] = React.useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const [isConfigModalOpen, setConfigModalOpen] = React.useState(firstTime);

  const defaultImage = '/images/logo.png';
  // const [isUploadModalOpen, displayUploadModal] = React.useState(false);
  // const defaultImage = '/images/public_images_new_logo_crop.png'

  return (
    <Wrapper collapsed={desktopMenuCollapsed}>
      <Toggle onClick={() => setDesktopMenuCollapsed(!desktopMenuCollapsed)} collapsed={desktopMenuCollapsed}><ReactSVG src="/images/icons/menu-toggle.svg" /></Toggle>
      <Header>
        <Brand collapsed={desktopMenuCollapsed}>
          <BrandLogo collapsed={desktopMenuCollapsed} onClick={() => setConfigModalOpen(true)}>
            <img src={profile.profileImage || defaultImage} alt={profile.name} />
          </BrandLogo>
          <BrandInfo>
            <div className="header-info">
              <h1 className="companyName">{profile.name}</h1>
              <h2>{profile.category}</h2>
            </div>
          </BrandInfo>
        </Brand>
        <HamburgerMenu onClick={() => setMobileMenuOpen(!mobileMenuOpen)}><span>Menu</span></HamburgerMenu>
      </Header>
      <Navigation isOpen={mobileMenuOpen}>
        <List>
          <li>
            <Item to="/posts" onClick={() => setMobileMenuOpen(false)} collapsed={desktopMenuCollapsed}>
              <ReactSVG src="/images/icons/menu-share.svg" /><span>My Posts</span>
            </Item>
          </li>
          {/* "false &&" component unnecessary? Why is this here? */}
          {/* {false && <li>
            <Item to="/" onClick={() => {setMobileMenuOpen(false); displayUploadModal(true)}} collapsed={desktopMenuCollapsed}>
              <ReactSVG src="/images/icons/menu-upload.svg" /><span>Upload Image</span>
            </Item>
          </li>} */}
          <li>
            <Item to="/checkout" onClick={() => setMobileMenuOpen(false)} collapsed={desktopMenuCollapsed}>
              <ReactSVG src="/images/icons/menu-checkout.svg" /><span>Subscribe</span>
            </Item>
          </li>
          {/* <li>
            <Item to="/statistics" onClick={() => setMobileMenuOpen(false)} collapsed={desktopMenuCollapsed}>
              <ReactSVG src="/images/icons/statistics.svg" /><span>Statistics</span>
            </Item>
          </li> */}
          <li>
            <Item to="/settings" onClick={() => setMobileMenuOpen(false)} collapsed={desktopMenuCollapsed}>
              <ReactSVG src="/images/icons/settings.svg" /><span>Settings</span>
            </Item>
          </li>
        </List>

        <List>
          <li>
            <Signout onClick={() => firebase.auth().signOut()} collapsed={desktopMenuCollapsed}>
              <ReactSVG src="/images/icons/menu-logout.svg" /><span>Sign out</span>
            </Signout>
          </li>
        </List>
      </Navigation>
      {/* Component doesn't seem to be used here, but is already used in PageHeader. */}
      {/* <UploadFileDialog isOpen={isUploadModalOpen} onClose={() => displayUploadModal(false)} /> */}

      {/* Testing new placement of this settings modal to user profile pic 2/24 */}
      <AccountInfoDialog isOpen={isConfigModalOpen} onClose={() => setConfigModalOpen(false)} showCancelButton={!firstTime} user={user} />

    </Wrapper>
  );
};

export default Sidebar;
