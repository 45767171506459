import React from 'react';
import NavBar from '../../components/NavBar';
// import { Link } from 'react-router-dom';
// import { Background, Wrapper, TopBar, Logo, Inner, Title, Navigation, NavLink, List, Footer, FooterLink } from './styles';
import { Wrapper, Logo, Title, Navigation, NavLink, List, Body, Subject,Value,Date,Footer,Page,Next,NextIcon,LoginList,Slogan,SloganIcon,Sign,Signup,Selected, Unselected, Pic,Image1,Image2,Image3} from './styles';

const About = ({history}) => (

  <Wrapper>
    <NavBar />

    <Body>
      <Subject >About us</Subject>
      <p>
        AIM-HI is a social media app which will help small businesses increase their productivity through the content our app automatically posts online.
      </p>
      <p>
        "AIM-HI" (Artificial Intelligence Marketing - High) is a social media Marketing tool which uses artificial intelligence to automatically create content (social media, posts, ads) to streamline ads in an automated app form.
      </p>
      <p>
        AIM-HI will use machine learning to analyse user's social media and create ads based on this. The difference between AIM-HI and other ads that users see on the side of the page when web browsing, is that AIM-HI actually creates content while other currently available tools just post ads.
      </p>
      <p>
        The goal of AIM-HI is to simplify and decrease costs of social media marketing by automating it to benefit companies.</p>

    </Body>
    <Footer>
        Copyright &copy; Aim-Hi 2020
    </Footer>
  </Wrapper>
)

export default About;
