import React from 'react';
import ReactSVG from 'react-svg';
import AutosizeInput from 'react-input-autosize';

import { addTag } from '../../utils/posts';

import {
  Tag,
  AddTag,
  AddTagInput
} from './styles';

/** This component is the tag form opened when the "+" button is clicked. */
const AddTagForm = ({ post }) => {
  const inputEl = React.useRef(null);
  const [tag, setTag] = React.useState('');
  const [isOpen, setOpen] = React.useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    
    if (tag) {
      setOpen(false);
      setTag('');
      await addTag(post, tag);
    } 
  };

  /** Handles a click on the "+" button by opening or closing the form. */
  const handleClick = async e => {
    e.preventDefault();
    if (!isOpen) {
      setOpen(true);
      inputEl.current.focus();
    } else {
      if(tag){
        setOpen(false);
        setTag('');
        await addTag(post, tag)
      } else {
        setOpen(false);
      }
    }
  };

  return (
    <Tag addtag>
      <AddTagInput isOpen={isOpen}>
        <form onSubmit={handleSubmit}>
          #
          <AutosizeInput
            ref={inputEl}
            name="form-field-name"
            value={tag}
            onChange={ event => setTag(event.target.value.replace(/[^a-z0-9]/gi,'')) }
          />
        </form>
      </AddTagInput>
      <AddTag onClick={handleClick}>
        <ReactSVG src="/images/icons/add.svg" />
      </AddTag>
    </Tag>
  );
};

export default AddTagForm;
