import React from 'react';
import NavBar from '../../components/NavBar';
// import firebase from 'firebase';
// import { Redirect } from 'react-router-dom';
import { Wrapper, Logo, Title, Navigation, NavLink, List, Body, Subject,Value,Date,Footer} from './styles';

class News extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }


  render() {
    return (
      <Wrapper>
        <NavBar />

        <Body>
          <Subject >Values, Mission, Vision</Subject>
          <Date>Auguest 02, 2020</Date>

          <h2>Mission</h2>
          <p>
            Aim-Hi’s mission is to become a go-to platform for all small businesses
            to advertise their company on social media. We will bring more power to
            the hands of small business owners by making their lives more simple through
            the use of Artificial Intelligence so that their content will best represent
            their company. We aim to provide them with a low-cost, simple solution so that
            they can spend time to grow their businesses while we do their marketing for them.

          </p>

          <h2>Vision</h2>
          <p>
            Aim-Hi’s vision is to become the number one place that small businesses can
            go to to solve all their problems and help them grow. Our current vision is
            to help them grow in terms of their social media marketing, while our future
            and more long term goal is to be able to help small businesses with any of their needs.

          </p>

          <h2>Value</h2>
          <p>
            <Value>Honest</Value> – when someone is honest, you know where you stand with them and they
            know where they stand with you
          </p>
          <p>
          <Value>Accountability</Value> – we count on each other as a team, everyone’s role is important
            and if any one of us wasn’t there in the company, we wouldn’t reach where we
            are now, new people need to realize this
          </p>
          <p>
          <Value>Team spirit</Value> – our success is not individual, but rather as a team, if anyone
            in the team succeeds both in their personal life or when working for Aim-Hi,
            we are all happy as if we experienced that success happening to ourselves,
            new people should have a big heart also
          </p>
          <p>
          <Value>Think big, Aim high</Value> – we want our platform to go global with all the Aim-Hi
            subsidiaries and with all the companies we acquire in the future (like google
            or amazon) we don’t want to stick within one industry, but help the world.
            Anyone who we bring onto the team has to be able to envision us going global
            and really believe in us
          </p>
          <p>
            <Value>Aim high</Value> - Big goals, hard work, big rewards. Success is our duty.
          </p>
          <p>
            <Value>Integrity</Value> - We are honest to each other and to our customers. We do what we
            say we are going to do.
          </p>
          <p>
            <Value>Can do</Value> - Positive, results oriented attitude.
          </p>
          <p>
            <Value>Teamwork</Value> - We help each other. We trust each other. We behave as one team and
            succeed as one team.
          </p>
          <p>
            <Value>Customer focus</Value> - Respect and Empathy for Users.
          </p>
          <p>
            <Value>Good Character</Value> - Integrity, Accountability, Transparency.
          </p>
          <p>
            <Value>Work standards</Value> - High Quality & Innovative.
          </p>
          <p>
            <Value>Personal Qualities</Value> - Honesty & Respect, Team work, Communication, Diversity (of
            thought and experiences, but united by the common goal and vision)
          </p>
          <p>
            <Value>Motivated</Value> - Motivation to succeed as a company + motivation to help people
            (our team & our clients)
          </p>
          <h2>Benefits of using Aim-Hi</h2>
          <p>
            Aim-Hi is a social media content creation platform which aims to empower small business owners to help them focus on growing their business while we can help them with creating content. We use artificial intelligence and machine learning techniques to ensure that content is highly accurate and relevant to the small business owners. We ensure that we are very secure for the small business owners by integrating security tools to keep the content encrypted and not visible to anyone other than small business owner.
          </p>
          <p>
            Aim-Hi is a scalable business model as we don't require to hire new staff in order to create content as all of the content is created automatically through the use of artificial intelligence. With most other content creation services, the content is created by a human being which means that that person also has access to all the small business owner's content. These content creation companies also have to hire more staff to create the content or use their limited staff to create the same content which means that the content will not be up to par as a fully automated system such as Aim-Hi.
          </p>
          <p>
            We ensured that Aim-Hi is suitable for all ages by testing our product with users from the age of 8 to around 80 years old and identifying how they use our product. From these tests, we got feedback from the users, which we incorporated in order to make our product very user-friendly by making it simple, quick and efficient. Our goal is that small business owners can feel empowered whatever their age might be as we understand that there are people of all ages who would like to grow their business.
          </p>
          <p>
            Aim-Hi's goal is to make our product as quick as a click of a button. This means that users spend very little time on their marketing and can focus on growing their business. The website works with simple clicks of a button starting off with the uploading of an image followed by the clicking of a button to actually post the caption which we created as well as the hashtags and other content they choose to add to the post.
          </p>
          <p>
            The platform Aim-Hi helps users create a post and upload this post onto social media without them actually creating the content themselves, or post this content one by one on to each social media platform. Our goal is to make this process for them as fast as possible so that they don't have to spend time on their marketing, and don't have to spend tons of money on hiring someone to do the marketing for them.
          </p>

          <p>
            Because we use artificial intelligence our product can be more accurate than anything else on the market as we grow and improve our product based on the users' needs. Also because of the use of artificial intelligence the content can be varied and be tailored to each specific user.
          </p>
          <p>
            Not only do we create the content we also create hashtags which will help improve the visibility of our clients (small business owners), and our use of hashtags aims to be a mix of hashtags relevant to business as well as popular hashtags during that day so that the posts stay very relevant to the time period. We also give the user the option to have their own hashtags which they may choose to have in the post every single time and this allows them to ensure that their company is always linked to a certain type of keyword and thus improving their search engine optimization and company brand and image.
          </p>
          <p>
            Aim-Hi also gives the user the option to add their company website or other information about their product in each post which allows them to save them a significant amount of time which they would otherwise be spending on copy-pasting into each of their posts.
          </p>
          <p>
            Because we want to give our clients as much power as possible in their hands we also give the client the chance to edit the post if we create using our AI as this gives them the option to change the post if required, we analyze this data so that future posts end up being similar to what they currently write.
          </p>
          <p>
            In order to ensure that small business owners feel secure the employee safe programming practices and use the best most current privacy protecting tools that we can find to integrate into our software.
            This way clients can be guaranteed that their information is kept protected.
          </p>
          <p>
            With the ability to log on to our platform through any device including a mobile phone or a laptop or a tablet the clients are able to use this product anywhere they want to post their content and with the future off going fully automated they don't even need to be on their phone to have the content being posted.
          </p>
          <p>
            Our goal as a company is to bring small businesses into the 21st century by giving companies the option to go on social media and promote their business in a more modern way than sending a flyer to the mailbox.
            With the majority of people using social media and the 21st century, this is now the best way to promote a small business.
          </p>
          <p>
            Aim-Hi's goal is to give founders and CEOs more time so that they can spend this time on growing their business.
          </p>
          <p>
            Aim-Hi plans to give small business owners options to market the product at the very low cost so that every small business owner can get the promotion that they need.
          </p>

        </Body>
        <Footer>
            Copyright &copy; Aim-Hi 2020
        </Footer>
      </Wrapper>
    );
  }
}


export default News;
