import React from 'react';
import { Wrapper, Body, Main} from './styles';
import Sidebar from '../../components/Sidebar';
import AimHiTagOption from './AimHiTagOption';
import AiTurnOffOption from './AiTurnOffOption';

const Settings = (props) => {
  // const { firebase, user } = React.useContext(UserContext);

  return (
    <Wrapper>
      <Sidebar />
      <Main>
        <Body>

          <AimHiTagOption handleClick={props.handleAimHiTagClick} addAimHiTag={props.addAimHiTag} />
          <AiTurnOffOption handleClick={props.handleAiTurnOffClick} aiTurnOff={props.aiTurnOff} />

          <div>
            <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_subscr-find&alias=SGGGX43FAKKXN&switch_classic=true">
              <img src="https://www.paypalobjects.com/en_US/i/btn/btn_unsubscribe_LG.gif" />
            </a>
          </div>
        </Body>
      </Main>
    </Wrapper>
  )
}

export default Settings;
