import React from "react";
import PropTypes from "prop-types";
import firebase from "firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDropzone } from "react-dropzone";
import ReactSVG from "react-svg";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./phone.css";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

import AddFavoriteTag from "./AcctInfo/AddFavoriteTag";
import UserContext from "../../config/UserContext";
import { getAccountCategories, saveAccountInfo } from "../../utils/account";
import {
  uploadToStorage,
  getCroppedImg,
  removeFavoriteTag,
} from "../../utils/posts";
import {
  Wrapper,
  Button,
  Description,
  FormInput,
  ProfileImageInput,
  ProfileImageCropper,
  Tags,
  Tag,
  DeleteFavoriteTag,
} from "./styles";

const AccountInfo = ({ isOpen, onClose, showCancelButton }) => {
  const { user, profile, firstTime } = React.useContext(UserContext);
  const [imageRef, setImageRef] = React.useState(null);
  const [uploadedImage, setUploadedImage] = React.useState(null);
  const [crop, setCrop] = React.useState({
    aspect: 1,
    unit: "%",
    width: 100,
  });

  const [name, setName] = React.useState("");
  const [category, setCategory] = React.useState(null);
  const [web, setWeb] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [other, setOther] = React.useState("");
  const [profileImage, setProfileImage] = React.useState(null);
  const [formErrors, setFormErrors] = React.useState({});
  const [tags, setTags] = React.useState([]);
  const db = firebase.firestore();

  const formIsValid = () => {
    let isValid = true;
    const errors = {};

    if (!name) {
      isValid = false;
      errors.name = "This field is required";
    }

    if (!category) {
      isValid = false;
      errors.category = "This field is required";
    }

    setFormErrors(errors);

    return isValid;
  };
  // also save new info
  const saveAccount = async () => {
    if (!formIsValid()) return;

    const image = await saveProfileImage();

    let businessInfo = profile.businessInfo || {};
    businessInfo.web = web;
    businessInfo.busPhone = phone;
    businessInfo.busEmail = email;
    businessInfo.other = other;

    await saveAccountInfo(user.uid, {
      name,
      category,
      image,
      businessInfo,
    });

    if (firstTime === true) {
      // New Baby Tier Access Level 50
      db.collection("users")
        .doc(user.uid)
        .update({
          accessLevel: 50,
          controlTokens: {
            imgUploadToken: 2,
          },
        })
        .then(() => {})
        .catch(function (error) {
          console.error("upload baby tier", error);
        });
    }
  };

  const onProfileImageDrop = React.useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setUploadedImage(
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: onProfileImageDrop,
  });

  const saveProfileImage = async () => {
    if (imageRef && crop.width && crop.height) {
      const storage = firebase.storage();
      const ext = uploadedImage.name.split(".").pop();

      const croppedImage = await getCroppedImg(
        imageRef,
        crop,
        `profilepic.${ext}`
      );
      const snapshot = await uploadToStorage(user, croppedImage);
      const url = await storage
        .ref()
        .child(snapshot.ref.location.path)
        .getDownloadURL();

      return url;
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    setName(profile.name || "");
    setCategory(profile.category ? { title: profile.category } : null);
    setWeb(profile.businessInfo ? profile.businessInfo.web : "");
    setPhone(profile.businessInfo ? profile.businessInfo.busPhone : "");
    setEmail(profile.businessInfo ? profile.businessInfo.busEmail : "");
    setOther(profile.businessInfo ? profile.businessInfo.other : "");
    setUploadedImage(null);
    setProfileImage(profile.profileImage || null);
    setTags(profile.tags || []);
    ///
  }, [profile, user, db]);

  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <Wrapper>
        <DialogTitle>Account Information</DialogTitle>
        <DialogContent>
          <Description>
            {!uploadedImage && (
              <ProfileImageInput {...getRootProps()}>
                <input {...getInputProps()} />
                {!!profileImage && <img src={profileImage} alt={name} />}
                <ReactSVG src="/images/icons/camera.svg" />
              </ProfileImageInput>
            )}
            {!!uploadedImage && (
              <ProfileImageCropper>
                <ReactCrop
                  src={uploadedImage.preview}
                  crop={crop}
                  onChange={(newCrop) => setCrop(newCrop)}
                  onImageLoaded={(image) => setImageRef(image)}
                />
              </ProfileImageCropper>
            )}
            <FormInput>
              <TextField
                label="Business Name"
                variant="outlined"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                error={!!formErrors.name}
                helperText={formErrors.name}
              />
            </FormInput>
            <FormInput>
              <Autocomplete
                options={getAccountCategories()}
                getOptionLabel={(option) => option.title}
                value={category}
                onChange={(event, value) => setCategory(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Business Category"
                    variant="outlined"
                    required
                    fullWidth
                    error={!!formErrors.category}
                    helperText={formErrors.category}
                  />
                )}
              />
            </FormInput>
            <FormInput id="busInfo">
              <fieldset>
                <legend>Business Information</legend>
                <label htmlFor="www">
                  Website:
                  <Input
                    placeholder="Business Website"
                    value={web}
                    id="web"
                    type="url"
                    onChange={(e) => setWeb(e.target.value)}
                  />
                </label>
                <label htmlFor="phone">
                  Phone:
                  <IntlTelInput
                    containerClassName="intl-tel-input"
                    inputClassName="form-control"
                    type="tel"
                    defaultValue={phone}
                    onPhoneNumberChange={(e) =>
                      setPhone(
                        document.getElementById("busInfo").childNodes[0]
                          .childNodes[2].childNodes[1].childNodes[1].value
                      )
                    }
                  />
                  <script src="../../../node_modules/intl-tel-input/build/js/intlTelInput.js"></script>
                </label>
                <label htmlFor="email">
                  Email:
                  <Input
                    placeholder="Business Email"
                    value={email}
                    type="email"
                    id="busemail"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                <label htmlFor="other">
                  Other:
                  <Input
                    placeholder="Business Other"
                    value={other}
                    id="other"
                    onChange={(e) => setOther(e.target.value)}
                  />
                </label>
              </fieldset>
            </FormInput>
            <FormInput>
              <fieldset>
                <legend>Favorite Hashtags</legend>
                {tags.length > 0 ? (
                  <Tags>
                    {tags.map((t, i) => (
                      <Tag key={t}>
                        #{t}
                        <DeleteFavoriteTag
                          onClick={() => removeFavoriteTag(user.uid, tags, i)}
                        >
                          <ReactSVG src="/images/icons/cancel.svg" />
                        </DeleteFavoriteTag>
                      </Tag>
                    ))}
                    <AddFavoriteTag tags={tags} />
                  </Tags>
                ) : (
                  <Tags>
                    <AddFavoriteTag tags={tags} />
                  </Tags>
                )}
              </fieldset>
            </FormInput>
          </Description>
        </DialogContent>
        <DialogActions>
          {showCancelButton && (
            <Button type="button" onClick={() => onClose()}>
              Cancel
            </Button>
          )}
          <Button
            type="button"
            onClick={() => {
              saveAccount();
              onClose();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Wrapper>
    </Dialog>
  );
};

AccountInfo.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  showCancelButton: PropTypes.bool,
};

AccountInfo.defaultProps = {
  isOpen: false,
  showCancelButton: true,
};

export default AccountInfo;
function newFunction() {
  return "check";
}
