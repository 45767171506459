import React, { useState, useRef } from "react";
import { TextField, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
    Wrapper,
    Button,
    CenteredSection,
    FormInput,
    ButtonContainer,
    TextAreaContainer
} from "./styles";
import { generateAdDescription } from "./services";
import { Slider } from "@material-ui/core";
import { StepLabel } from "@material-ui/core";

const CaptionFile = ({ isOpen, onClose, onFinish }) => {

    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [productName, setProductName] = useState(null);
    const [AdvProductName, setAdvProductName] = useState(null)
    const [features, setFeatures] = useState(null);
    const [textField, setTextField] = useState(null)
    const [audience, setAudience] = useState('');
    const [adDescription, setAdDescription] = useState('');
    const [showInputs, setShowInputs] = useState(false);
    const [isGeneratingAdDescription, setIsGeneratingAdDescription] = useState(false);
    const [tokenSize, setTokenSize] = useState(100);
    const [isCaptionGenerated, setIsCaptionGenerated] = useState(false);



    const handleSubmit = async (e) => {

        e.preventDefault();
        setIsGeneratingAdDescription(true);

        const description = await generateAdDescription(productName, features, audience, textField, tokenSize);

        setAdvProductName(productName ? productName : null)
        setAdDescription(description);
        setIsGeneratingAdDescription(false);
        setIsCaptionGenerated(true);
    }

    const handleFinish = () => {
        onFinish(adDescription);
        setIsGeneratingAdDescription(false);
        setProductName(null);
        setFeatures(null);
        setAudience(null);
        setTextField(null);
        formRef.current.reset();
    }

    const handleShowInputs = () => {
        setShowInputs(!showInputs);
    }

    return (
        <Wrapper>
            <CenteredSection>
                <Typography style={{ paddingBottom: 10 }} >Enter caption info using {showInputs ? "Inputs" : "Short Text"}</Typography>
            </CenteredSection>
            <DialogContent>
                <section>
                    <form ref={formRef} onSubmit={handleSubmit} >
                        {showInputs ? (
                            <  >
                                <FormInput>
                                    <TextField
                                        label="Product Name"
                                        variant="outlined"
                                        required
                                        value={productName}
                                        onChange={(e) => setProductName(e.target.value)}
                                        fullWidth
                                    />
                                </FormInput>
                                <FormInput>
                                    <TextField
                                        label="Features"
                                        variant="outlined"
                                        required
                                        value={features}
                                        onChange={(e) => setFeatures(e.target.value)}
                                        fullWidth
                                    />
                                </FormInput>
                                <FormInput>
                                    <TextField
                                        label="Target Audience"
                                        variant="outlined"
                                        required
                                        value={audience}
                                        onChange={(e) => setAudience(e.target.value)}
                                        fullWidth
                                    />
                                </FormInput>
                                <FormInput>
                                    <StepLabel>Max Word count</StepLabel>
                                    <Slider
                                        aria-label="Temperature"
                                        valueLabelDisplay="auto"
                                        step={50}
                                        marks
                                        min={100}
                                        max={1000}
                                        value={tokenSize}
                                        onChange={(e) => setTokenSize(e.target.value)}

                                    />
                                </FormInput>
                                <ButtonContainer>
                                    <Button variant="contained" onClick={handleShowInputs}> Create caption using Short Text </Button>
                                    <Button variant="contained" color={productName ? "primary" : 'default'} type="submit" onClick={handleSubmit}>
                                        {isGeneratingAdDescription ? <CircularProgress style={{ color: 'white' }} size={20} /> : 'Generate Ad Description'}
                                    </Button>

                                </ButtonContainer>


                            </>
                        ) : (
                            <div>
                                <FormInput>
                                    <StepLabel>Give a short descriptions about Product and its features</StepLabel>
                                    <TextField
                                        value={textField}
                                        onChange={(e) => setTextField(e.target.value)}
                                        fullWidth
                                        required
                                        variant="standard"
                                    />
                                </FormInput>

                                <FormInput>
                                    <StepLabel>Max Word count </StepLabel>
                                    <Slider
                                        aria-label="Temperature"
                                        valueLabelDisplay="auto"
                                        step={50}
                                        marks
                                        min={100}
                                        max={1000}
                                        value={tokenSize}
                                        onChange={(e) => setTokenSize(e.target.value)}
                                    />
                                </FormInput>
                                <ButtonContainer>
                                    <Button variant="contained" onClick={handleShowInputs}> Create caption using Inputs </Button>
                                    <Button variant="contained" color={productName ? "primary" : 'default'} type="submit">
                                        {isGeneratingAdDescription ? <CircularProgress style={{ color: 'white' }} size={20} /> : 'Generate Ad Description'}
                                    </Button>

                                </ButtonContainer>

                            </div>
                        )}
                        {adDescription &&
                            <>

                                <h2>Advertisement description {AdvProductName ? 'for ' + AdvProductName : ''} is</h2>
                                <p>{adDescription}</p>


                            </>
                        }
                    </form>
                </section>
            </DialogContent>
            <CenteredSection>
                <DialogActions>
                    {!loading && isCaptionGenerated && (
                        <>
                            <Button type="button" onClick={handleFinish}>
                                Finish
                            </Button>
                        </>
                    )}
                    {loading && <CircularProgress size={20} />}
                </DialogActions>
            </CenteredSection>

        </Wrapper>
    );
};

CaptionFile.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

CaptionFile.defaultProps = {
    isOpen: false,
    onClose: () => { },
};

export default CaptionFile;
