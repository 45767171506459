import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  /* background-color: #541516; */
  background-color: white;
  overflow: hidden;
  padding: 38px 70px;
  @media only screen and (max-width:768px){
    overflow: auto;
  }
`;

export const Title = styled.div`
  display:flex;
  justify-content: space-between;
`

export const Logo = styled.h1`
  background-image:url("/images/logo.svg");
  background-repeat: no-repeat;
  width: 208px;
  height: 90px;
  background-size: 100% 100%;
  font-size: 0;
  text-indent: -9999;
  overflow: hidden;
  margin-bottom: 38px;
  @media only screen and (max-width:415px){
    width: 104px;
    height: 45px;
    margin-bottom: 15px;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  font-family: "AppleGothic";
  font-size: 17px;
  font-weight: 700;
  color: #000000;
  display: inline-block;
  padding: 0 8px;
  @media only screen and (max-width:768px){
    font-size: 14px;
    font-weight:600;
  }
  @media only screen and (max-width:375px){
    font-size: 10px;
    font-weight: 600;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

`

export const Intro = styled.div`
  width: 100%;
  min-height: 500px;
`

export const Pic = styled.div`
  width: 100%;
  max-width: 700px;
  max-height: 500px;
  margin-bottom: 10px
  padding-bottom: 10px;
`

export const Image1 = styled.img.attrs({
  src: '/images/login/Group0.svg'
})`
  width: 100%;
  max-height: 500px;
  object-fit: cover
`

export const Image2 = styled.img.attrs({
  src: '/images/login/Group1.svg'
})`
  width: 100%;
  max-height: 500px;
  object-fit: cover;
`

export const Image3 = styled.img.attrs({
  src: '/images/login/Group2.svg'
})`
  width: 100%;
  max-height: 500px;
  object-fit: cover;
`

export const Paginator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  max-width: 700px;
  max-height: 20px;
  margin-top: 20px;
  position: relative;
  @media only screen and (max-width:768px){
    margin-top: -10px;
    max-height: 10px;
  }
`

export const Prev = styled.a`
  width: 20%;
  min-width: 117px;
  max-height: 20px;
  position: absolute;
  margin-left: 20%;
  cursor: pointer;
  margin-bottom:auto;
  @media only screen and (max-width:415px){
    min-width: 50px;
  }
`

export const PrevIcon = styled.img.attrs({
  src: "/images/login/PREVIOUS.png"
})`
  height: 100%;
  @media only screen and (max-width:415px){
    height: 8px;
  }
`

export const Page = styled.div`
  margin: auto;
  width: 20%;
  min-width: 100px;
  max-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: absolute;
  margin-left: 40%;
  @media only screen and (max-width:975px){
    margin-left:         calc(20% + 117px);
  }
  @media only screen and (max-width:415px){
    margin-left: calc(20% + 50px);
    min-width: 20%
  }
`

export const Selected = styled.img.attrs({
  src: "/images/login/Ellipse_r.png"
})`
  width: 8%;
  margin: auto;
  display: block;
`
export const Unselected = styled.img.attrs({
  src: "/images/login/Ellipse_g.png"
})`
  width: 8%
  margin: auto;
  display: block;

`

export const Next = styled.a`
  width: 20%;
  mix-width: 117px;
  max-height: 20px;
  position: absolute;
  margin-left: 60%;
  cursor: pointer;
  margin-bottom:auto;
  @media only screen and (max-width:975px){
    margin-left:  calc(66%);
  }
  @media only screen and (max-width:415px){
    margin-left:  calc(40% + 50px);
  }
`

export const NextIcon = styled.img.attrs({
  src: "/images/login/NEXT.png"
})`
  height: 20px;
  @media only screen and (min-width:975px){
    margin-left:  calc(100% - 117px);
  }
  @media only screen and (max-width:415px){
    height: 8px;
  }
`

export const LoginList = styled.div`
  display: flex;
  flex-direction:column;
  width:30%;
  height:auto;
  justify-content: start
  margin-bottom: 50px;
`

export const Slogan = styled.div`
  width: 302px;
  min-height: 90px;
  display: block;
  margin-bottom:  50px;
  @media only screen and (max-width:768px){
    width: 200px;
    margin-bottom:  0;
  }
`

export const SloganIcon = styled.img.attrs({
  src: "/images/login/slogan.svg"
})`
  width: 100%
`

export const Signup = styled.div`
  width: auto;
  height: auto;
  min-width: 250px;
  min-height: 250px;
  @media only screen and (max-width:768px){
    margin-top: 20px;
  }
`

export const Sign = styled.h3`
  min-width: 250px
`
