import axios from 'axios';
const { Configuration, OpenAIApi } = require("openai");


const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY
const configuration = new Configuration({
    apiKey: OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);

const getImageFromTextUsingDS = async (textPrompt) => {
    try {
        const response = await axios.post(process.env.REACT_APP_DREAM_STUDIO_URL, {
            text_prompts: [
                {
                    text: textPrompt,
                    weight: 1,
                },
            ],
        }, {
            headers: {
                'content-type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_DREAM_STUDIO_KEY}`,
            },
        });

        const base64Str = response.data.artifacts[0].base64;
        const imageSource = `data:image/png;base64,${base64Str}`;

        return imageSource;
    } catch (error) {
        console.error(error);
    }
};

const getImageFromTextUsingOpenAI = async (textPrompt) => {
    try{
        let response = await openai.createImage({
            prompt: textPrompt,
            n:1,
            size : "512x512",
            response_format : 'b64_json'
        });
        let base64Str = response.data.data[0].b64_json;
        const imageSource = `data:image/png;base64,${base64Str}`;
        return imageSource;
    }catch(error){
        console.error('Error while generating image : ',error);
    }
    
}


export default getImageFromTextUsingOpenAI; // replace with getImageFromTextUsingDS to use dream studio
