import styled from 'styled-components';
import { Link } from 'react-router-dom';

// export const Wrapper = styled.div`
//   width: 100vw;
//   height: 100vh;
//   background-color: #541516;
//   background-color: white;
//   background-size: cover;
//   background-position: center;
//   overflow: hidden;
//   padding: 38px 70px;
//   @media only screen and (max-width:768px){
//     overflow: auto;
//   }
// `;

export const Wrapper = styled.div`
  @media only screen and (min-width: 768px) {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }
`;
export const Flex = styled.div`
  display:flex,

`;

export const Main = styled.main`
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  padding-top: 120px;

  @media only screen and (min-width: 768px) {
    padding: 0 20px 20px 40px;
  }
`;


export const Title = styled.div`
  display:flex;
  justify-content: space-between;
`

export const Logo = styled.h1`
  background-image:url("/images/logo.svg");
  background-repeat: no-repeat;
  width: 208px;
  height: 90px;
  background-size: 100% 100%;
  font-size: 0;
  text-indent: -9999;
  overflow: hidden;
  margin-bottom: 38px;
  @media only screen and (max-width:415px){
    width: 104px;
    height: 45px;
    margin-bottom: 15px;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  font-family: "AppleGothic";
  font-size: 17px;
  font-weight: 700;
  color: #000000;
  display: inline-block;
  padding: 0 8px;
  @media only screen and (max-width:768px){
    font-size: 14px;
    font-weight:600;
  }
  @media only screen and (max-width:375px){
    font-size: 10px;
    font-weight: 600;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:flex-start;
`

export const PayPal = styled.div`
  height: 100%;
  display: flex;
    margin: 10px;
  flex-direction: column;
  justify-content: space-between;
`

export const Tiers = styled.div`
  display: flex;
  flex-direction: row;
  // @media (max-width: 768px)
  // {
  //   flex-wrap: wrap;

  // }
`
export const CheckoutCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  // @media (max-width: 768px)
  // {
  //   flex-direction: column;
  // }
`

export const Tier = styled.div`
  background-color: white;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  box-shadow: 4px 4px 2px rgba(136, 136, 136, 0.2),-4px 4px 2px rgba(136, 136, 136, 0.2);
  `

export const SelectedTier = styled.div`
  background-color: white;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  box-shadow: 8px 8px 2px rgba(140, 140, 140),-1px 4px 2px rgba(140, 140, 140);
  `

export const TierTitle = styled.div`
  background-color: #9D0000;
  color: white;
  font-weight: 700;
  padding-left: 5px;
  font-size: 30px;
  border-radius: 25px 25px 0 0;
  display: flex;
  flex-direction: row;
  padding:20px;
  justify-content:space-between

`

export const TierPrice = styled.div`
  background-color: #9D0000;
  color: white;
  font-weight: 900;
  padding-left: 5px;
  font-size: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
    div{
      font-size:20px
    }
`
export const TierFeatures = styled.ul`
  background-color: white;
  display: flex;
  align-items:flex-start;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  list-style: none;
`

export const TierFeature = styled.li`
  margin: 3px;
  padding-left: 5px;
  font-weight: 700;
  font-size: 15px;
  margin: 10px;
`


export const PayButton = styled.button`
  background-color: #980000;
  border: 0;
  color: #ffffff;
  border-radius: 31px;
  font-family: "AppleGothic";
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;
  padding: 7px 20px 3px;
  margin: 10px auto
`;

export const SoonButton = styled.button`
  background-color: rgba(145,145,145, 0.5);
  border: 0;
  color: #ffffff;
  border-radius: 31px;
  font-family: "AppleGothic";
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  padding: 7px 20px 3px;
  margin: 10px auto
`;

