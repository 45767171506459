import styled from "styled-components";
import { TextField } from "@material-ui/core";

export const Wrapper = styled.div`
  h2.MuiTypography-h6 {
    font-family: "AppleGothic";
    color: #9b9b9b;
    text-align: center;
    font-size: 36px;
    border-bottom: 2px solid #9b9b9b;
  }
`;

export const Button = styled.button`
  background-color: #980000;
  border: 0;
  color: #ffffff;
  border-radius: 31px;
  font-family: "AppleGothic";
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  padding: 7px 20px 3px;
`;

export const TextAreaContainer = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const AddFile = styled.div`
  // width: 340px;
  height: 305px;
  padding: 40px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  color: #bebebe;
  font-family: "AppleGothic";
  text-align: center;
  cursor: pointer;
`;

export const AddFileIcon = styled.span`
  display: inline-block;
  height: 67px;
  width: 67px;
  border-radius: 34px;
  background-color: #980000;
  color: #fff;
  font-size: 58px;
  line-height: 76px;
`;

export const AddFileMain = styled.p`
  font-size: 24px;
  margin: 45px 0 15px;
  line-height: 31px;

  & .action {
    color: #980000;
  }
`;

export const AddFileSecondary = styled.p`
  font-size: 14px;
  line-height: 17px;
`;

export const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
`;

export const Thumb = styled.div`
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  width: 208px;
  height: 208px;
  padding: 4px;
`;

export const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;

  img {
    display: block;
    width: auto;
    height: 100%;
  }
`;

export const Description = styled.div`
  margin-bottom: 20px;
`;

export const Dropdown = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  & select {
    min-width: 200px;
  }
`;

export const FormInput = styled.div`
  margin: 10px 0 20px;

  fieldset > legend {
    padding: 0 4px;
  }

  label {
    font-family: "AppleGothic";
    font-weight: 400;
    display: block;
    padding-bottom: 5px;
  }

`;

export const ProfileImageInput = styled.div`
  position: relative;
  overflow: hidden;
  width: 145px;
  height: 145px;
  border: solid 2px #eaeaea;
  border-radius: 50%;
  margin: 0 auto 35px;
  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    fill: #eaeaea;
    transform: translate(-50%, -50%);
    opacity: 0.8;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ProfileImageCropper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 35px;

  .ReactCrop {
    max-width: 300px;
    border: solid 2px #eaeaea;
  }
`;
export const Tags = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -3px;
  padding: 0;
`;

export const Tag = styled.li`
  display: flex;
  align-items: center;
  background-color: #f2cbcb;
  margin: 3px;
  padding: ${(props) => (props.addtag ? "2px" : "2px 2px 2px 10px")};
  border-radius: 15px;
  font-size: 13px;
  font-weight: bold;
`;

export const DeleteFavoriteTag = styled.a`
  width: 15px;
  height: 15px;
  margin-left: 8px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    fill: #980000;
  }
`;

export const AddFavoriteTag = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    fill: #980000;
  }
`;

export const ProductLineSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const CenteredSection = styled.div`
  display: flex;
  justify-content: center;
`;

export const AddNewTagArea = styled.div`
  display: flex;
`;

export const TagsArea = styled.div`
  display: flex;
  margin: 10px;
`;

export const Section = styled.div`
  box-sizing: border-box;
  padding: 20px;
`;
export const LeftDiv = styled(Section)`
  width: 50%;
  border-right: 1px solid #000;
  @media (max-width: 768px)
  {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #000;
  }
`;
export const RightDiv = styled(Section)`
  width: 50%;
  @media (max-width: 768px)
  {
    width: 100%;
  }
`;
export const DialogContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const DialogActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;