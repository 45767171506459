import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Redirect } from 'react-router-dom';
import { Wrapper, Logo, Title, Navigation, NavLink, List, Body, Intro,Paginator,Prev,PrevIcon,Page,Next,NextIcon,LoginList,Slogan,SloganIcon,Sign,Signup,Selected, Unselected, Pic,Image1,Image2,Image3} from './styles';
import UserContext from '../../config/UserContext';
import NavBar from '../../components/NavBar';

const useWindowSize = () => {
  const [windowWidth, setWidth] = React.useState(0);
  React.useLayoutEffect(()=>{
    const updateSize = () => {
      setWidth([window.innerWidth]);
    }
    window.addEventListener('resize',updateSize);
    updateSize();
    return ()=>  window.removeEventListener('resize',updateSize)
  },[]);
  return [windowWidth] ;
}

const Login = () => {
  const { firebase, user } = React.useContext(UserContext);
  //count 0-2 to display different slide
  const [count, setCount] = React.useState(0);
  const [windowWidth] = useWindowSize();

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful.
    // Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: '/signedIn',
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => {}
    },
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ]
  };


  if (user) {
    return <Redirect to="/" />;
  }

  if(windowWidth &&  windowWidth > 768){
    return (
      <Wrapper>
        <NavBar />
        <Body>
          <Intro>
            <Pic>
            {count===0 ? <Image1/> : (count===1 ? <Image2/> : (count===2 && <Image3/>) )}
            </Pic>
            <Paginator>
              {count>0 && <Prev onClick={()=>setCount(count-1)}> <PrevIcon/> </Prev> }
              <Page>
                  {count===0 ? <Selected /> : <Unselected/>}
                  {count===1 ? <Selected /> : <Unselected/>}
                  {count===2 ? <Selected /> : <Unselected/>}
              </Page>
              {count<2 && <Next onClick={()=>setCount(count+1)}> <NextIcon/> </Next>}
            </Paginator>
          </Intro>
          <LoginList>
            <Slogan><SloganIcon/></Slogan>
            <Sign>Signup / Login</Sign>
            <Signup>
              { user === null && <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} /> }
            </Signup>
          </LoginList>
        </Body>
      </Wrapper>
    )
  } else  {
    return (
      <Wrapper>
        <Title className='title'>
          <Logo>AIM-HI - Artificial Intelligence Marketing</Logo>
        </Title>
        <Body>
          <Intro>
            <Slogan><SloganIcon/></Slogan>
            <Pic>
            {count===0 ? <Image1/> : (count===1 ? <Image2/> : (count===2 && <Image3/>) )}
            </Pic>
            <Paginator>
              {count>0 && <Prev onClick={()=>setCount(count-1)}> <PrevIcon/> </Prev> }
              <Page>
                  {count===0 ? <Selected /> : <Unselected/>}
                  {count===1 ? <Selected /> : <Unselected/>}
                  {count===2 ? <Selected /> : <Unselected/>}
              </Page>
              {count<2 && <Next onClick={()=>setCount(count+1)}> <NextIcon/> </Next>}
            </Paginator>
            <LoginList>
              <Signup>
                <Sign>Signup / Login</Sign>
                { user === null && <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} /> }
              </Signup>
            </LoginList>
          </Intro>

        </Body>
        <Navigation>
            <List>
              <NavLink to="/">Home</NavLink>
              <NavLink to="/contact">Contact</NavLink>
              <NavLink to="/privacy">Privacy</NavLink>
              <NavLink to="/about">About</NavLink>
            </List>
          </Navigation>
      </Wrapper>
    );
  }

};

export default Login;
