import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import ReactSVG from 'react-svg';
import UserContext from '../../config/UserContext';
import { Item, Content, ImageWrapper, DeletePost, RecyclePost, Image, Body, Tags, Tag, DeleteTag } from './styles';


import AddTag from './AddTag';
import Share from '../Share';
import { updatePost, removeTag, deletePost, recyclePost, addTag } from '../../utils/posts';

const Post = ({ post, businessName, businessCategory, addAimHiTag }) => {
  const [body, setBody] = React.useState(post.data.body);
  const [showing, setShowing] = React.useState(false);
  const { firebase, profile } = React.useContext(UserContext);
  const db = firebase.firestore();


  const onDeletePost = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Do you want to delete this post permanently?')) {
      deletePost(post.id);
    }
    // if profile imgUploadTokens === 0 => warning you have no more uploads this month 
  }

  const onRecylePost = () => {
    console.debug("POST: ", post);

    if (post.data.postRecycleTokens > 0) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(`Do you want to generate new content for this post? You will lose your current contents. You may generate new content ${post.data.postRecycleTokens} more time(s).`)) {
        recyclePost(post, businessName, businessCategory);
      }
      let decrementedTokens = post.data.postRecycleTokens - 1;
      db.collection("posts").doc(post.id).update({
        postRecycleTokens: decrementedTokens
      })
    }
    else {
      alert("You ran out of re-generations for this post.");
    }
  }

  React.useEffect(() => {
    setBody(post.data.body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  React.useEffect(() => {
    if (post.data.image) {
      setShowing(true);
    }
  }, [post.data.image])

  React.useEffect(() => {
    if (addAimHiTag || profile.accessLevel < 500) {
      if (!post.data.tags.includes("AimHiGlobal")) {
        addTag(post, "AimHiGlobal")
      }
    }
  }, [])

  return (
    <Item showing={showing}>
      <ImageWrapper>
        <DeletePost onClick={onDeletePost}>
          <ReactSVG src="/images/icons/trash.svg" />
        </DeletePost>
        {
          post.data.labels &&
          <RecyclePost onClick={onRecylePost}>
            <ReactSVG src="/images/icons/recycle.svg" />
          </RecyclePost>
        }
        <Image src={post.data.image} alt={post.data.title} />
      </ImageWrapper>
      <Content>
        <Body>
          <TextareaAutosize onChange={e => setBody(e.target.value)} onBlur={() => updatePost(post.id, body)} value={body} />
        </Body>
        <Tags>
          {post.data.tags.map((tag, i) => {
            // the AimHiGlobal tag should not be removable for users with access level < 500
            // possible bug to test: profile var may not update when changing access level
            if (tag === "AimHiGlobal" && profile.accessLevel < 500) {
              return <Tag key={i}>
                @{tag}
                <DeleteTag onClick={() => { }}><ReactSVG src="/images/icons/cancel-gray.svg" /></DeleteTag>
              </Tag>
            }
            else if (tag === "AimHiGlobal") {
              return (
                <Tag key={i}>
                  @{tag}
                  <DeleteTag onClick={() => removeTag(post, i)}><ReactSVG src="/images/icons/cancel.svg" /></DeleteTag>
                </Tag>
              )
            }
            else {
              return (
                <Tag key={i}>
                  #{tag}
                  <DeleteTag onClick={() => removeTag(post, i)}><ReactSVG src="/images/icons/cancel.svg" /></DeleteTag>
                </Tag>
              )
            }
          })}
          <AddTag post={post} />
        </Tags>
      </Content>
      <Share post={post} />

    </Item>
  );
};

Post.propTypes = {
  post: PropTypes.object.isRequired
};

export default Post;
