/* Why purposely obfuscate the names???
I've seen these components imported twice into the same file, as both this index.js file and as the files themselves.
-Kevin
*/
import GenerateAd from './GenerateAd';
import CaptionFile from './CaptionGenerate';
import SelectFbPage from './SelectFbPage';
import AccountInfo from './AccountInfo';
import PaymentInfo from './PaymentInfo';
import InstagramPop from './instagramPop'


export const GenerateAdModelDialog = GenerateAd;
export const CaptionModelDialog = CaptionFile;
export const SelectPageDialog = SelectFbPage;
export const AccountInfoDialog = AccountInfo;
export const PaymentInfoDialog = PaymentInfo;
export const InstagramPopDialog = InstagramPop;
