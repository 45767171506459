import React from 'react';
import firebase from 'firebase';
import Recaptcha from 'react-recaptcha';
// import { Link } from 'react-router-dom';
import { Background, Navigation, List, NavLink, Logo, Title, MainBody, Picture, FormArea, Form, TopForm, BottomForm,  Submit, Footer } from './styles';
import { TextField } from '@material-ui/core';
import {findBrowser, findDevice} from '../../utils/user';
import NavBar from '../../components/NavBar';

class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contactName: '',
      contactEmail: '',
      contactMessage: '',
      respondedToYet: false,
      isInputValid: null,
      recaptchaVerified: null,
      windowWidth: window.innerWidth,
      ub: "",
      ud: ""
    }

    this.updateState = this.updateState.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.addMsg = this.addMsg.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.verifyRecaptcha = this.verifyRecaptcha.bind(this);
  }

  // UPDATE STATE FUNCTION

  updateState = () => {
    this.setState({
        contactName: document.getElementById("contactName").value,
        contactEmail: document.getElementById("contactEmail").value,
        contactMessage: document.getElementById("contactMessage").value
    });
  }

  reportWindowSize = () => {
    this.setState({
      windowWidth: window.innerWidth
    })
    return window.innerWidth;
  }

  componentDidMount() {
    window.addEventListener('resize', this.reportWindowSize.bind(this));
    let ub1 = findBrowser();
    let ud1 = findDevice();
    this.setState({
      ub: ub1,
      ud: ud1
    })
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.reportWindowSize.bind(this));
  }

  onWindowResize = () => {
    this.setState({windowWidth: window.innerWidth});
  }

  // VALIDATE INPUT FUNCTION

  validateInput = () => {
    if (!this.state.contactName || !this.state.contactEmail || !this.state.contactMessage) {
      this.setState({isInputValid: false});
      alert("Please fill out the whole form.");
      return -1;
    }
    else {
      this.setState({isInputValid: true});
      return true;
    }
  }

  // ADD MESSAGE TO DB
  // include email notification to company contact email

  addMsg = () => {
    let time = String(Date.now());

    if (!this.state.recaptchaVerified) {
      alert("Need to verify recaptcha.");
      return -1;
    }

    let msg = {
      contactName: this.state.contactName,
      contactEmail: this.state.contactEmail,
      contactMessage: this.state.contactMessage,
      respondedToYet: false
    }

    const db = firebase.firestore();

    // let time = String(Date.now());
    // let setDoc = db.collection("contactMessages").doc(time).set(data);
    // db.collection('contactMessages').doc(time).set({

    db.collection('contactMessages').add({
      contactName: this.state.contactName,
      contactEmail: this.state.contactEmail,
      contactMessage: this.state.contactMessage,
      respondedToYet: false
    })
    .then(()=>{})
    .catch(function(err) {
      console.error("Error submit contact form ", err);
    })

    alert("Messaged receieved. We will contact you via email.");

    let docForm1 = document.getElementById('contactName');
    let docForm2 = document.getElementById('contactEmail');
    let docForm3 = document.getElementById('contactMessage');
    docForm1.value="";
    docForm2.value="";
    docForm3.value="";
  };


  handleClick = (e) => {
    e.preventDefault();

    new Promise ( (res, rej) => {
      this.updateState();
      res();
    })
    .then( (res, rej) => {
      this.validateInput();
    })
    .then( (res, rej) => {
      if (!this.state.isInputValid) {
        throw new Error('invalid input inside form');
      }
    })
    .then( (res, rej) => {
      this.addMsg();
    })
    .catch( (err) => {
      console.error("Error occured updating state: ", err);
    })
    return 1;
  }

  // Recaptcha attribute functions

  recaptchaLoaded = () => {
    console.log("recaptchaLoaded")
  }

  verifyRecaptcha = (res) => {
    if (res) {
      this.setState({
        recaptchaVerified: true
      })
    }
  }


  render() {
    return (

      <Background>
        <NavBar />

        <MainBody>
          {(this.state.ud !== "mobile") && (this.state.windowWidth > 740) &&
            <Picture className="contactPagePic1">
              <img src="/images/contactPagePic1.png" alt="img break" height="300" width="300" />
            </Picture>}

          <FormArea>
          {( (this.state.ud !== "mobile") && (this.state.windowWidth > 740) ) ?
            <Title>
              <text style={{"font-size": "72px"}}>Get In Touch</text>
              {/* <img src="/images/getInTouch.png" alt="img break" height="90" width="360"/>  */}
            </Title> :
            <Title>
              <text>Get In Touch</text>
              {/* <img src="/images/getInTouch.png" alt="img break" height="90" width="360" />  */}
            </Title>
          }

          <Form>
            <TopForm>
              <TextField label="Name" variant="filled" fullWidth style={{"padding": "8px 7px 8px"}} width="100%"
                type="text"
                id="contactName"
                placeholder="name"
                size="49"
                required
              >
              </TextField>
              <br></br>
              <TextField label="Email" variant="filled" fullWidth style={{"padding": "5px 7px 2px"}}
                type="email"
                id="contactEmail"
                placeholder="email"
                size="49"
                required
              >
              </TextField>
              <br></br>
              <br></br>
              <TextField label="Message" variant="outlined" multiline fullWidth style={{"padding": "2px 7px 2px"}}
                type="text"
                id="contactMessage"
                placeholder="message"
                size="49"
                maxLength="450"
                rows={4}
              >
              </TextField>
              <br></br>
            </TopForm>
            <BottomForm>
              <Recaptcha
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  //sitekey="6LfxP_wUAAAAAID_rIHF92edszm9J0-zqyAiKdBu"
                  render="explicit"
                  verifyCallback={this.verifyRecaptcha}
                  onloadCallback={this.recaptchaLoaded}
              />
              <Submit>
                  <button
                    type="submit"
                    value="Submit"
                    onClick={(e) => {this.handleClick(e)}}
                  >
                  Submit Message
                  </button>
              </Submit>
            </BottomForm>
          </Form>
          </FormArea>
        </MainBody>

        <br></br>

        <Footer>
          {/* <FooterLink onClick={() => this.props.history.push('/login')}>
            Back
          </FooterLink> */}
          Copyright &copy; Aim-Hi 2020
        </Footer>

      </Background>

      )
  }
}


export default Contact;
