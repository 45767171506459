import React, { useEffect, useState } from 'react';
import UserContext from '../../config/UserContext';
import { Link } from 'react-router-dom';
import { linkProvider, facebookPublishMedia, hasProvider } from '../../utils/user';
import { Background, Wrapper, TopBar, Logo, Inner, Title, TwoByOne, Navigation, NavLink, List, Footer, FooterLink } from './styles';

import {
  getFbPages,
  getFbFeed,
  getPageAccessToken,
  getFbLikes
} from '../../utils/stats_utils/apiFunctions';

import {
  AreaChart,
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis
} from 'recharts';
import ChartMockup from '../StatsDataCharts/StatsDataCharts';
import { resolve } from 'path';

const Statistics = ({ history }) => {

  const { user, firebase, setLoading } = React.useContext(UserContext);
  const [hookAccessToken, sethookAccessToken] = React.useState('');
  const [aimHiFbPosts, setAimHiFbPosts] = React.useState([]);
  const [fbPages, setFbPages] = React.useState([]);
  const [fbFeed, setFbFeed] = React.useState([]);
  const [fbPostLikes, setFbPostLikes] = React.useState([]);
  const [hookAllPositivePostLikes, setHookAllPositivePostLikes] = React.useState({});
  const [chartState, setChartState] = React.useState(null);
  const [instagramPages, setInstagramPages] = React.useState([]);
  const [displayFbPagesDialog, setDisplayFbPagesDialog] = React.useState(false);
  const [displayInstagramPagesDialog, setDisplayInstagramPagesDialog] = React.useState(false);


  const [someHardData, setSomeHardData] = useState(undefined);

  const [dbUser, setDbUser] = React.useState([]);
  const [uniquePostArray, setUniquePosts] = React.useState([]);
  // const[fbAimHiPostIds, setFbAimHiPostIds] = React.useState([]); 

  const db = firebase.firestore();

  let accessToken = "";
  let pageAccessTokens = [];
  let fbGlobalPages = [];
  let fbAllGlobalFeed = [];


  let fbAimHiPostIds = [];
  let aimHiPostLikes = [];

  let fbPagesArray = [];

  //================================================

  window.FB.getLoginStatus(function (response) {
    return new Promise((res, rej) => {
      if (response.status === 'connected') {
        // The user is logged in and has authenticated your
        // app, and response.authResponse supplies
        // the user's ID, a valid access token, a signed
        // request, and the time the access token 
        // and signed request each expire.
        var uid = response.authResponse.userID;
        var accessToken = response.authResponse.accessToken;
        res(accessToken)
      } else if (response.status === 'not_authorized') {
        // The user hasn't authorized your application.  They
        // must click the Login button, or you must call FB.login
        // in response to a user gesture, to launch a login dialog.
      }
      else {
        // The user isn't logged in to Facebook. You can launch a
        // login dialog with a user gesture, but the user may have
        // to log in to Facebook before authorizing your application.
      }
    });
  })

  function scrubPageIds(unscrubbedPageId) {
    let postId = unscrubbedPageId.split("_")[1];
    return postId;
  }

  function scrubSecondValue(unscrubbedSecondValue) {
    let secondValue = unscrubbedSecondValue.split("_")[0];
    return secondValue;
  }

  //===============================
  //---------------1---------------
  //===============================
  const getFBAccessToken = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('pages_manage_posts');
    provider.addScope('pages_read_engagement');
    provider.addScope('pages_show_list');
    // provider.addScope('pages_read_user_content');

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      window.FB.getLoginStatus(async response => {
        if (response.status === 'connected') {
          window.sessionStorage.setItem('fbAccessToken', response.authResponse.accessToken);
          accessToken = response.authResponse.accessToken;
          sethookAccessToken(accessToken);
          resolve(response.authResponse.accessToken);
        } else {
          try {
            if (!hasProvider(user, 'facebook.com')) {
              const accessToken = await linkProvider(provider);
              resolve(accessToken);
            } else {
              const result = await firebase.auth().signInWithPopup(provider);
              resolve(result.credential.accessToken);
            }
          } catch (err) {
            return reject(err);
          }
        }
      });
    });
  };

  //===============================
  //---------------3---------------
  //===============================
  let loopToGetFbFeed = async (pagesArr) => {
    let fbFeedArray = [];
    return new Promise(async (resolve, reject) => {
      try {
        for (let i = 0; i < pagesArr.length; i++) {
          let feedPromiseResult = await getFbFeed(pagesArr[i].id);
          console.log("getFbFeed >>> [", i, "] : ", feedPromiseResult);
          fbFeedArray.push(feedPromiseResult);
        }
        resolve(fbFeedArray);
      }
      catch (err) {
        console.error("getFbFeed: ", err);
        reject();
      }
    })
  }

  //===============================
  //---------------4---------------
  //===============================
  let getEachLikesCount = async (feedArrParams) => {
    let allPositivePostLikes = [];

    return new Promise(async (resolve, reject) => {
      try {
        // ----------- PAGES ------------
        for (let i = 0; i < feedArrParams.length; i++) {

          // ----------- POSTS ------------
          for (let j = 0; j < feedArrParams[i].data.length; j++) {

            let getPostLikes = await getFbLikes(feedArrParams[i].data[j].id)

            if (getPostLikes.summary === undefined) return null;
            // console.log("getPostLikes Params: ",scrubSecondValue(fbFeedArray[i].data[j].id), " +++ ",scrubPageIds(fbFeedArray[i].data[j].id))

            // let tryPostLikes = () => {
            //   if(getPostLikes!==undefined){
            //     return true;
            //   } else {
            //     return false;
            //   }
            // }
            // try {
            //   tryPostLikes(); 
            // }
            // catch (error) {
            //   console.error("feedArrParams ERROR: ", error); 
            //   continue;
            // }
            // finally {
            //   //
            // }

            let post_id = scrubPageIds(feedArrParams[i].data[j].id);
            let total_count = getPostLikes.summary.total_count;

            console.log("[i][j]: [", i, "] [", j, "] : ", getPostLikes);

            if (total_count > 0) {
              console.log("POSITIVE VVV");

              allPositivePostLikes.push({
                "POST_ID": `${post_id}`,
                "TOTAL_LIKES": total_count
              })
            }
          }
        }
        resolve(allPositivePostLikes);
      }
      catch (err) {
        console.error("getEachLikesCount ERROR: ");
        reject(err);
      }
    })
  }


  // ----- Date Creator
  let todaysDate = new Date;
  // console.log("seconds since 1970/1/1", todaysDate);
  let yesterday = (new Date(new Date().setDate(new Date().getDate() - 1)))
  let twoDaysAgo = (new Date(new Date().setDate(new Date().getDate() - 2)))
  // console.log("yesterday: ", yesterday)
  todaysDate = Math.floor(todaysDate.getTime() / 1000);
  yesterday = Math.floor(yesterday.getTime() / 1000);
  twoDaysAgo = Math.floor(twoDaysAgo.getTime() / 1000);
  // console.log("todaysDate: ", todaysDate)


  //=======================================
  //---------------useEffect--------------- 
  //=======================================
  useEffect(() => {
    // setLoading causes the loading circle in App.js to appear
    setLoading(true);

    let getAimHiFbPostLikes = async () => {
      return new Promise(async (resolve, reject) => {
        let token = await getFBAccessToken();
        // resolve(token)
        resolve(getFBAccessToken());
      }).then(async (token) => {
        let uniqueAimHiFbPosts = await getAimHiFbPosts(user.uid);
        console.log("GET AIM HI FB POSTS >>> ", uniqueAimHiFbPosts);
        let result = {
          uniqueAimHiFbPosts: uniqueAimHiFbPosts,
          token: token
        }
        return (result);
      }).then(async (result) => {
        let aimHiFbLikesObj = {}
        for (let i = 0; i < result.uniqueAimHiFbPosts.length; i++) {
          let likesPromiseResult = await getFbLikes(result.uniqueAimHiFbPosts[i][0], twoDaysAgo, yesterday, result.token);
          aimHiFbLikesObj[result.uniqueAimHiFbPosts[i][0]] = likesPromiseResult.summary.total_count;
        }
        console.log("aimHiFbLikesObj >>> ", aimHiFbLikesObj)
        setFbPostLikes(aimHiFbLikesObj);
        return (aimHiFbLikesObj);
        //========================================
        // Save this data into Post.ID in Firebase
        //========================================
      }).catch((error) => {
        console.error("Failure getting Aim Hi Fb Posts: ", error);
      })
    }


    //----------------------------Get All Fb Post Likes Data----------------------------
    let getAllFbPostLikes = async () => {
      return new Promise(async (resolve, reject) => {
        //=========
        //----1----
        //=========
        const accessToken = await getFBAccessToken();
        sethookAccessToken(accessToken);
        console.log("HOOK accessToken SUCCESS: ", hookAccessToken);
        resolve(hookAccessToken);
      }).then(async (token) => {
        return new Promise(async (resolve, reject) => {
          //=========
          //----2----
          //=========
          let pagesPromiseResult = await getFbPages(token);
          console.log('getFbPages >>> ', pagesPromiseResult);
          setFbPages(pagesPromiseResult);
          resolve(pagesPromiseResult);
        }).then(async (pages) => {
          return new Promise(async (resolve, reject) => {
            //=========
            //----3----
            //=========
            let feedPromiseResult = await loopToGetFbFeed(pages)
            console.log("loopToGetFbFeed >>> ", feedPromiseResult);
            setFbFeed(feedPromiseResult);
            resolve(feedPromiseResult);
          }).then(async (feed) => {
            console.log("Getting values ???? ", feed);
            //=========
            //----4----
            //=========
            // loop over Aim Hi FB Posts []
            // get likes data for each Post ID 
            let likesPromiseResult = await getFbLikes("100190148346741_159379489094473");
            console.log("getEachLikesCount >>> ", likesPromiseResult.summary.total_count);
            setFbPostLikes(likesPromiseResult.summary.total_count);
            resolve(likesPromiseResult);
          }).then((likesData) => {
            console.log("Promise Chain ", likesData);
          }).catch((error) => {
            console.error("Promise Chain Error");
          })
        })
      })
    }

    getAimHiFbPostLikes();
    setLoading(false);
    //=============================================
    //---------CRITICAL STEP: ACCESS STATE---------
    //=============================================

    //--------- Hard Code Test --------------
    // let getLikesForPost = getFbLikes("177668063932282", "177668063932282");
    // console.log("GET HAROLDS LIKES: ", getLikesForPost); 
    // getLikesForPost.then((value)=>{
    //   setSomeHardData(value); 
    //   console.log(value);
    //   console.log("GET ASYNC TEST VALUE: ", someHardData);
    // }); 
    //--------------------------------------
    // return ()=>{
    //   bluebird npm package needed
    //   userPages.cancel(); 
    // }

  }, [user, hookAccessToken]);

  //======================================
  //----------------Regular Push Into Array------------------
  async function fbPagesFeedAPI() {
    for (let i = 0; i < fbGlobalPages.length; i++) {
      let getMyPageToken = await getPageAccessToken(i, accessToken);
      pageAccessTokens.push(getMyPageToken);
    }
    let getMyPages = await getFbPages(accessToken);
    fbGlobalPages = getMyPages;
    for (let i = 0; i < fbGlobalPages.length; i++) {
      let fbPageIndex = await getFbFeed(fbGlobalPages[i].id);
      fbAllGlobalFeed.push(fbPageIndex);
    }
    return 1;
  }
  //-------------------------------------------------------
  // setLoading(true); 
  // fbPagesFeedAPI(); 
  // setLoading(false); 

  async function getUniques() {
    console.log("MY POSTS: ", fbAimHiPostIds);
    console.log("MY POSTS . length: ", fbAimHiPostIds.length);
    console.log("MY POSTS [0]: ", fbAimHiPostIds[0]);
    console.log("MY POSTS [1]: ", fbAimHiPostIds[1]);
    var likes = await getLikesData();

    console.log("LIKES: ", likes)
    aimHiPostLikes = likes;
    console.log("AIM HI POST LIKES: ", aimHiPostLikes);
    console.log("AIM HI POST KEYS 0: ", (aimHiPostLikes[0]));
    console.log("AIM HI POST VALS 1: ", aimHiPostLikes);
    return likes;
  }
  //  getUniques(); 


  //======================================



  function getAimHiFbPosts(userID) {
    let aimHiPosts = [];
    return new Promise((resolve, reject) => {
      db.collection('posts').where('uid', '==', userID)
        .get().then(function (querySnapshot) {
          if (querySnapshot.size > 0) {
            for (let i = 0; i < querySnapshot.size; i++) {
              console.log("SNAPSHOT ", i, querySnapshot.docs[i].data());
              if (querySnapshot.docs[i].data().fbPageIds) {
                aimHiPosts.push((querySnapshot.docs[i].data().fbPageIds));
                console.log("uniq id: ", i, querySnapshot.docs[i].data().fbPageIds);
              }
            }
            resolve(aimHiPosts);
          }
        })
        .then((aimHiPosts) => {
          setAimHiFbPosts(aimHiPosts);
        })
        .catch(function (error) {
          console.log("Error getting Aim Hi Posts: ", error);
        });
    })
  }



  async function getLikesData() {
    let likesRes = [];
    return new Promise((resolve, reject) => {
      for (let i = 0; i < fbAimHiPostIds.length; i++) {
        console.log("Current i: ", fbAimHiPostIds[i]);
        let getMyLikesData = getFbLikes(fbAimHiPostIds[i]);
        getMyLikesData.then((res) => {
          likesRes.push(fbAimHiPostIds[i] + "_" + res.summary.total_count);

          //---------------

          // db.collection('posts').where('fbPageIds', '==', fbAimHiPostIds[i])
          // .get().then(function(querySnapshot) {
          //   if (querySnapshot.size > 0) {
          //     querySnapshot.doc.update({
          //       total_likes:res.summary.total_count
          //     }) 
          //   } 
          // }).catch(e => {console.log("Likes data error: ", e)})

          //---------------

        })
      }
      console.log("Final Res: ", likesRes)
      return resolve(likesRes);
    })
  }

  //======================================




  const getFbInsights = (fbObjId, metric, time) => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        "/" + fbObjId + "/insights/" + metric + "/" + time,
        { summary: true },
        // {message: body}
        function (res) {
          if (res && !res.error) {
            console.log("Insights Metric: ", res);
            resolve(res)
          }
          else {
            console.log("Insights Error: ", res.error);
            reject(res);
          }
        }
      );
    })
  }




  // post_engaged_fan 
  // page_posts_impressions_frequency_distribution

  //======================================
  //            LIKES INSIGHTS LOCATIONS  


  // window.FB.api(
  //   "/105398904569512/insights?metric=count",
  //   { access_token: accessToken, summary: true },
  //   function (response) {
  //     if (response && !response.error) {
  //       console.log("POST INSIGHTS: ", response); 
  //     }
  //     else {
  //       console.log("POST INSIGHTS ERROR: ", response.error); 
  //     }
  //   }
  // );

  // window.FB.api(
  //   "/100190148346741/locations",
  //   {
  //       "fields": "location{latitude,longitude},is_permanently_closed",
  //       "limit": "30000"
  //   },
  //   function (response) {
  //     if (response && !response.error) {
  //       console.log("LOCATIONS: ", response); 
  //     }
  //   }
  // );

  // window.FB.api(
  //   'GET',
  //   {access_token: accessToken}, 
  //   "100190148346741/insights/_unique",
  //   function (response) {
  //     if (response && !response.error) {
  //       console.log("UNIQUE: ", response); 
  //     }
  //   }
  // );

  // window.FB.api(
  //   '/me/posts',
  //   'GET',
  //   {access_token: accessToken}, 
  //   function(response) {
  //     console.log("GET POSTS: ", response); 
  //   }
  // );






  //=============================================================================
  //                          FaceBook SIDE
  //=============================================================================

  //------------ FACEBOOK INIT & LOGINS ------------


  // window.fbAsyncInit = function() {
  //   window.FB.init({
  //     appId      : '467338260658658',
  //     cookie     : true,                // Enable cookies to allow the server to access the session.
  //     xfbml      : true,                // Parse social plugins on this webpage.
  //     version    : '7.0'                // Use this Graph API version for this call. version 7.0 is latest , or 8.0 
  //   });
  // };  

  // (function(d, s, id){
  //   var js, fjs = d.getElementsByTagName(s)[0];
  //   if (d.getElementById(id)) {return;}
  //   js = d.createElement(s); js.id = id;
  //   js.src = "//connect.facebook.net/en_US/sdk.js";
  //   fjs.parentNode.insertBefore(js, fjs);
  // }(document, 'script', 'facebook-jssdk'));

  // window.FB.login(function(response) {
  //   if (response.authResponse) {
  //    console.log('Welcome!  Fetching your information.... ');
  //    window.FB.api('/me', function(response) {
  //      console.log('FaceBook: Good to see you, ' + response.name + '.');
  //    });
  //   } else {
  //    console.log('User cancelled login or did not fully authorize.');
  //   }
  // });


  // APP ACCESS TOKEN: 467338260658658|bmlxwWYXdGcJ0eXN8EUOrXuuyvE


  //------------------------------------------------


  //================================================
  //                  SCOPE
  //================================================

  // window.FB.login(function(response) {
  //   console.log("SCOPE: "); 
  //   }, {scope: 'public_profile,email'});

  //   } 
  //   else {
  //     console.log("ERROR:::else error FB api");
  //   }

  //   const provider = new firebase.auth.FacebookAuthProvider();
  //   provider.addScope('instagram_basic');
  //   provider.addScope('pages_show_list');
  //   provider.addScope('manage_pages');
  //   provider.addScope('publish_pages');

  // window.FB.login(function(response) {
  //   console.log("SCOPE: "); 
  //   console.log(response);
  // }, {scope: 'public_profile,email'});

  //================================================



  //=============================================================================
  //                          END FaceBook SIDE
  //=============================================================================




  //=============================================================================
  //                             RE CHARTS DATA LOADER
  //=============================================================================

  // let data_input; 
  // let dataLoader = async () =>{
  //   data_input = await getFbLikes("177668063932282", "177668063932282");
  // } 
  // dataLoader(); 
  // console.log("data_input ", data_input); 

  //=============================================================================
  //                            END RE CHARTS DATA
  //=============================================================================

  return (
      <Background>
        <Wrapper>
          <TopBar>
            <Link to="/">
              <Logo>AIM-HI</Logo>
            </Link>
          </TopBar>
          <Inner>
            <Title>AIM HI POST STATISTICS</Title>

              POST DATA RESULT <br />
              {fbPostLikes !== undefined
                ? (fbPostLikes["100190148346741_247130790319342"])
                : "...loading"
              }
            <br />
            <ChartMockup
              days={180}
              payload={(fbPostLikes["100190148346741_247130790319342"])}
            >
            </ChartMockup>

            <ChartMockup
              days={180}
              payload={(fbPostLikes["100190148346741_247130790319342"])}
            >
            </ChartMockup>

          </Inner>
          <Footer>
            <FooterLink onClick={() => history.goBack()}>Back</FooterLink>
          </Footer>
        </Wrapper>
      </Background>
  )
}

Statistics.propTypes = {
  // post: PropTypes.object.isRequired
};

export default Statistics;