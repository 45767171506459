import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 15px 15px;
`;

export const Label = styled.p`
  font-weight: bold;
  margin: 0 0 10px 0;

  @media only screen and (min-width: 768px) {
  margin: 0 10px 0 0;
  }
`;

export const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Icon = styled.a`
  display: block;
  padding: 0 2px;
  cursor: pointer;

  svg {
    width: 30px;
  }
`;
