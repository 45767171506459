import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #541516;
  background-color: white;
  background-size: cover;
  background-position: center;
  overflow: auto;
  padding: 38px 70px;

`;

export const Title = styled.div`
  display:flex;
  justify-content: space-between;
`

export const Logo = styled.a`
  background-image:url("/images/logo.svg");
  background-repeat: no-repeat;
  width: 208px;
  height: 90px;
  background-size: 100% 100%;
  font-size: 0;
  text-indent: -9999;
  overflow: hidden;
  margin-bottom: 38px;
  @media only screen and (max-width:415px){
    width: 104px;
    height: 45px;
    margin-bottom: 15px;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  font-family: "AppleGothic";
  font-size: 17px;
  font-weight: 700;
  color: #000000;
  display: inline-block;
  padding: 0 8px;
  @media only screen and (max-width:768px){
    font-size: 14px;
    font-weight:600;
  }
  @media only screen and (max-width:375px){
    font-size: 10px;
    font-weight: 600;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
`

export const Subject = styled.h1`
  color:#3562c4;
  font-size: 5em;
`

export const Date = styled.p`
color: #e833bd;
font-weight: 800

`

export const Value = styled.span`
  font-weight: 800
  color: #545454
`

export const Footer = styled.div`
  width: 100%;
  text-align:center;
  margin: 50px auto
`