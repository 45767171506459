import React from 'react';
import PropTypes from 'prop-types';
import { linkedInAuthorizationUrl } from '../../utils/user';

const LinkedIn = ({ children, supportIE, redirectPath, onSuccess, onFailure }) => {
  let popup;

  const getUrl = () => {
    const linkedInAuthenLink = linkedInAuthorizationUrl();

    if (supportIE) {
      const redirectLink = `${window.location.origin}${redirectPath}?linkedin_redirect_url=${encodeURIComponent(linkedInAuthenLink)}`;
      return redirectLink;
    }
    return linkedInAuthenLink;
  };

  const receiveMessage = event => {
    const state = process.env.REACT_APP_LINKEDIN_STATE;

    if (event.origin === window.location.origin) {
      if (event.data.errorMessage && event.data.from === 'Linked In') {
        // Prevent CSRF attack by testing state
        if (event.data.state !== state) {
          popup && popup.close();
          return;
        }
        onFailure(event.data);
        popup && popup.close();
      } else if (event.data.code && event.data.from === 'Linked In') {
        // Prevent CSRF attack by testing state
        if (event.data.state !== state) {
          popup && popup.close();
          return;
        }
        onSuccess({ code: event.data.code });
        popup && popup.close();
      }
    }
  };

  const handleConnectLinkedInClick = (e) => {
    if (e) {
      e.preventDefault();
    }

    popup = window.open(getUrl(), '_blank', 'width=600,height=600');
    window.removeEventListener('message', receiveMessage, false);
    window.addEventListener('message', receiveMessage, false);
  };


  React.useEffect(() => {
    return () => {
      window.removeEventListener('message', receiveMessage, false);
      if (popup && !popup.closed) popup.close();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{ children(handleConnectLinkedInClick) }</>;
};

LinkedIn.propTypes = {
  children: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  supportIE: PropTypes.bool,
  redirectPath: PropTypes.string
};

LinkedIn.defaultProps = {
  supportIE: false,
  redirectPath: '/linkedin'
};


export default LinkedIn;
