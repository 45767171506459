import styled from "styled-components";

export const Wrapper = styled.div`
  @media only screen and (min-width: 768px) {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  padding-top: 120px;

  @media only screen and (min-width: 768px) {
    padding: 0 20px 20px 40px;
  }
`;

export const ProductLines = styled.div`
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
  margin-right: 20px;
  @media (max-width: 765px) {
    flex-direction: column;
  }
`;
