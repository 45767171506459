import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.aside`
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #2B2931;
  color: #9b9b9b;
  padding: 15px;
  flex-shrink: 0;
  z-index: 2;

  @media only screen and (min-width: 768px) {
    position: relative;
    width: 280px;
    padding: 38px 30px;
    transition: all 0.2s ease-in-out;

    ${props => props.collapsed && css`
      width: 50px;
      padding: 38px 10px;
    `}
  }
`;

export const Toggle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 8px 0 5px;
  appearance: none;
  background-color: #2B2931;
  border: 0;
  border-radius: 0 10px 10px 0;
  color: white;
  position: absolute;
  left: 100%;
  top: 30px;
  cursor: pointer;
  transition: .3s ease;

  svg {
    width: 10px;
    height: 10px;
    fill: #9b9b9b;
    transform: ${({ collapsed }) => (collapsed ? `rotate(0deg)` : `rotate(180deg)`)};
  }

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (min-width: 768px) {
    margin-bottom: 25px;
    overflow: hidden;
  }

  .header-info {
    @media only screen and (min-width: 768px) {
      ${props => props.collapsed && css`
        display: none;
      `}
    }
  }

  h1 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    color: #fff;
  }

  h2 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
`;

export const BrandLogo = styled.button`
  overflow: hidden;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
  flex-shrink: 0;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    outline: none;
  }

  @media only screen and (min-width: 768px) {
    width: 70px;
    height: 70px;
    transition: all 0.2s ease-in-out;

    ${props => props.collapsed && css`
      width: 30px;
      height: 30px;
    `}
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const BrandInfo = styled.div`

`;

export const HamburgerMenu = styled.a`
  position: relative;
  overflow: hidden;
  display: block;
  width: 25px;
  height: 25px;
  background-image: url("/images/icons/menu.svg");
  background-size: contain;
  background-position: center;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    display: none;
  }

  span {
    position: absolute;
    top: 0px;
    left: -5000px;
  }
`;

export const Navigation = styled.nav`
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  background-color: #2B2931;
  width: 100%;
  height: calc(100vh - 90px);
  top: 90px;
  left: ${props => props.isOpen ? '0' : '-100vw' };
  transition: left 0.2s ease;
  padding: 20px 0;

  @media only screen and (min-width: 768px) {
    position: static;
    display: flex;
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Item = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 15px;
  position: relative;
  font-size: 16px;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    padding: 15px 0;
    transition: all 0.2s ease-in-out;

    ${props => props.collapsed && css`
      span {
        opacity: 0;
        visibility: hidden;
      }
    `}
  }

  svg {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    fill: #9b9b9b;
  }
`;

export const Signout = styled.a`
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 15px;
  position: relative;
  font-size: 16px;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    padding: 15px 0;
    transition: all 0.2s ease-in-out;

    ${props => props.collapsed && css`
      span {
        opacity: 0;
        visibility: hidden;
      }
    `}
  }

  svg {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    fill: #9b9b9b;
  }
`;
