import styled from 'styled-components';

/* this file no longer used, converted to Sass. Slate for deletion. */
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #979797;
  margin-bottom: 15px;
  height: 100%;
  transition: opacity 1s;
  opacity: ${props => props.showing ? 1 : 0}

  img {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex-grow: 1;
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const DeletePost = styled.a`
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 30px;
  height: 30px;
  padding: 7px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    fill: #980000;
  }
`;

export const RecyclePost = styled.a`
  position: absolute;
  top: 10px;
  right: 50px;
  display: block;
  width: 30px;
  height: 30px;
  padding: 7px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    fill: #980000;
  }
`;

export const Image = styled.img`
  width: 100%;

`;

export const Body = styled.div`
  textarea {
    width: 100%;
    border: dashed 1px #D8D8D8;
    border-radius: 5px;
    resize: none;
    margin-bottom: 15px;
    padding: 10px 10px 25px;
    background-image: url('/images/icons/pencil-gray.svg');
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) calc(100% - 5px);
  }
`;

export const Tags = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -3px;
  padding: 0;
`;

export const Tag = styled.li`
  display: flex;
  align-items: center;
  background-color: #f2cbcb;
  margin: 3px;
  padding: ${props => props.addtag ? "2px" : "2px 2px 2px 10px"};
  border-radius: 15px;
  font-size: 13px;
  font-weight: bold;
`;

export const DeleteTag = styled.a`
  width: 15px;
  height: 15px;
  margin-left: 8px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    fill: #980000;
  }
`;

export const AddTag = styled.a`
  width: 15px;
  height: 15px;
  cursor: pointer;

  svg {
    width: 15px;
    height: 15px;
    fill: #980000;
  }
`;

export const AddTagInput = styled.div`
  max-width: ${props=>props.isOpen ? 'auto' : '0'};
  overflow: hidden;
  padding-left: ${props=>props.isOpen ? '8px' : '0'};
  padding-right: ${props=>props.isOpen ? '8px' : '0'};
  transition: max-width 0.5s ease;
  height: 15px;

  input {
    padding: 0;
    border: 0;
    font-size: 13px;
    font-weight: bold;
    background-color: #f9eaea;
    margin: 0 3px;
    padding: 0 2px;
    min-width: 60px;
  }
`;
