import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Loading
} from './styles';

/** Component that checks for user upon completion of loading in App.js. 
* If no user is logged in, redirect to specified path.
* Else load the child component. */
function ProtectedRoute({ loadingUser, user, onFailureRedirectToPath, ...rest }) {
  return loadingUser ? (<Loading><CircularProgress size={60} /></Loading>) : 
  (
    user ? <Route {...rest} /> : (<Redirect to={onFailureRedirectToPath} />)
  )
}

export default ProtectedRoute;