import styled from 'styled-components';
import { Link } from 'react-router-dom';
import img from './contactPagePic1.png';

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 38px 70px;
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  display:flex;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 48px;
  font-family: "AppleGothic";
  color: rgb(172, 0, 1);
  font-weight: 750;
`

export const Logo = styled.a`
  background-image:url("/images/logo.svg");
  background-repeat: no-repeat;
  width: 208px;
  height: 90px;
  background-size: 100% 100%;
  font-size: 0;
  text-indent: -9999;
  overflow: hidden;
  margin-bottom: 38px;
  @media only screen and (max-width:415px){
    width: 104px;
    height: 45px;
    margin-bottom: 15px;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  font-family: "AppleGothic";
  font-size: 17px;
  font-weight: 700;
  color: #000000;
  display: inline-block;
  padding: 0 8px;
  @media only screen and (max-width:768px){
    font-size: 14px;
    font-weight:600;
  }
  @media only screen and (max-width:375px){
    font-size: 10px;
    font-weight: 600;
  }
`;


// export const Logo = styled.h1`
//   justify-content: flex-start;
//   background-image:url("/images/logo.svg");
//   background-repeat: no-repeat;
//   width: 208px;
//   height: 90px;
//   background-size: 100% 100%;
//   font-size: 0;
//   text-indent: -9999;
//   overflow: hidden;
//   margin-bottom: 38px;
//   @media only screen and (max-width:415px){
//     width: 104px;
//     height: 45px;
//     margin-bottom: 15px;
//   }
// `;

// export const Title = styled.div`
// padding-right: 28px;
//   img {
//     justify-content: flex-end;
//   }
// `;

export const MainBody = styled.div`
  display: flex;
  justify-content: space-around;
  // align-items: ;
  width: 100%
  height: 100%
  padding-top: 20px;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
`;

export const Picture = styled.div`
  // background:url(${img});
  // max-width:200%;
  // max-height:200%;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 3px solid black;
  img {
    width: 400px;
    height: 450px;
    // object-fit: contain;
    margin: 20px
  }
`;


export const FormArea = styled.div`
 display: flex;
 flex-direction:column;
 justify-content: space-around;
`;
export const Form = styled.div`

`;

export const TopForm = styled.div`
  > * {
    padding-left: 7px;
    text-align: center;
  }
`;

export const Name = styled.div`

`;

export const Email = styled.div`

`;

export const BottomForm = styled.div`
  padding: 2px 7px 8px;
`;

export const Message = styled.div`

`;

export const Submit = styled.div`
  display: flex;
  padding-top: 25px;
  padding-bottom: 5px;
  margin: 0 auto;
  padding-bottom: 5px;
  cursor: pointer;
  justify-content: center;

  button {
    padding: 12px 20px 9px;
    border-radius: 31px;
    font-family: "AppleGothic";
    font-size: 18px;
    color: #ffffff;
    background-color: #980000;
    cursor: pointer;
  }
`;


export const Footer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  vertical-align: baseline;
  bottom:0;
`;

export const FooterLink = styled.a`
  font-family: "AppleGothic";
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  cursor: pointer;
  text-decoration: underline;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
`;
