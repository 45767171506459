const R = require("ramda");

const filteredOutLabels = [
  "Human",
  "Person",
  "Man",
  "Woman",
  "Animal",
  "Thing",
  "Hand",
  "Machine",
  "Advertisement",
  "Poster",
  "Brochure",
  "Flyer",
  "Paper",
  "Trademark",
  "Tin",
  "Can",
  "Text",
  "Food",
  "Word",
  "Arm",
  "Sleve",
  "Vehicle",
  "Human",
  "Person",
];

const templates = [
  {
    categories: [
      "Clothing",
      "Apparel",
      "Fashion",
      "Clothes",
      "Wear",
      "Sweater",
      "Pants",
      "Fitness" ,
    ],
    text: [
      "Have you ever dreamt about that perfect {1}, well guess what, here at {company} we have it!",
      "Let's talk about fashion. We all love it but how difficult is it to find an outfit that makes you feel like yourself but also a better you. Think - You 2.0. Here is {1} by {company}.",
      "Be Exclusive. Be Divine. Be YOURSELF – Be {company}",
      "Be Exclusive. Be Divine. Be YOURSELF – Try {1} by {company}.",
      "True style never dies",
      "True style never dies - {1} by {company}.",
      "Engagin' Enhancin' Experiential - Elementary for {1}! Now at {company}!",
      "Everyday living. {company}.",
      "Everyday living. {1} brought to you by {company}.",
      "{company} - Exceptional products, unparalleled service, unique products. Choice of fashinistas!",
      "{1} brought to you exclusively by {company} – Exceptional products, unparalleled service, unique products. Choice of fashionistas!",
      "Looking for the best apparel this season? {company} has the best {1} and {2}. Check it out!",
      "Engagin' Enhancin' Experiential – Elementary for {company}.  It's not just another {1}. These are {1} created exclusively for you, by {company}!",
      "Remember, form is often more important than function. Enhance your form. Try our {1} . Only at Artistically Inspired {company}.",
      "Beautiful in its simplicity - {1} presented to you by {company}",
      "Enhance your life with {1} from {company}.",
      "See the beautiful you in these fabulous {1} from {company}.",
      "{1} from {company} - Looks beautiful on the outside and makes you truly feel good on the inside.",
      "Bring out the Awesome You with these {1} from {company}.",
      "Perfection from head to toe. Get noticed. {1} from {company}.",
      "Display your {1} from {company} with pride. Our reputation goes with it.",
      "Our longevity is built on your trust and love. You will like our {1}. {company} ask by name.",
      "For that moment to remember! {1} from {company}. The ideal choice.",
      "Celebrate Life. Make every moment memorable with {1} from {company}.",
      "One of its kind. {1} from {company}. Because you are Unique.",
      "Feel the quality. {1} from {company}. Worth the difference.",
      "For your most precious one. Our most precious {1} from {company}.",
    ],
  },
  {
    categories: ["Food", "Restaurant"],
    text: [
      "Nothing is more delicious than {1} by {company}.",
      "Have you tried {company}'s new {1}?",
      "Looking for something delicious? {company} has got your back. Delicious {1}.",
      "Out of this world flavors of {1}. What you could only previously dream of is now available by {company}.",
      "Delicious {1} will fill your senses with flavor. Try them now at {company}.",
      "Tastes so GOOD. Ask for it at {company}!",
    ],
  },
  {
    categories: ["Fitness"],
    text: [
      "Do you want to get fit, feel sexy, and have fun ? Join {company} for pole dancing and aerial classes! Learn new moves that will have you feeling confident in any situation.Join the {company} community today!" ,
      "Do you want to feel confident ? Want to learn new skills ? Want to release your inner goddess ? Come to {company} and learn pole dancing and aerial classes.  {company} is a company that offers pole dancing and aerial classes." ,
      "Learn pole dancing and aerial fitness from a professional instructor. {company} offers pole dancing and aerial classes for all levels.Join the {company} community today!" ,
      "If you're a college student and you're looking for a new way to get in shape, {company} is the place for you! {company} offers pole dancing and aerial classes.Pole Dancing Classes" ,
      "It's time to get more confident, attractive and fit! Are you ready to learn pole dancing and aerial arts? We offer classes for beginners to advanced levels. Call now to feel alive! {company}" ,
      "{company} is the new way to get fit.Come to {company} and learn the art of pole dancing and aerial classes.It's a great way to stay in shape and have fun." ,
      "Pole Dancing Classes - Learn to be a captivating dancer - Get a new hobby Come to {company} 's pole dancing classes and learn to be an amazing dancer! You'll have a new hobby that will have you feeling sexy and confident." ,
      "Do you want to feel like a goddess ? Do you want to feel like you can conquer the world ? Do you want to feel like you're in control of your life? Then come to {company}'s pole dancing and aerial classes." ,
      "Live your wildest fantasies with {company}! If you're a college girl looking for a way to let loose, {company} is the place for you! With pole dancing and aerial classes, {company} is the ultimate way to unleash your inner vixen.",
    ],
  },
  {
    categories: ["Pizza"],
    text: [
      // 'Combo deals TODAY/THIS WEEK from {company}. Buy one {1} and get another for only $5. FREE delivery within 2miles.',
      // 'TODAY ONLY! Buy any specialty {1} and get medium {2} / {3} FREE.',
      "Where taste meets the myth. {1} from {company}.",
      "The authentic Italian taste of Pizza. {1} from {company}.",
      "Artisans of the divine taste in pizza. {1} from {company}.",
    ],
  },
  {
    categories: ["Baby", "Sitters"],
    text: [
      "A child is not a vase to be filled, but a fire to be lit. {1} from {company}.",
      "A better alternative to expensive baby care. {1} from {company}.",
      "Because dazzling, busy parents need time off. {1} from {company}.",
      "Because your loved little one deserves better than just daycare. {1} from {company}.",
      "For children we are friends. For parents we are peace of mind. {1} from {company}.",
    ],
  },
  {
    categories: ["Personal Trainer", "Trainers", "Physical"],
    text: [
      "Age is but a number. I can help remove the limitations on what you can achieve. {1} from {company}.",
      "If it doesn’t challenge you, it won’t change you. Let me help you reach your potential. {1} from {company}.",
      "Its hard to beat a person who never gives up. I will help you be one. {1} from {company}.",
    ],
  },
  {
    categories: ["Tattoo", "Massage"],
    text: [
      "Make stress, headache, anxiety, pain, and depression problems of the past. Massage {1} from {company}.",
      "Wondering if a Deep Tissue, Thai, Shiatsu, Swedish or Reflexology massage will heal you? Let us help you with it. Massage {1} from {company}.",
      "The time to RELAX is when you don’t have TIME FOR IT! {1} from {company}.",
      "Be kind to your body. You will ever have only one. RELAX. UNWIND. With {1} from {company}.",
      "Things I like more than a massage…? Oh, right, NOTHING. {1} from {company}.",
      "Master engraver at work. {1} from {company}.",
      "Tattoos have a power and magic all their own. Try {1} from {company}.",
      "Yes I have tattoos and piercings. No that don’t make me irresponsible or untrustworthy. {1} from {company}.",
      "It's all about your tolerance to pain. The ink is just a souvenir. {1} from {company}.",
    ],
  },
  {
    categories: ["Gaming", "Game"],
    text: [
      "So fun and addicting. You will love the new game by {company}!",
      "Challenge yourself - try the new game by {company}.",
      "What level will you reach? Get the new game by {company} now!",
    ],
  },
  {
    categories: [
      "Invest",
      "Investment",
      "Bank",
      "Finance",
      "Financial",
      "Money",
      "Loan",
      "Debt",
    ],
    text: ["Pledging a risk-free investment. {1} from {company}."],
  },
  {
    categories: ["Appliances", "Electronics"],
    text: [
      "Any product that needs a manual to get working is broken. {1} from {company}. Simplicity by design.",
    ],
  },
  {
    categories: ["Toys"],
    text: [
      "{company} – Where your loved one will explore and discover an out of this world experience! {1}, {2} {3}",
      "{1} from {company} – Having fun is the best way to learn. From toddlers to teens!",
      "{1} from {company} – Making all their dreams come true!",
      "Let your child feel the wonders. Intelligent {1} from {company}",
      "We sell happiness. {1} from {company}",
      "{1} from {company} delivers smiles",
      "Keep them active. Stimulate their creativity. Let them enjoy. {1} from {company}",
      "For your most precious one. Our most precious one {1} from {company}",
      "Start a life full of adventure. Get them {1} from {company}. See them explore at a very young age.",
      "Arouse their curiosity but keep them safe. {1} from {company}.",
      "Pamper your child, more. {1} from {company}. A cause for BLISS.",
      "Smart toys for the smart ones. Keep them busy. Keep them happy. {1} from {company}.",
    ],
  },
];

const genericTemplates = [
  "Here at {company} we are excited to show you our new {1}.",
  "Exclusively here to help businesses with their marketing needs, {company} brings you {1}.",
  "Have you ever dreamt about that perfect {1}? Well, here at {company} we have it!",
  "{company}. Divine moments.",
  "{1} from {company}. Guaranteed satisfaction.",
  "Hurry! Selling fast. {1} from {company}.",
  "Score a bargain. {1} from {company}.",
  "{1} from {company}. No holds. Don’t miss out.",
  "We are so confident of {1} by {company}, we provide a satisfaction guarantee.",
  "Because... We make products our customers want. {1} from {company}.",
  "{1} from {company}. When you buy it, happiness is not a goal. It’s a by-product.",
];

const genericNoProductTemplate = "{company}. For moments, Money can buy!";

const getTemplateFromCategory = (categoryTemplateArray, random) => {
  if (random) {
    return categoryTemplateArray[
      Math.floor(Math.random() * categoryTemplateArray.length)
    ];
  } else {
    return categoryTemplateArray[0];
  }
};

export const generatePostBody = (lb, bN, bC) => {
  if (!lb || !bN || !bC || lb.length < 1) {
    return { success: false };
  }

  let data = {
    labels: lb,
    businessName: bN,
    businessCategory: bC,
  };

  const sortByConfidence = R.compose(R.reverse, R.sortBy(R.prop("Confidence")));
  const labels = R.filter(
    (l) => !filteredOutLabels.includes(l.Name),
    sortByConfidence(data.labels)
  );

  let text = getTemplateFromCategory(genericTemplates, true);
  let foundByCategory = false;
  let foundByLabels = false;
  // find template by business category
  templates.forEach((t) => {
    if (
      t.categories.some((r) => data.businessCategory.split(" ").includes(r))
    ) {
      text = getTemplateFromCategory(t.text, true);
      // console.warn('Found by category', data.businessCategory.split(' '), t.categories);
      foundByCategory = true;
    }
  });
  // find template by image recognised labels
  if (!foundByCategory || Math.random() < 0.2) {
    // introduce a 20% chance of generic template to add variety of options
    templates.forEach((t) => {
      if (t.categories.some((r) => R.pluck("Name", labels).includes(r))) {
        text = getTemplateFromCategory(t.text, true);
        foundByLabels = true;
      }
    });
  }

  if (labels.length < 1) {
    text = genericNoProductTemplate;
  }

  while (text.indexOf("{company}") >= 0) {
    text = R.replace("{company}", data.businessName, text);
  }
  for (let i = 1; i <= labels.length; i++) {
    // console.warn('lables!', `${i} of ${labels.length}`, labels);
    // console.warn(labels[i - 1].Name);
    while (text.indexOf(`{${i}}`) >= 0) {
      text = R.replace(`{${i}}`, labels[i - 1].Name, text);
    }
    // text = R.replace(`{${i}}`, labels[i - 1].Name.toLowerCase(), text);
  }

  return {
    success: true,
    foundByCategory,
    foundByLabels,
    text,
  };
};
