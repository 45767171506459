const { Configuration, OpenAIApi } = require("openai");

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY
const configuration = new Configuration({
    apiKey: OPENAI_API_KEY,
});

const openai = new OpenAIApi(configuration);

export const generateAdDescription = async (productName, features, audience, text, tokenSize) => {
    const prompt = productName
        ? `Write a creative ad for the following product aimed at ${audience} \n\nProduct:${productName} is ${features}`
        : `Write a creative ad for the following product : ${text}`;

    const response = await openai.createCompletion({
        model: "text-davinci-003",
        prompt,
        temperature: 0.5,
        max_tokens: tokenSize,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
    });

    if (!response || !response.data || !response.data.choices || response.data.choices.length === 0) {
        throw new Error("OpenAI API response is undefined or malformed");
    }

    const { data: { choices: [{ text: adDescription }] } } = response;
    return adDescription;
};

