import firebase from 'firebase';
import { userExists } from './user';

export const saveAccountInfo = async (uid, payload) => {
  const db = firebase.firestore();
  const { name, category, image, businessInfo } = payload;
  const data = {
    name,
    category: category.title, 
    businessInfo
  };
  if (image) {
    data.profileImage = image;
  }
  
  try {
    if (await userExists(db, uid)) {
      return db.collection('users').doc(uid).update(data);
    }

    return db.collection('users').doc(uid).set(data);
  } catch (error) {
    console.error(error);
  }
};

export const getAccountCategories = () => {
  return [
    // { title: 'Advertising/Marketing' },
    // { title: 'Advertising Agency' },
    // { title: 'Copywriting Service' },
    // { title: 'Internet Marketing Service' },
    // { title: 'Market Research Consultant' },
    // { title: 'Marketing Agency' },
    // { title: 'Media Agency' },
    // { title: 'Merchandising Service' },
    // { title: 'Public Relations Agency' },
    // { title: 'Social Media Agency' },
    // { title: 'Telemarketing Service' },
    // { title: 'Agriculture' },
    // { title: 'Agricultural Cooperative' },
    // { title: 'Agricultural Service' },
    // { title: 'Farm' },
    // { title: 'Dairy Farm' },
    // { title: 'Fish Farm' },
    // { title: 'Livestock Farm' },
    // { title: 'Poultry Farm' },
    // { title: 'Urban Farm' },
    // { title: 'Arts & Entertainment' },
    // { title: 'Adult Entertainment Club' },
    // { title: 'Amusement & Theme Park' },
    // { title: 'Water Park' },
    // { title: 'Aquarium' },
    // { title: 'Arcade' },
    // { title: 'Art Gallery' },
    // { title: 'Betting Shop' },
    // { title: 'Bingo Hall' },
    // { title: 'Casino' },
    // { title: 'Circus' },
    // { title: 'Dance & Night Club' },
    // { title: 'Escape Game Room' },
    // { title: 'Haunted House' },
    // { title: 'Karaoke' },
    // { title: 'Movie Theater' },
    // { title: 'Drive-In Movie Theater' },
    // { title: 'Museum' },
    // { title: 'Art Museum' },
    // { title: 'Asian Art Museum' },
    // { title: 'Cartooning Museum' },
    // { title: 'Contemporary Art Museum' },
    // { title: 'Costume Museum' },
    // { title: 'Decorative Arts Museum' },
    // { title: 'Design Museum' },
    // { title: 'Modern Art Museum' },
    // { title: 'Photography Museum' },
    // { title: 'Textile Museum' },
    // { title: 'Aviation Museum' },
    // { title: 'Children\'s Museum' },
    // { title: 'History Museum' },
    // { title: 'Civilization Museum' },
    // { title: 'Community Museum' },
    // { title: 'Science Museum' },
    // { title: 'Computer Museum' },
    // { title: 'Observatory' },
    // { title: 'Planetarium' },
    // { title: 'Sports Museum' },
    // { title: 'Performance & Event Venue' },
    // { title: 'Amphitheater' },
    // { title: 'Auditorium' },
    // { title: 'Comedy Club' },
    // { title: 'Jazz & Blues Club' },
    // { title: 'Live Music Venue' },
    // { title: 'Opera House' },
    // { title: 'Performance Art Theatre' },
    // { title: 'Pool & Billiard Hall' },
    // { title: 'Race Track' },
    // { title: 'Salsa Club' },
    // { title: 'Zoo' },
    // { title: 'Petting Zoo' },
    // { title: 'Wildlife Sanctuary' },
    // { title: 'Automotive, Aircraft & Boat' },
    // { title: 'Automotive Dealership' },
    // { title: 'ATV Dealership' },
    // { title: 'Aircraft Dealership' },
    // { title: 'Automotive Wholesaler' },
    // { title: 'Boat Dealership' },
    // { title: 'Car Dealership' },
    // { title: 'Commercial Truck Dealership' },
    // { title: 'Golf Cart Dealership' },
    // { title: 'Motorcycle Dealership' },
    // { title: 'Recreational Vehicle Dealership' },
    // { title: 'Trailer Dealership' },
    // { title: 'Automotive Service' },
    // { title: 'Auto Detailing Service' },
    // { title: 'Automotive Body Shop' },
    // { title: 'Automotive Consultant' },
    // { title: 'Automotive Customization Shop' },
    // { title: 'Automotive Glass Service' },
    // { title: 'Automotive Leasing Service' },
    // { title: 'Automotive Repair Shop' },
    // { title: 'Automotive Restoration Service' },
    // { title: 'Automotive Shipping Service' },
    // { title: 'Automotive Storage Facility' },
    // { title: 'Automotive Wheel Polishing Service' },
    // { title: 'Automotive Window Tinting Service' },
    // { title: 'Boat Service' },
    // { title: 'Car Wash' },
    // { title: 'Emergency Roadside Service' },
    // { title: 'Gas Station' },
    // { title: 'Marine Service Station' },
    // { title: 'Motorcycle Repair Shop' },
    // { title: 'Oil Lube & Filter Service' },
    // { title: 'RV Repair Shop' },
    // { title: 'Smog Emissions Check Station' },
    // { title: 'Tire Dealer & Repair Shop' },
    // { title: 'Towing Service' },
    // { title: 'Truck Repair Shop' },
    // { title: 'Wheel & Rim Repair Service' },
    // { title: 'Automotive Store' },
    // { title: 'Automotive Parts Store' },
    // { title: 'Car Stereo Store' },
    // { title: 'Marine Supply Store' },
    // { title: 'Motorsports Store' },
    // { title: 'Aviation Repair Station' },
    // { title: 'Avionics Shop' },
    // { title: 'Motor Vehicle Company' },
    // { title: 'Automotive Manufacturer' },
    // { title: 'Motorcycle Manufacturer' },
    // { title: 'Beauty, Cosmetic & Personal Care' },
    // { title: 'Barber Shop' },
    // { title: 'Beauty Salon' },
    // { title: 'Hair Salon' },
    // { title: 'Nail Salon' },
    // { title: 'Tanning Salon' },
    // { title: 'Beauty Supplier' },
    // { title: 'Hair Extensions Service' },
    // { title: 'Hair Removal Service' },
    // { title: 'Laser Hair Removal Service' },
    // { title: 'Sugaring Service' },
    // { title: 'Threading Service' },
    // { title: 'Waxing Service' },
    // { title: 'Hair Replacement Service' },
    // { title: 'Image Consultant' },
    // { title: 'Makeup Artist' },
    // { title: 'Skin Care Service' },
    // { title: 'Spa' },
    // { title: 'Aromatherapy Service' },
    // { title: 'Day Spa' },
    // { title: 'Health Spa' },
    // { title: 'Massage Service' },
    // { title: 'Onsen' },
    // { title: 'Tattoo & Piercing Shop' },
    // { title: 'Commercial & Industrial' },
    // { title: 'Automation Service' },
    // { title: 'Commercial & Industrial Equipment Supplier' },
    // { title: 'Environmental Service' },
    // { title: 'Environmental Consultant' },
    // { title: 'Geologic Service' },
    // { title: 'Occupational Safety and Health Service' },
    // { title: 'Recycling Center' },
    // { title: 'Forestry & Logging' },
    // { title: 'Forestry Service' },
    // { title: 'Logging Contractor' },
    // { title: 'Hotel Services Company' },
    // { title: 'Industrial Company' },
    // { title: 'Inventory Control Service' },
    // { title: 'Manufacturer/Supplier' },
    // { title: 'Aircraft Manufacturer' },
    // { title: 'Apparel Distributor' },
    // { title: 'Appliance Manufacturer' },
    // { title: 'Bags & Luggage Company' },
    // { title: 'Clothing Company' },
    // { title: 'Glass Manufacturer' },
    // { title: 'Jewelry & Watches Company' },
    // { title: 'Jewelry Wholesaler' },
    // { title: 'Machine Shop' },
    // { title: 'Mattress Manufacturer' },
    // { title: 'Metal & Steel Company' },
    // { title: 'Metal Fabricator' },
    // { title: 'Metal Plating Service Company' },
    // { title: 'Metal Supplier' },
    // { title: 'Mining Company' },
    // { title: 'Granite & Marble Supplier' },
    // { title: 'Plastic Company' },
    // { title: 'Plastic Fabricator' },
    // { title: 'Plastic Manufacturer' },
    // { title: 'Textile Company' },
    // { title: 'Tobacco Company' },
    // { title: 'Education' },
    // { title: 'Academic Camp' },
    // { title: 'Archaeological Service' },
    // { title: 'College & University' },
    // { title: 'Community College' },
    // { title: 'Educational Consultant' },
    // { title: 'Educational Research Center' },
    // { title: 'School' },
    // { title: 'Art School' },
    // { title: 'Day Care' },
    // { title: 'Elementary School' },
    // { title: 'High School' },
    // { title: 'Junior High School' },
    // { title: 'Medical School' },
    // { title: 'Middle School' },
    // { title: 'Performing Arts School' },
    // { title: 'Preschool' },
    // { title: 'Private School' },
    // { title: 'Public School' },
    // { title: 'Religious School' },
    // { title: 'Specialty School' },
    // { title: 'Aviation School' },
    // { title: 'Bartending School' },
    // { title: 'Computer Training School' },
    // { title: 'Cooking School' },
    // { title: 'Cosmetology School' },
    // { title: 'Dance School' },
    // { title: 'Driving School' },
    // { title: 'First Aid Class' },
    // { title: 'Flight School' },
    // { title: 'Language School' },
    // { title: 'Massage School' },
    // { title: 'Music Lessons & Instruction School' },
    // { title: 'Nursing School' },
    // { title: 'Painting Lessons' },
    // { title: 'Trade School' },
    // { title: 'Traffic School' },
    // { title: 'Test Preparation Center' },
    // { title: 'Tutor/Teacher' },
    // { title: 'Finance' },
    // { title: 'Bank' },
    // { title: 'Commercial Bank' },
    // { title: 'Credit Union' },
    // { title: 'Investment Bank' },
    // { title: 'Retail Bank' },
    // { title: 'Financial Service' },
    // { title: 'Accountant' },
    // { title: 'Bank Equipment & Service' },
    // { title: 'Cash Advance Service' },
    // { title: 'Collection Agency' },
    // { title: 'Credit Counseling Service' },
    // { title: 'Currency Exchange' },
    // { title: 'Financial Aid Service' },
    // { title: 'Financial Consultant' },
    // { title: 'Financial Planner' },
    // { title: 'Franchise Broker' },
    // { title: 'Loan Service' },
    // { title: 'Tax Preparation Service' },
    // { title: 'Insurance Company' },
    // { title: 'Insurance Agent' },
    // { title: 'Insurance Broker' },
    // { title: 'Investing Service' },
    // { title: 'Brokerage Firm' },
    // { title: 'Investment Management Company' },
    // { title: 'Hedge Fund' },
    { title: 'Food & Beverage' },
    { title: 'Fitness' },
    // { title: 'Bakery' },
    // { title: 'Wholesale Bakery' },
    // { title: 'Bar' },
    // { title: 'Beer Bar' },
    // { title: 'Beer Garden' },
    // { title: 'Champagne Bar' },
    // { title: 'Cocktail Bar' },
    // { title: 'Dive Bar' },
    // { title: 'Gay Bar' },
    // { title: 'Hookah Lounge' },
    // { title: 'Hotel Bar' },
    // { title: 'Irish Pub' },
    // { title: 'Lounge' },
    // { title: 'Pub' },
    // { title: 'Sake Bar' },
    // { title: 'Speakeasy' },
    // { title: 'Sports Bar' },
    // { title: 'Tiki Bar' },
    // { title: 'Whisky Bar' },
    // { title: 'Wine Bar' },
    // { title: 'Bottled Water Company' },
    // { title: 'Brewery' },
    // { title: 'Bubble Tea Shop' },
    // { title: 'Butcher Shop' },
    // { title: 'Cafe' },
    // { title: 'Coffee Shop' },
    // { title: 'Pet Cafe' },
    // { title: 'Tea Room' },
    // { title: 'Cafeteria' },
    // { title: 'Caterer' },
    // { title: 'Cheese Shop' },
    // { title: 'Convenience Store' },
    // { title: 'Deli' },
    // { title: 'Dessert Shop' },
    // { title: 'Candy Store' },
    // { title: 'Chocolate Shop' },
    // { title: 'Cupcake Shop' },
    // { title: 'Frozen Yogurt Shop' },
    // { title: 'Gelato Shop' },
    // { title: 'Ice Cream Shop' },
    // { title: 'Shaved Ice Shop' },
    // { title: 'Distillery' },
    // { title: 'Donut Shop' },
    // { title: 'Farmers Market' },
    // { title: 'Food Consultant' },
    // { title: 'Food Delivery Service' },
    // { title: 'Food Stand' },
    // { title: 'Food Truck' },
    // { title: 'Food Wholesaler' },
    // { title: 'Meat Wholesaler' },
    // { title: 'Restaurant Wholesaler' },
    // { title: 'Foodservice Distributor' },
    // { title: 'Grocery Store' },
    // { title: 'Ethnic Grocery Store' },
    // { title: 'Fish Market' },
    // { title: 'Fruit & Vegetable Store' },
    // { title: 'Health Food Store' },
    // { title: 'Organic Grocery Store' },
    // { title: 'Specialty Grocery Store' },
    // { title: 'Supermarket' },
    // { title: 'Wholesale Grocer' },
    // { title: 'Personal Chef' },
    // { title: 'Restaurant' },
    // { title: 'Afghan Restaurant' },
    // { title: 'African Restaurant' },
    // { title: 'Ethiopian Restaurant' },
    // { title: 'Nigerian Restaurant' },
    // { title: 'Senegalese Restaurant' },
    // { title: 'South African Restaurant' },
    // { title: 'American Restaurant' },
    // { title: 'Arabian Restaurant' },
    // { title: 'Asian Restaurant' },
    // { title: 'Asian Fusion Restaurant' },
    // { title: 'Burmese Restaurant' },
    // { title: 'Cambodian Restaurant' },
    // { title: 'Chinese Restaurant' },
    // { title: 'Anhui Restaurant' },
    // { title: 'Beijing Restaurant' },
    // { title: 'Cantonese Restaurant' },
    // { title: 'Dim Sum Restaurant' },
    // { title: 'Dongbei Restaurant' },
    // { title: 'Fujian Restaurant' },
    // { title: 'Guizhou Restaurant' },
    // { title: 'Hainan Restaurant' },
    // { title: 'Henan Restaurant' },
    // { title: 'Hong Kong Restaurant' },
    // { title: 'Hot Pot Restaurant' },
    // { title: 'Huaiyang Restaurant' },
    // { title: 'Hubei Restaurant' },
    // { title: 'Hunan Restaurant' },
    // { title: 'Imperial Restaurant' },
    // { title: 'Jiangsu Restaurant' },
    // { title: 'Jiangxi Restaurant' },
    // { title: 'Macanese Restaurant' },
    // { title: 'Manchu Restaurant' },
    // { title: 'Shaanxi Restaurant' },
    // { title: 'Shandong Restaurant' },
    // { title: 'Shanghainese Restaurant' },
    // { title: 'Shanxi Restaurant' },
    // { title: 'Szechuan/Sichuan Restaurant' },
    // { title: 'Tianjin Restaurant' },
    // { title: 'Xinjiang Restaurant' },
    // { title: 'Yunnan Restaurant' },
    // { title: 'Zhejiang Restaurant' },
    // { title: 'Filipino Restaurant' },
    // { title: 'Indo Chinese Restaurant' },
    // { title: 'Indonesian Restaurant' },
    // { title: 'Acehnese Restaurant' },
    // { title: 'Balinese Restaurant' },
    // { title: 'Betawinese Restaurant' },
    // { title: 'Javanese Restaurant' },
    // { title: 'Manadonese Restaurant' },
    // { title: 'Padangnese Restaurant' },
    // { title: 'Sundanese Restaurant' },
    // { title: 'Japanese Restaurant' },
    // { title: 'Donburi Restaurant' },
    // { title: 'Kaiseki Restaurant' },
    // { title: 'Kushikatsu Restaurant' },
    // { title: 'Monjayaki Restaurant' },
    // { title: 'Nabe Restaurant' },
    // { title: 'Okonomiyaki Restaurant' },
    // { title: 'Ramen Restaurant' },
    // { title: 'Shabu Shabu Restaurant' },
    // { title: 'Soba Restaurant' },
    // { title: 'Sukiyaki Restaurant' },
    // { title: 'Sushi Restaurant' },
    // { title: 'Takoyaki Restaurant' },
    // { title: 'Tempura Restaurant' },
    // { title: 'Teppanyaki Restaurant' },
    // { title: 'Tonkatsu Restaurant' },
    // { title: 'Udon Restaurant' },
    // { title: 'Unagi Restaurant' },
    // { title: 'Wagashi Restaurant' },
    // { title: 'Yakiniku Restaurant' },
    // { title: 'Yakitori Restaurant' },
    // { title: 'Yoshoku Restaurant' },
    // { title: 'Korean Restaurant' },
    // { title: 'Bossam/Jokbal Restaurant' },
    // { title: 'Bunsik Restaurant' },
    // { title: 'Gukbap Restaurant' },
    // { title: 'Janguh Restaurant' },
    // { title: 'Samgyetang Restaurant' },
    // { title: 'Malaysian Restaurant' },
    // { title: 'Mongolian Restaurant' },
    // { title: 'Noodle House' },
    // { title: 'Singaporean Restaurant' },
    // { title: 'Taiwanese Restaurant' },
    // { title: 'Thai Restaurant' },
    // { title: 'Vietnamese Restaurant' },
    // { title: 'Pho Restaurant' },
    // { title: 'Australian Restaurant' },
    // { title: 'Austrian Restaurant' },
    // { title: 'Bar & Grill' },
    // { title: 'Barbecue Restaurant' },
    // { title: 'Basque Restaurant' },
    // { title: 'Belgian Restaurant' },
    // { title: 'Breakfast & Brunch Restaurant' },
    // { title: 'British Restaurant' },
    // { title: 'Buffet Restaurant' },
    // { title: 'Burger Restaurant' },
    // { title: 'Cajun & Creole Restaurant' },
    // { title: 'Canadian Restaurant' },
    // { title: 'Caribbean Restaurant' },
    // { title: 'Dominican Restaurant' },
    // { title: 'Haitian Restaurant' },
    // { title: 'Jamaican Restaurant' },
    // { title: 'Trinidadian Restaurant' },
    // { title: 'Catalan Restaurant' },
    // { title: 'Chicken Joint' },
    // { title: 'Comfort Food Restaurant' },
    // { title: 'Continental Restaurant' },
    // { title: 'Crêperie' },
    // { title: 'Czech Restaurant' },
    // { title: 'Diner' },
    // { title: 'Drive In Restaurant' },
    // { title: 'Eastern European Restaurant' },
    // { title: 'Belarusian Restaurant' },
    // { title: 'Bulgarian Restaurant' },
    // { title: 'Romanian Restaurant' },
    // { title: 'Tatar Restaurant' },
    // { title: 'Egyptian Restaurant' },
    // { title: 'European Restaurant' },
    // { title: 'Family Style Restaurant' },
    // { title: 'Fast Food Restaurant' },
    // { title: 'Fish & Chips Restaurant' },
    // { title: 'Fondue Restaurant' },
    // { title: 'French Restaurant' },
    // { title: 'Gastropub' },
    // { title: 'German Restaurant' },
    // { title: 'Baden Restaurant' },
    // { title: 'Bavarian Restaurant' },
    // { title: 'Hessian Restaurant' },
    // { title: 'Palatine Restaurant' },
    // { title: 'Saxon Restaurant' },
    // { title: 'Swabian Restaurant' },
    // { title: 'Gluten-Free Restaurant' },
    // { title: 'Halal Restaurant' },
    // { title: 'Hawaiian Restaurant' },
    // { title: 'Health Food Restaurant' },
    // { title: 'Himalayan Restaurant' },
    // { title: 'Hot Dog Joint' },
    // { title: 'Hungarian Restaurant' },
    // { title: 'Iberian Restaurant' },
    // { title: 'Indian Restaurant' },
    // { title: 'Andhra Restaurant' },
    // { title: 'Awadhi Restaurant' },
    // { title: 'Bengali/Bangladeshi Restaurant' },
    // { title: 'Chaat Place' },
    // { title: 'Chettinad Restaurant' },
    // { title: 'Dhaba Restaurant' },
    // { title: 'Dosa Restaurant' },
    // { title: 'Goan Restaurant' },
    // { title: 'Gujarati Restaurant' },
    // { title: 'Hyderabadi Restaurant' },
    // { title: 'Indian Chinese Restaurant' },
    // { title: 'Irani Restaurant' },
    // { title: 'Jain Restaurant' },
    // { title: 'Karnataka Restaurant' },
    // { title: 'Kashmiri Restaurant' },
    // { title: 'Kerala Restaurant' },
    // { title: 'Maharashtrian Restaurant' },
    // { title: 'Mughalai Restaurant' },
    // { title: 'North Indian Restaurant' },
    // { title: 'Parsi Restaurant' },
    // { title: 'Punjabi Restaurant' },
    // { title: 'Rajasthani Restaurant' },
    // { title: 'South Indian Restaurant' },
    // { title: 'Tamilian Restaurant' },
    // { title: 'Udupi Restaurant' },
    // { title: 'Uttar Pradesh Restaurant' },
    // { title: 'Irish Restaurant' },
    // { title: 'Italian Restaurant' },
    // { title: 'Abruzzo Restaurant' },
    // { title: 'Aosta Restaurant' },
    // { title: 'Basilicata Restaurant' },
    // { title: 'Calabrian Restaurant' },
    // { title: 'Emilia Romagna Restaurant' },
    // { title: 'Friuli Venezia Giulia Restaurant' },
    // { title: 'Ligurian Restaurant' },
    // { title: 'Lombard Restaurant' },
    // { title: 'Marche Restaurant' },
    // { title: 'Molise Restaurant' },
    // { title: 'Neapolitan Restaurant' },
    // { title: 'Piedmont Restaurant' },
    // { title: 'Puglia Restaurant' },
    // { title: 'Roman Restaurant' },
    // { title: 'Sardinian Restaurant' },
    // { title: 'Sicilian Restaurant' },
    // { title: 'South Tyrolean Restaurant' },
    // { title: 'Trentino Alto Adige Restaurant' },
    // { title: 'Tuscan Restaurant' },
    // { title: 'Umbrian Restaurant' },
    // { title: 'Venetian Restaurant' },
    // { title: 'Kosher Restaurant' },
    // { title: 'Latin American Restaurant' },
    // { title: 'Argentinian Restaurant' },
    // { title: 'Belizean Restaurant' },
    // { title: 'Bolivian Restaurant' },
    // { title: 'Brazilian Restaurant' },
    // { title: 'Chilean Restaurant' },
    // { title: 'Colombian Restaurant' },
    // { title: 'Costa Rican Restaurant' },
    // { title: 'Cuban Restaurant' },
    // { title: 'Ecuadorian Restaurant' },
    // { title: 'Guatemalan Restaurant' },
    // { title: 'Honduran Restaurant' },
    // { title: 'Mexican Restaurant' },
    // { title: 'Tex-Mex Restaurant' },
    // { title: 'Nicaraguan Restaurant' },
    // { title: 'Panamanian Restaurant' },
    // { title: 'Paraguayan Restaurant' },
    // { title: 'Peruvian Restaurant' },
    // { title: 'Puerto Rican Restaurant' },
    // { title: 'Salvadoran Restaurant' },
    // { title: 'Uruguayan Restaurant' },
    // { title: 'Venezuelan Restaurant' },
    // { title: 'Live & Raw Food Restaurant' },
    // { title: 'Mediterranean Restaurant' },
    // { title: 'Greek Restaurant' },
    // { title: 'Middle Eastern Restaurant' },
    // { title: 'Armenian Restaurant' },
    // { title: 'Azerbaijani Restaurant' },
    // { title: 'Georgian Restaurant' },
    // { title: 'Israeli Restaurant' },
    // { title: 'Kurdish Restaurant' },
    // { title: 'Lebanese Restaurant' },
    // { title: 'Persian/Iranian Restaurant' },
    // { title: 'Syrian Restaurant' },
    // { title: 'Turkish Restaurant' },
    // { title: 'Kebab Shop' },
    // { title: 'Modern European Restaurant' },
    // { title: 'Molecular Gastronomy Restaurant' },
    // { title: 'Moroccan Restaurant' },
    // { title: 'Nepalese Restaurant' },
    // { title: 'New American Restaurant' },
    // { title: 'Pakistani Restaurant' },
    // { title: 'Pizza Place' },
    // { title: 'Polish Restaurant' },
    // { title: 'Polynesian Restaurant' },
    // { title: 'Portuguese Restaurant' },
    // { title: 'Russian Restaurant' },
    // { title: 'Salad Bar' },
    // { title: 'Scandinavian Restaurant' },
    // { title: 'Scottish Restaurant' },
    // { title: 'Seafood Restaurant' },
    // { title: 'Slovakian Restaurant' },
    // { title: 'Soul Food Restaurant' },
    // { title: 'Soup Restaurant' },
    // { title: 'Southern Restaurant' },
    // { title: 'Southwestern Restaurant' },
    // { title: 'Spanish Restaurant' },
    // { title: 'Sri Lankan Restaurant' },
    // { title: 'Steakhouse' },
    // { title: 'Swiss Restaurant' },
    // { title: 'Taco Restaurant' },
    // { title: 'Tapas Bar & Restaurant' },
    // { title: 'Theme Restaurant' },
    // { title: 'Ukrainian Restaurant' },
    // { title: 'Uzbek Restaurant' },
    // { title: 'Vegetarian/Vegan Restaurant' },
    // { title: 'Sandwich Shop' },
    // { title: 'Smoothie & Juice Bar' },
    // { title: 'Wine, Beer & Spirits Store' },
    // { title: 'Homebrew Supply Store' },
    // { title: 'Winery/Vineyard' },
    // { title: 'Hotel & Lodging' },
    // { title: 'Bed and Breakfast' },
    // { title: 'Cabin' },
    // { title: 'Campground' },
    // { title: 'Cottage' },
    // { title: 'Hostel' },
    // { title: 'Hotel' },
    // { title: 'Beach Resort' },
    // { title: 'Hotel Resort' },
    // { title: 'Inn' },
    // { title: 'Lodge' },
    // { title: 'Motel' },
    // { title: 'RV Park' },
    // { title: 'Service Apartments' },
    // { title: 'Vacation Home Rental' },
    // { title: 'Legal' },
    // { title: 'Lawyer & Law Firm' },
    // { title: 'Bankruptcy Lawyer' },
    // { title: 'Contract Lawyer' },
    // { title: 'Corporate Lawyer' },
    // { title: 'Criminal Lawyer' },
    // { title: 'DUI Lawyer' },
    // { title: 'Divorce & Family Lawyer' },
    // { title: 'Entertainment Lawyer' },
    // { title: 'Estate Planning Lawyer' },
    // { title: 'General Litigation' },
    // { title: 'Immigration Lawyer' },
    // { title: 'Intellectual Property Lawyer' },
    // { title: 'Internet Lawyer' },
    // { title: 'Juvenile Lawyer' },
    // { title: 'Labor & Employment Lawyer' },
    // { title: 'Landlord & Tenant Lawyer' },
    // { title: 'Malpractice Lawyer' },
    // { title: 'Medical Lawyer' },
    // { title: 'Military Lawyer' },
    // { title: 'Personal Injury Lawyer' },
    // { title: 'Property Lawyer' },
    // { title: 'Real Estate Lawyer' },
    // { title: 'Tax Lawyer' },
    // { title: 'Legal Service' },
    // { title: 'Bail Bondsmen' },
    // { title: 'Lobbyist' },
    // { title: 'Notary Public' },
    // { title: 'Private Investigator' },
    // { title: 'Process Service' },
    // { title: 'Local Service' },
    // { title: 'Adoption Service' },
    // { title: 'Astrologist' },
    // { title: 'Astrologist & Psychic' },
    // { title: 'Bicycle Repair Service' },
    // { title: 'Bottled Water Supplier' },
    // { title: 'Business Service' },
    // { title: 'Business Consultant' },
    // { title: 'Business Supply Service' },
    // { title: 'Cargo & Freight Company' },
    // { title: 'Consulting Agency' },
    // { title: 'Employment Agency' },
    // { title: 'Recruiter' },
    // { title: 'Franchising Service' },
    // { title: 'Graphic Designer' },
    // { title: 'Hospitality Service' },
    // { title: 'Janitorial Service' },
    // { title: 'Management Service' },
    // { title: 'Personal Assistant' },
    // { title: 'Personal Coach' },
    // { title: 'Secretarial Service' },
    // { title: 'Security Guard Service' },
    // { title: 'Shipping Supply & Service' },
    // { title: 'Shredding Service' },
    // { title: 'Translator' },
    // { title: 'Uniform Supplier' },
    // { title: 'Vending Machine Sales & Service' },
    // { title: 'Web Designer' },
    // { title: 'Career Counselor' },
    // { title: 'Computer Repair Service' },
    // { title: 'Dating Service' },
    // { title: 'Design & Fashion' },
    // { title: 'Modeling Agency' },
    // { title: 'Dry Cleaner' },
    // { title: 'Event Planner' },
    // { title: 'Bartending Service' },
    // { title: 'Wedding Planning Service' },
    // { title: 'Event Space' },
    // { title: 'Ballroom' },
    // { title: 'Business Center' },
    // { title: 'Convention Center' },
    // { title: 'Wedding Venue' },
    // { title: 'Florist' },
    // { title: 'Funeral Service & Cemetery' },
    // { title: 'Genealogist' },
    // { title: 'Glass Blower' },
    // { title: 'Home Improvement' },
    // { title: 'Appliance Repair Service' },
    // { title: 'Heating, Ventilating & Air Conditioning Service' },
    // { title: 'Refrigeration Service' },
    // { title: 'Television Repair Service' },
    // { title: 'Water Heater Installation & Repair Service' },
    // { title: 'Architectural Designer' },
    // { title: 'Carpenter' },
    // { title: 'Carpet Cleaner' },
    // { title: 'Chimney Sweeper' },
    // { title: 'Concrete Contractor' },
    // { title: 'Construction Company' },
    // { title: 'Contractor' },
    // { title: 'Damage Restoration Service' },
    // { title: 'Deck & Patio Builder' },
    // { title: 'Demolition & Excavation Company' },
    // { title: 'Electrician' },
    // { title: 'Elevator Service' },
    // { title: 'Fence & Gate Contractor' },
    // { title: 'Fire Protection Service' },
    // { title: 'Furniture Repair & Upholstery Service' },
    // { title: 'Garage Door Service' },
    // { title: 'Gardener' },
    // { title: 'Glass Service' },
    // { title: 'Gutter Cleaning Service' },
    // { title: 'Handyman' },
    // { title: 'Home Security Company' },
    // { title: 'Home Window Service' },
    // { title: 'House Painting' },
    // { title: 'Interior Design Studio' },
    // { title: 'Kitchen & Bath Contractor' },
    // { title: 'Landscape Company' },
    // { title: 'Lumber Yard' },
    // { title: 'Masonry Contractor' },
    // { title: 'Paving & Asphalt Service' },
    // { title: 'Pest Control Service' },
    // { title: 'Plumbing Service' },
    // { title: 'Portable Building Service' },
    // { title: 'Portable Toilet Rentals' },
    // { title: 'Powder Coating Service' },
    // { title: 'Roofing Service' },
    // { title: 'Sandblasting Service' },
    // { title: 'Septic Tank Service' },
    // { title: 'Sewer Service' },
    // { title: 'Solar Energy Service' },
    // { title: 'Swimming Pool & Hot Tub Service' },
    // { title: 'Swimming Pool Cleaner' },
    // { title: 'Tiling Service' },
    // { title: 'Tree Cutting Service' },
    // { title: 'Vinyl Siding Company' },
    // { title: 'Water Treatment Service' },
    // { title: 'Well Water Drilling Service' },
    // { title: 'Window Installation Service' },
    // { title: 'In-Home Service' },
    // { title: 'Babysitter' },
    // { title: 'Child Care Service' },
    // { title: 'Cleaning Service' },
    // { title: 'House Sitter' },
    // { title: 'Maid & Butler' },
    // { title: 'Nanny' },
    // { title: 'Internet Cafe' },
    // { title: 'Junkyard' },
    // { title: 'Laundromat' },
    // { title: 'Locksmith' },
    // { title: 'Moving & Storage Service' },
    // { title: 'Home Mover' },
    // { title: 'Self-Storage Facility' },
    // { title: 'Storage Facility' },
    // { title: 'Parking Garage / Lot' },
    // { title: 'Party & Entertainment Service' },
    // { title: 'Party Entertainment Service' },
    // { title: 'Adult Entertainment Service' },
    // { title: 'DJ' },
    // { title: 'Kids Entertainment Service' },
    // { title: 'Magician' },
    // { title: 'Pet Service' },
    // { title: 'Animal Rescue Service' },
    // { title: 'Animal Shelter' },
    // { title: 'Dog Day Care Center' },
    // { title: 'Dog Trainer' },
    // { title: 'Dog Walker' },
    // { title: 'Horse Trainer' },
    // { title: 'Kennel' },
    // { title: 'Livery Stable' },
    // { title: 'Pet Adoption Service' },
    // { title: 'Pet Breeder' },
    // { title: 'Dog Breeder' },
    // { title: 'Pet Groomer' },
    // { title: 'Pet Sitter' },
    // { title: 'Taxidermist' },
    // { title: 'Veterinarian' },
    // { title: 'Photography Videography' },
    // { title: 'Amateur Photographer' },
    // { title: 'Camera Store' },
    // { title: 'Event Photographer' },
    // { title: 'Event Videographer' },
    // { title: 'Photo Booth Service' },
    // { title: 'Photographer' },
    // { title: 'Printing Service' },
    // { title: 'Screen Printing & Embroidery' },
    // { title: 'Signs & Banner Service' },
    // { title: 'Sewing & Alterations' },
    // { title: 'Shoe Repair Shop' },
    // { title: 'Weight Loss Center' },
    // { title: 'Writing Service' },
    // { title: 'Media/News Company' },
    // { title: 'Animation Studio' },
    // { title: 'Book & Magazine Distributor' },
    // { title: 'Broadcasting & Media Production Company' },
    // { title: 'Game Publisher' },
    // { title: 'Movie/Television Studio' },
    // { title: 'Music Production Studio' },
    // { title: 'Publisher' },
    // { title: 'Radio Station' },
    // { title: 'Social Media Company' },
    // { title: 'Medical & Health' },
    // { title: 'Dentist & Dental Office' },
    // { title: 'Cosmetic Dentist' },
    // { title: 'Endodontist' },
    // { title: 'General Dentist' },
    // { title: 'Oral Surgeon' },
    // { title: 'Orthodontist' },
    // { title: 'Pediatric Dentist' },
    // { title: 'Periodontist' },
    // { title: 'Prosthodontist' },
    // { title: 'Teeth Whitening Service' },
    // { title: 'Doctor' },
    // { title: 'Allergist' },
    // { title: 'Anesthesiologist' },
    // { title: 'Audiologist' },
    // { title: 'Cardiologist' },
    // { title: 'Dermatologist' },
    // { title: 'Endocrinologist' },
    // { title: 'Family Doctor' },
    // { title: 'Fertility Doctor' },
    // { title: 'Gastroenterologist' },
    // { title: 'Gerontologist' },
    // { title: 'Internist (Internal Medicine)' },
    // { title: 'Lasik/Laser Eye Surgeon' },
    // { title: 'Nephrologist' },
    // { title: 'Neurologist' },
    // { title: 'Neurosurgeon' },
    // { title: 'Obstetrician-Gynecologist (OBGYN)' },
    // { title: 'Oncologist' },
    // { title: 'Ophthalmologist' },
    // { title: 'Optometrist' },
    // { title: 'Orthopedist' },
    // { title: 'Osteopathic Doctor' },
    // { title: 'Otolaryngologist (ENT)' },
    // { title: 'Pediatrician' },
    // { title: 'Plastic Surgeon' },
    // { title: 'Podiatrist' },
    // { title: 'Proctologist' },
    // { title: 'Psychiatrist' },
    // { title: 'Psychologist' },
    // { title: 'Pulmonologist' },
    // { title: 'Radiologist' },
    // { title: 'Rheumatologist' },
    // { title: 'Surgeon' },
    // { title: 'Urologist' },
    // { title: 'Medical Center' },
    // { title: 'AIDS Resource Center' },
    // { title: 'Blood Bank' },
    // { title: 'Crisis Prevention Center' },
    // { title: 'Diagnostic Center' },
    // { title: 'Dialysis Clinic' },
    // { title: 'Emergency Room' },
    // { title: 'Family Medicine Practice' },
    // { title: 'Halfway House' },
    // { title: 'Hospice' },
    // { title: 'Hospital' },
    // { title: 'Maternity Clinic' },
    // { title: 'Medical Lab' },
    // { title: 'Nursing Home' },
    // { title: 'Pregnancy Care Center' },
    // { title: 'Retirement & Assisted Living Facility' },
    // { title: 'STD Testing Center' },
    // { title: 'Surgical Center' },
    // { title: 'Women\'s Health Clinic' },
    // { title: 'Medical Device Company' },
    // { title: 'Medical Equipment Manufacturer' },
    // { title: 'Medical Equipment Supplier' },
    // { title: 'Medical Research Center' },
    // { title: 'Medical Service' },
    // { title: 'Abortion Service' },
    // { title: 'Addiction Service' },
    // { title: 'Addiction Resources Center' },
    // { title: 'Addiction Treatment Center' },
    // { title: 'Alcohol Addiction Treatment Center' },
    // { title: 'Drug Addiction Treatment Center' },
    // { title: 'Tobacco Cessation Treatment Center' },
    // { title: 'Alternative & Holistic Health Service' },
    // { title: 'Acupuncturist' },
    // { title: 'Chiropractor' },
    // { title: 'Massage Therapist' },
    // { title: 'Medical Cannabis Dispensary' },
    // { title: 'Medical Spa' },
    // { title: 'Meditation Center' },
    // { title: 'Naturopath' },
    // { title: 'Nutritionist' },
    // { title: 'Reflexologist' },
    // { title: 'Disability Service' },
    // { title: 'Emergency Rescue Service' },
    // { title: 'Healthcare Administrator' },
    // { title: 'Home Health Care Service' },
    // { title: 'Mental Health Service' },
    // { title: 'Nursing Agency' },
    // { title: 'Orthotics & Prosthetics Service' },
    // { title: 'Reproductive Service' },
    // { title: 'Safety & First Aid Service' },
    // { title: 'Optician' },
    // { title: 'Pharmaceutical Company' },
    // { title: 'Pharmacy / Drugstore' },
    // { title: 'Medical Supply Store' },
    // { title: 'Vitamin Supplement Shop' },
    // { title: 'Therapist' },
    // { title: 'Counselor' },
    // { title: 'Family Therapist' },
    // { title: 'Marriage Therapist' },
    // { title: 'Occupational Therapist' },
    // { title: 'Physical Therapist' },
    // { title: 'Psychotherapist' },
    // { title: 'Sex Therapist' },
    // { title: 'Speech Pathologist' },
    // { title: 'Speech Therapist' },
    // { title: 'Sport Psychologist' },
    // { title: 'Non-Governmental Organization (NGO)' },
    // { title: 'Nonprofit Organization' },
    // { title: 'Public & Government Service' },
    // { title: 'Automotive Registration Center' },
    // { title: 'Child Protective Service' },
    // { title: 'Community Center' },
    // { title: 'Senior Center' },
    // { title: 'Cultural Center' },
    // { title: 'DMV' },
    // { title: 'Defense Company' },
    // { title: 'Food Bank' },
    // { title: 'Housing & Homeless Shelter' },
    // { title: 'Law Enforcement Agency' },
    // { title: 'Library' },
    // { title: 'Passport & Visa Service' },
    // { title: 'Public Service' },
    // { title: 'Public Utility Company' },
    // { title: 'Electric Utility Provider' },
    // { title: 'Energy Company' },
    // { title: 'Internet Service Provider' },
    // { title: 'Television Service Provider' },
    // { title: 'Water Utility Company' },
    // { title: 'Social Service' },
    // { title: 'Waste Management Company' },
    // { title: 'Real Estate' },
    // { title: 'Commercial Real Estate Agency' },
    // { title: 'Escrow Service' },
    // { title: 'Home Inspector' },
    // { title: 'Home Staging Service' },
    // { title: 'Housing Assistance Service' },
    // { title: 'Mobile Home Dealer' },
    // { title: 'Mobile Home Park' },
    // { title: 'Mortgage Brokers' },
    // { title: 'Property Management Company' },
    // { title: 'Real Estate Agent' },
    // { title: 'Real Estate Appraiser' },
    // { title: 'Real Estate Company' },
    // { title: 'Real Estate Developer' },
    // { title: 'Real Estate Investment Firm' },
    // { title: 'Real Estate Service' },
    // { title: 'Real Estate Title & Development' },
    // { title: 'Science, Technology & Engineering' },
    // { title: 'Aerospace Company' },
    // { title: 'Biotechnology Company' },
    // { title: 'Chemical Company' },
    // { title: 'Gas & Chemical Service' },
    // { title: 'Petroleum Service' },
    // { title: 'Engineering Service' },
    // { title: 'Information Technology Company' },
    // { title: 'Computer Company' },
    // { title: 'Electronics Company' },
    // { title: 'Internet Company' },
    // { title: 'Software Company' },
    // { title: 'Telecommunication Company' },
    // { title: 'Cable & Satellite Company' },
    // { title: 'Robotics Company' },
    // { title: 'Solar Energy Company' },
    // { title: 'Structural Engineer' },
    // { title: 'Surveyor' },
    // { title: 'Shopping & Retail' },
    // { title: 'Antique Store' },
    { title: 'Apparel & Clothing' },
    // { title: 'Accessories' },
    // { title: 'Bags & Luggage Store' },
    // { title: 'Hat Store' },
    // { title: 'Jewelry & Watches Store' },
    // { title: 'Sunglasses & Eyewear Store' },
    // { title: 'Clothing Store' },
    // { title: 'Baby & Children\'s Clothing Store' },
    // { title: 'Bridal Shop' },
    // { title: 'Costume Shop' },
    // { title: 'Footwear Store' },
    // { title: 'Lingerie & Underwear Store' },
    // { title: 'Maternity & Nursing Clothing Store' },
    // { title: 'Men\'s Clothing Store' },
    // { title: 'Sportswear Store' },
    // { title: 'Swimwear Store' },
    // { title: 'Women\'s Clothing Store' },
    // { title: 'Arts & Crafts Store' },
    // { title: 'Auction House' },
    // { title: 'Beauty Store' },
    // { title: 'Beauty Supply Store' },
    // { title: 'Cosmetics Store' },
    // { title: 'Wig Store' },
    // { title: 'Big Box Retailer' },
    // { title: 'Bookstore' },
    // { title: 'College / University Bookstore' },
    // { title: 'Comic Bookstore' },
    // { title: 'Independent Bookstore' },
    // { title: 'Religious Bookstore' },
    // { title: 'Boutique Store' },
    // { title: 'Collectibles Store' },
    // { title: 'Cultural Gifts Store' },
    // { title: 'Department Store' },
    // { title: 'Discount Store' },
    // { title: 'Duty-Free Shop' },
    // { title: 'E-Cigarette Store' },
    // { title: 'Educational Supply Store' },
    // { title: 'Electronics Store' },
    // { title: 'Audio Visual Equipment Store' },
    // { title: 'Computer Store' },
    // { title: 'Fabric Store' },
    // { title: 'Fireworks Retailer' },
    // { title: 'Flea Market' },
    // { title: 'Gift Shop' },
    // { title: 'Glass & Mirror Shop' },
    // { title: 'Gun Store' },
    // { title: 'Hobby Store' },
    // { title: 'Home & Garden Store' },
    // { title: 'Appliance Store' },
    // { title: 'Awning Supplier' },
    // { title: 'Blinds & Curtains Store' },
    // { title: 'Building Material Store' },
    // { title: 'Cabinet & Countertop Store' },
    // { title: 'Carpet & Flooring Store' },
    // { title: 'Fireplace Store' },
    // { title: 'Furniture Store' },
    // { title: 'Garden Center' },
    // { title: 'Hardware Store' },
    // { title: 'Home Goods Store' },
    // { title: 'Home Theater Store' },
    // { title: 'Lighting Store' },
    // { title: 'Mattress Store' },
    // { title: 'Nurseries & Gardening Store' },
    // { title: 'Lottery Retailer' },
    // { title: 'Mattress Wholesaler' },
    // { title: 'Mobile Phone Shop' },
    // { title: 'Movie & Music Store' },
    // { title: 'Moving Supply Store' },
    // { title: 'Musical Instrument Store' },
    // { title: 'Newsstand' },
    // { title: 'Night Market' },
    // { title: 'Office Equipment Store' },
    // { title: 'Outdoor & Sporting Goods Company' },
    // { title: 'Outlet Store' },
    // { title: 'Party Supply & Rental Shop' },
    // { title: 'Carnival Supply Store' },
    // { title: 'Pawn Shop' },
    // { title: 'Pet Store' },
    // { title: 'Aquatic Pet Store' },
    // { title: 'Reptile Pet Store' },
    // { title: 'Pop-Up Shop' },
    // { title: 'Rent to Own Store' },
    // { title: 'Restaurant Supply Store' },
    // { title: 'Retail Company' },
    // { title: 'Seasonal Store' },
    // { title: 'Shopping Mall' },
    // { title: 'Shopping Service' },
    // { title: 'Souvenir Shop' },
    // { title: 'Sporting Goods Store' },
    // { title: 'Archery Shop' },
    // { title: 'Bicycle Shop' },
    // { title: 'Fishing Store' },
    // { title: 'Mountain Biking Shop' },
    // { title: 'Outdoor Equipment Store' },
    // { title: 'Skate Shop' },
    // { title: 'Ski & Snowboard Shop' },
    // { title: 'Surf Shop' },
    // { title: 'Thrift & Consignment Store' },
    // { title: 'Tobacco Store' },
    // { title: 'Toy Store' },
    // { title: 'Trophies & Engraving Shop' },
    // { title: 'Video Game Store' },
    // { title: 'Vintage Store' },
    // { title: 'Wholesale & Supply Store' },
    // { title: 'Sports & Recreation' },
    // { title: 'Sports & Fitness Instruction' },
    // { title: 'Boat / Sailing Instructor' },
    // { title: 'Coach' },
    // { title: 'Fitness Trainer' },
    // { title: 'Golf Instructor' },
    // { title: 'Horse Riding School' },
    // { title: 'Scuba Instructor' },
    // { title: 'Ski & Snowboard School' },
    // { title: 'Swimming Instructor' },
    // { title: 'Sports & Recreation Venue' },
    // { title: 'ATV Recreation Park' },
    // { title: 'Archery Range' },
    // { title: 'Badminton Court' },
    // { title: 'Baseball Field' },
    // { title: 'Basketball Court' },
    // { title: 'Batting Cage' },
    // { title: 'Bowling Alley' },
    // { title: 'Disc Golf Course' },
    // { title: 'Driving Range' },
    // { title: 'Equestrian Center' },
    // { title: 'Fencing Club' },
    // { title: 'Fitness Venue' },
    // { title: 'Boxing Studio' },
    // { title: 'Cycling Studio' },
    // { title: 'Dance Studio' },
    // { title: 'Fitness Boot Camp' },
    // { title: 'Gym/Physical Fitness Center' },
    // { title: 'Martial Arts School' },
    // { title: 'Pilates Studio' },
    // { title: 'Tai Chi Studio' },
    // { title: 'Yoga Studio' },
    // { title: 'Flyboarding Center' },
    // { title: 'Go-Kart Track' },
    // { title: 'Golf Course & Country Club' },
    // { title: 'Gun Range' },
    // { title: 'Gymnastics Center' },
    // { title: 'Hang Gliding Center' },
    // { title: 'Hockey Field / Rink' },
    // { title: 'Horseback Riding Center' },
    // { title: 'Ice Skating Rink' },
    // { title: 'Kiteboarding Center' },
    // { title: 'Laser Tag Center' },
    // { title: 'Miniature Golf Course' },
    // { title: 'Paddleboarding Center' },
    // { title: 'Paintball Center' },
    // { title: 'Public Swimming Pool' },
    // { title: 'Racquetball Court' },
    // { title: 'Rafting/Kayaking Center' },
    // { title: 'Recreation Center' },
    // { title: 'Rock Climbing Gym' },
    // { title: 'Rodeo' },
    // { title: 'Roller Skating Rink' },
    // { title: 'Rugby Pitch' },
    // { title: 'Scuba Diving Center' },
    // { title: 'Shooting/Hunting Range' },
    // { title: 'Skateboard Park' },
    // { title: 'Ski Resort' },
    // { title: 'Skydiving Center' },
    // { title: 'Soccer Field' },
    // { title: 'Squash Court' },
    // { title: 'Tennis Court' },
    // { title: 'Volleyball Court' },
    // { title: 'Sports League' },
    // { title: 'Amateur Sports League' },
    // { title: 'Esports League' },
    // { title: 'Professional Sports League' },
    // { title: 'School Sports League' },
    // { title: 'Sports Team' },
    // { title: 'Amateur Sports Team' },
    // { title: 'Esports Team' },
    // { title: 'Professional Sports Team' },
    // { title: 'School Sports Team' },
    // { title: 'Stadium, Arena & Sports Venue' },
    // { title: 'Baseball Stadium' },
    // { title: 'Basketball Stadium' },
    // { title: 'Cricket Ground' },
    // { title: 'Football Stadium' },
    // { title: 'Hockey Arena' },
    // { title: 'Rugby Stadium' },
    // { title: 'Soccer Stadium' },
    // { title: 'Tennis Stadium' },
    // { title: 'Track Stadium' },
    { title: 'Travel & Transportation' },
    // { title: 'Airline Company' },
    // { title: 'Airline Industry Service' },
    // { title: 'Boat/Ferry Company' },
    // { title: 'Bus Line' },
    // { title: 'Cruise Line' },
    // { title: 'Railroad Company' },
    // { title: 'Rental Shop' },
    // { title: 'ATV Rental' },
    // { title: 'Bike Rental' },
    // { title: 'Boat Rental' },
    // { title: 'Canoe & Kayak Rental' },
    // { title: 'Car Rental' },
    // { title: 'Exotic Car Rental' },
    // { title: 'Jet Ski Rental' },
    // { title: 'RV Rental' },
    // { title: 'Scooter Rental' },
    // { title: 'Trailer Rental' },
    // { title: 'Truck Rental' },
    // { title: 'Transit Hub' },
    // { title: 'Airport' },
    // { title: 'Airport Gate' },
    // { title: 'Airport Lounge' },
    // { title: 'Airport Terminal' },
    // { title: 'Balloonport' },
    // { title: 'Bus Station' },
    // { title: 'Heliport' },
    // { title: 'Seaplane Base' },
    // { title: 'Train Station' },
    // { title: 'Light Rail Station' },
    // { title: 'Railway Station' },
    // { title: 'Subway Station' },
    // { title: 'Transit Stop' },
    // { title: 'Transportation Service' },
    // { title: 'Airport Shuttle Service' },
    // { title: 'Charter Bus Service' },
    // { title: 'Fishing Charter' },
    // { title: 'Limo Service' },
    // { title: 'Private Plane Charter' },
    // { title: 'Rideshare Service' },
    // { title: 'School Transportation Service' },
    // { title: 'Taxi Service' },
    // { title: 'Travel Company' },
    // { title: 'Tour Agency' },
    // { title: 'Architectural Tour Agency' },
    // { title: 'Art Tour Agency' },
    // { title: 'Boat Tour Agency' },
    // { title: 'Bus Tour Agency' },
    // { title: 'Eco Tour Agency' },
    // { title: 'Food Tour Agency' },
    // { title: 'Historical Tour Agency' },
    // { title: 'Horse-Drawn Carriage Service' },
    // { title: 'Hot Air Balloon Tour Agency' },
    // { title: 'Pedicab Service' },
    // { title: 'Sightseeing Tour Agency' },
    // { title: 'Tour Guide' },
    // { title: 'Travel Agency' },
    // { title: 'Cruise Agency' },
    // { title: 'Travel Service' },
    // { title: 'Luggage Service' },
    // { title: 'Tourist Information Center' },
    // { title: 'Community Organization' },
    // { title: 'Armed Forces' },
    // { title: 'Charity Organization' },
    // { title: 'Community Service' },
    // { title: 'Country Club / Clubhouse' },
    // { title: 'Environmental Conservation Organization' },
    // { title: 'Labor Union' },
    // { title: 'Private Members Club' },
    // { title: 'Religious Organization' },
    // { title: 'Social Club' },
    // { title: 'Sorority & Fraternity' },
    // { title: 'Sports Club' },
    // { title: 'Youth Organization' },
    // { title: 'Interest' },
    // { title: 'Literary Arts' },
    // { title: 'Performance Art' },
    // { title: 'Performing Arts' },
    // { title: 'Science' },
    // { title: 'Sports' },
    // { title: 'Visual Arts' },
    // { title: 'Media' },
    // { title: 'Art' },
    // { title: 'Books & Magazines' },
    // { title: 'Article' },
    // { title: 'Book' },
    // { title: 'Book Genre' },
    // { title: 'Book Series' },
    // { title: 'Magazine' },
    // { title: 'Newspaper' },
    // { title: 'Concert Tour' },
    // { title: 'Media Restoration Service' },
    // { title: 'Art Restoration Service' },
    // { title: 'Music' },
    // { title: 'Album' },
    // { title: 'Choir' },
    // { title: 'Music Award' },
    // { title: 'Music Chart' },
    // { title: 'Music Video' },
    // { title: 'Musical Genre' },
    // { title: 'Playlist' },
    // { title: 'Podcast' },
    // { title: 'Record Label' },
    // { title: 'Song' },
    // { title: 'Symphony' },
    // { title: 'Show' },
    // { title: 'TV & Movies' },
    // { title: 'Episode' },
    // { title: 'Movie' },
    // { title: 'Movie Character' },
    // { title: 'Movie Genre' },
    // { title: 'One-Time TV Program' },
    // { title: 'TV' },
    // { title: 'TV Channel' },
    // { title: 'TV Genre' },
    // { title: 'TV Network' },
    // { title: 'TV Season' },
    // { title: 'TV Show' },
    // { title: 'TV/Movie Award' },
    // { title: 'Video' },
    // { title: 'Theatrical Play' },
    // { title: 'Theatrical Productions' },
    // { title: 'Non-Business Places' },
    // { title: 'Automated Teller Machine (ATM)' },
    // { title: 'Campus Building' },
    // { title: 'City Infrastructure' },
    // { title: 'Bridge' },
    // { title: 'Canal' },
    // { title: 'Highway' },
    // { title: 'Lighthouse' },
    // { title: 'Marina' },
    // { title: 'Pier' },
    // { title: 'Promenade' },
    // { title: 'Quay' },
    // { title: 'Street' },
    // { title: 'Transit System' },
    // { title: 'Weather Station' },
    // { title: 'Landmark & Historical Place' },
    // { title: 'Monument' },
    // { title: 'Statue & Fountain' },
    // { title: 'Locality' },
    // { title: 'Borough' },
    // { title: 'Cemetery' },
    // { title: 'Pet Cemetery' },
    // { title: 'City' },
    // { title: 'City' },
    // { title: 'Congressional District' },
    // { title: 'Continental Region' },
    // { title: 'Country/Region' },
    // { title: 'County' },
    // { title: 'Designated Market Area' },
    // { title: 'Geo Entity' },
    // { title: 'Harbor' },
    // { title: 'Large Geo Area' },
    // { title: 'Medium Geo Area' },
    // { title: 'Metro Area' },
    // { title: 'Neighborhood' },
    // { title: 'Port' },
    // { title: 'Postal Code' },
    // { title: 'Province' },
    // { title: 'Region' },
    // { title: 'Shopping District' },
    // { title: 'Small Geo Area' },
    // { title: 'State' },
    // { title: 'Subcity' },
    // { title: 'Subneighborhood' },
    // { title: 'Time zone' },
    // { title: 'Township' },
    // { title: 'Village' },
    // { title: 'Meeting Room' },
    // { title: 'Outdoor Recreation' },
    // { title: 'Fairground' },
    // { title: 'Geographical Place' },
    // { title: 'Bay' },
    // { title: 'Beach' },
    // { title: 'Cape' },
    // { title: 'Cave' },
    // { title: 'Continent' },
    // { title: 'Desert' },
    // { title: 'Fjord/Loch' },
    // { title: 'Glacier' },
    // { title: 'Hot Spring' },
    // { title: 'Island' },
    // { title: 'Lake' },
    // { title: 'Mountain' },
    // { title: 'Ocean' },
    // { title: 'Pond' },
    // { title: 'Reservoir' },
    // { title: 'River' },
    // { title: 'Volcano' },
    // { title: 'Waterfall' },
    // { title: 'Park' },
    // { title: 'Arboretum' },
    // { title: 'Dog Park' },
    // { title: 'Field' },
    // { title: 'National Forest' },
    // { title: 'National Park' },
    // { title: 'Nature Preserve' },
    // { title: 'Picnic Ground' },
    // { title: 'Playground' },
    // { title: 'Public Square / Plaza' },
    // { title: 'State Park' },
    // { title: 'Public Garden' },
    // { title: 'Botanical Garden' },
    // { title: 'Community Garden' },
    // { title: 'Rose Garden' },
    // { title: 'Sculpture Garden' },
    // { title: 'Recreation Spot' },
    // { title: 'Bike Trail' },
    // { title: 'Diving Spot' },
    // { title: 'Fishing Spot' },
    // { title: 'Hiking Trail' },
    // { title: 'Rock Climbing Spot' },
    // { title: 'Snorkeling Spot' },
    // { title: 'Surfing Spot' },
    // { title: 'Public Toilet' },
    // { title: 'Religious Place of Worship' },
    // { title: 'Assemblies of God' },
    // { title: 'Buddhist Temple' },
    // { title: 'Church' },
    // { title: 'African Methodist Episcopal Church' },
    // { title: 'Anglican Church' },
    // { title: 'Apostolic Church' },
    // { title: 'Baptist Church' },
    // { title: 'Catholic Church' },
    // { title: 'Charismatic Church' },
    // { title: 'Christian Church' },
    // { title: 'Christian Science Church' },
    // { title: 'Church of Christ' },
    // { title: 'Church of God' },
    // { title: 'Church of Jesus Christ of Latter-day Saints' },
    // { title: 'Congregational Church' },
    // { title: 'Eastern Orthodox Church' },
    // { title: 'Episcopal Church' },
    // { title: 'Evangelical Church' },
    // { title: 'Full Gospel Church' },
    // { title: 'Holiness Church' },
    // { title: 'Independent Church' },
    // { title: 'Interdenominational Church' },
    // { title: 'Lutheran Church' },
    // { title: 'Mennonite Church' },
    // { title: 'Methodist Church' },
    // { title: 'Nazarene Church' },
    // { title: 'Nondenominational Church' },
    // { title: 'Pentecostal Church' },
    // { title: 'Presbyterian Church' },
    // { title: 'Seventh Day Adventist Church' },
    // { title: 'Convent & Monastery' },
    // { title: 'Hindu Temple' },
    // { title: 'Kingdom Hall' },
    // { title: 'Mission' },
    // { title: 'Mosque' },
    // { title: 'Religious Center' },
    // { title: 'Sikh Temple' },
    // { title: 'Synagogue' },
    // { title: 'Residence' },
    // { title: 'Apartment & Condo Building' },
    // { title: 'Castle' },
    // { title: 'Dorm' },
    // { title: 'Fort' },
    // { title: 'Home' },
    // { title: 'Palace' },
    // { title: 'Stately Home' },
    // { title: 'Other' },
    // { title: 'Brand' },
    // { title: 'App Page' },
    // { title: 'Appliances' },
    // { title: 'Baby Goods/Kids Goods' },
    // { title: 'Bags/Luggage' },
    // { title: 'Board Game' },
    // { title: 'Brand/Company Type' },
    // { title: 'Building Materials' },
    // { title: 'Camera/Photo' },
    // { title: 'Cars' },
    // { title: 'Clothing (Brand)' },
    // { title: 'Commercial Equipment' },
    // { title: 'Computers (Brand)' },
    // { title: 'Electronics' },
    { title: 'Furniture' },
    // { title: 'Games/Toys' },
    // { title: 'Health/Beauty' },
    // { title: 'Home Decor' },
    // { title: 'Household Supplies' },
    // { title: 'Jewelry/Watches' },
    // { title: 'Kitchen/Cooking' },
    // { title: 'Musical Instrument' },
    // { title: 'Office Supplies' },
    // { title: 'Patio/Garden' },
    // { title: 'Pet Supplies' },
    // { title: 'Pharmaceuticals' },
    // { title: 'Phone/Tablet' },
    // { title: 'Product Type' },
    // { title: 'Product/Service' },
    // { title: 'Software' },
    // { title: 'Tools/Equipment' },
    // { title: 'Video Game' },
    // { title: 'Vitamins/Supplements' },
    // { title: 'Website' },
    // { title: 'Arts & Humanities Website' },
    // { title: 'Business & Economy Website' },
    // { title: 'Computers & Internet Website' },
    // { title: 'E-commerce Website' },
    // { title: 'Education Website' },
    // { title: 'Entertainment Website' },
    // { title: 'Food Website' },
    // { title: 'Health & Wellness Website' },
    // { title: 'Home & Garden Website' },
    // { title: 'Local & Travel Website' },
    // { title: 'News & Media Website' },
    // { title: 'Personal Blog' },
    // { title: 'Recreation & Sports Website' },
    // { title: 'Reference Website' },
    // { title: 'Regional Website' },
    // { title: 'Science Website' },
    // { title: 'Society & Culture Website' },
    // { title: 'Teens & Kids Website' },
    // { title: 'Wine/Spirits' },
    // { title: 'Cause' },
    // { title: 'Color' },
    // { title: 'Community' },
    // { title: 'Course' },
    // { title: 'Cuisine' },
    // { title: 'Diseases' },
    // { title: 'Editorial/Opinion' },
    // { title: 'Election' },
    // { title: 'Event' },
    // { title: 'Festival' },
    // { title: 'School Fundraiser' },
    // { title: 'Sports Event' },
    // { title: 'Exchange Program' },
    // { title: 'Fan Page' },
    // { title: 'Harmonized Page' },
    // { title: 'Just For Fun' },
    // { title: 'Language' },
    // { title: 'Mood' },
    // { title: 'Nationality' },
    // { title: 'Not a Business' },
    // { title: 'Profile' },
    // { title: 'Satire/Parody' },
    // { title: 'Sports Season' },
    // { title: 'Surgeries' },
    // { title: 'Ticket Sales' },
    // { title: 'Topic' },
    // { title: 'University (NCES)' },
    // { title: 'University Status' },
    // { title: 'Work Position' },
    // { title: 'Work Project' },
    // { title: 'Work Status' },
    // { title: 'Public Figure' },
    // { title: 'Actor' },
    // { title: 'Artist' },
    // { title: 'Athlete' },
    // { title: 'Author' },
    // { title: 'Band' },
    // { title: 'Blogger' },
    // { title: 'Chef' },
    // { title: 'Comedian' },
    // { title: 'Dancer' },
    // { title: 'Designer' },
    // { title: 'Fashion Designer' },
    // { title: 'Digital Creator' },
    // { title: 'Editor' },
    // { title: 'Entrepreneur' },
    // { title: 'Fashion Model' },
    // { title: 'Film Director' },
    // { title: 'Fitness Model' },
    // { title: 'Gamer' },
    // { title: 'Journalist' },
    // { title: 'Motivational Speaker' },
    // { title: 'Musician' },
    // { title: 'Musician/Band' },
    // { title: 'News Personality' },
    // { title: 'Orchestra' },
    // { title: 'Producer' },
    // { title: 'Scientist' },
    // { title: 'Spiritual Leader' },
    // { title: 'Sports Promoter' },
    // { title: 'Talent Agent' },
    // { title: 'Video Creator' },
    // { title: 'Gaming Video Creator' },
    // { title: 'Writer' },
    { title: 'Influencer' },
  ];
};
