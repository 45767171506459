import React from "react";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";

import UserContext from "../../config/UserContext";
import { GenerateAdModelDialog, AccountInfoDialog } from "../Dialogs";

import {
  Header,
  Inner,
  Title,
  Buttons,
  UploadButton,
  CaptionButton,
  ConfigButton,
  BetaMessage,
  WelcomeMessage,
} from "./styles";

const PageHeader = ({
  title,
  onNewPostClick,
  aiTurnOff,
  handleAiTurnOffClick,
}) => {
  const { user, firstTime, posts, profile } = React.useContext(UserContext);
  const [isGenerateAdModelOpen, setIsGenerateAdModelOpen] = React.useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = React.useState(false);
  const [imgToken, setImgToken] = React.useState(null);
  const [maxPosts, setMaxPosts] = React.useState(null);
  const [planName, setPlanName] = React.useState("");

  React.useEffect(() => {
    // setImgToken(profile.controlTokens.imgUploadToken || null);
    setImgToken(
      profile.controlTokens ? profile.controlTokens.imgUploadToken : 0
    );
    // Only verifies Basic Plan Tier
    setMaxPosts(
      profile.accessLevel <= 100
        ? 1
        : profile.accessLevel === 500
          ? 8
          : profile.accessLevel === 600
            ? 16
            : profile.accessLevel === 700
              ? 24
              : 0
    );
    if (profile.accessLevel <= 100) {
      setPlanName("Beta Plan");
    } else if (profile.accessLevel === 500) {
      setPlanName("Basic Plan");
    } else if (profile.accessLevel === 600) {
      setPlanName("Silver Plan");
    } else if (profile.accessLevel === 700) {
      setPlanName("Gold Plan");
    } else {
      setPlanName("No plan yet");
    }

    if (firstTime) {
      setIsConfigModalOpen(true);
    }
  }, [firstTime, profile, profile.accessLevel]);

  return (
    <Header>
      <Inner>
        <Title>{title}</Title>
        <Buttons>
          {!!onNewPostClick && posts.length < maxPosts && imgToken > 0 && (
            <UploadButton onClick={() => setIsGenerateAdModelOpen(true)}>
              Generate Ad
            </UploadButton>
          )}
          <ConfigButton onClick={() => setIsConfigModalOpen(true)}>
            <ReactSVG src="/images/icons/cog.svg" />
          </ConfigButton>
        </Buttons>
      </Inner>

      {Object.keys(profile).length !== 0 &&
        profile &&
        profile.accessLevel === 50 && (
          <WelcomeMessage>
            Welcome to Aim Hi! To use our services, please go to the Subscribe
            tab inside the Menu Bar and choose a plan.
          </WelcomeMessage>
        )}
      {profile.accessLevel === 100 && (
        <BetaMessage>
          You cancelled your plan. Your storage and uploads will remain
          available for 4 weeks after last payment.
        </BetaMessage>
      )}
      {imgToken <= 0 && posts.length < maxPosts && (
        <BetaMessage>You have {imgToken} remaining uploads.</BetaMessage>
      )}
      {imgToken > 0 && posts.length >= maxPosts && (
        <BetaMessage>
          You have reached the maximum storage space. To upload a new image, you
          can delete an existing post and re-upload.
        </BetaMessage>
      )}
      {profile.accessLevel !== undefined &&
        imgToken <= 0 &&
        posts.length >= maxPosts && (
          <BetaMessage>
            You have reached the maximum storage and uploads for the {planName}{" "}
            during this month.
          </BetaMessage>
        )}

      <GenerateAdModelDialog
        isOpen={isGenerateAdModelOpen}
        onClose={() => setIsGenerateAdModelOpen(false)}
        aiTurnOff={aiTurnOff}
        handleAiTurnOffClick={handleAiTurnOffClick}
      />
      <AccountInfoDialog
        isOpen={isConfigModalOpen}
        onClose={() => setIsConfigModalOpen(false)}
        showCancelButton={!firstTime}
        user={user}
      />
    </Header>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onNewPostClick: PropTypes.func,
};

PageHeader.defaultProps = {
  onNewPostClick: null,
};

export default PageHeader;
